import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../styles/slider.css"; // CSS dosyanızın yolu



export default function Slider({ slideData }) {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [progress, setProgress] = useState(0);
  const progressInterval = useRef(null);
  
  // Progress bar sıfırlama ve başlatma
  const resetProgress = () => {
    setProgress(0);
    clearInterval(progressInterval.current);
  };
  const isMobile = window.matchMedia("(max-width: 800px)").matches;
  const startProgress = () => {
    resetProgress();
    progressInterval.current = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(progressInterval.current);
          return 100;
        }
      });
    }, 50); // Daha yumuşak animasyon için daha kısa aralık
  };

  // Swiper olaylarında progress kontrolü
  useEffect(() => {
    if (swiperInstance) {
      startProgress();
    }
    return () => clearInterval(progressInterval.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiperInstance]);

  // İleri ve geri slayt kontrolü
  const nextSlide = () => {
    resetProgress();
    swiperInstance?.slideNext();
  };

  const prevSlide = () => {
    resetProgress();
    swiperInstance?.slidePrev();
  };

  return (
    <div style={{ position: "relative" }}>
      {/* Navigasyon ve progress bar */}
      <ul className="nav">
        <button className="btn" onClick={prevSlide}>
          &#60;
        </button>

        <li className="progress-bar-wrapper">
          <div
            className="progress-bar"
            style={{
              width: `${progress}%`,
            }}
          ></div>
        </li>

        <button className="btn" onClick={nextSlide}>
          &#62;
        </button>
      </ul>

      {/* Swiper bileşeni */}
      <Swiper
        onSwiper={setSwiperInstance}
        modules={[Autoplay]}
        centeredSlides
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        loop
        onSlideChange={() => {
          resetProgress();
          startProgress();
        }}
        style={{ height: isMobile ? "auto" : "auto", marginTop: "15px" }}
      >
        {slideData.map((slide, index) => (
          <SwiperSlide key={index}>
            <img
              src={slide.src}
              alt={slide.title}
              
              style={{
                width: "100%",
                height: "100%",
                objectFit: isMobile ? "contain" : "cover", // Medya sorgusu ile değişim
              }}
            />
            
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
