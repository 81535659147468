import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Modal
} from "@mui/material";
import { motion } from "framer-motion";
import useAuth from './useAuth.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { getAllOrdersForAdmin,updateOrderStatus } from "../../services/accountService"; // accountService import
import { useNotification } from "../../components/NotificationContext";
// Durum rengi ve metinlerini döndüren fonksiyon
const getStatusDetails = (status) => {
  switch (status) {
    case 3:
      return { color: "#0f430f", text: "Kargoya Teslim Edildi" }; // Yeşil
    case 2:
      return { color: "#1C427A", text: "Hazırlanıyor" }; // Turuncu
    default:
      return { color: "#BDBDBD", text: "Onay Bekleniyor" }; // Gri
  }
};

const Orders = ({ id }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("date");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterPrice, setFilterPrice] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null); // Seçilen siparişi saklamak için
  const [openModal, setOpenModal] = useState(false); // Modal açma/kapama durumu
  const showNotification = useNotification();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  useAuth();
  const handleBackClick = () => {
    navigate(-1);
  };
  useEffect(() => {
    // Kullanıcı id'sine göre siparişleri çek
    const fetchOrders = async () => {
      try {
        const data = await getAllOrdersForAdmin( token);
        setOrders(data);
        console.log(data);
        setLoading(false);
      } catch (err) {
        setError("Siparişler alınırken bir hata oluştu.");
        setLoading(false);
      }
    };

    fetchOrders();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleSortRequest = (property) => {
    const isAsc = sortBy === property && sortOrder === "asc";
    setSortOrder(isAsc ? "desc" : "asc");
    setSortBy(property);
  };

  const sortedOrders = [...orders].sort((a, b) => {
    if (sortBy === "date") {
      const dateA = new Date(a.order_date);
      const dateB = new Date(b.order_date);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    } else if (sortBy === "total_price") {
      return sortOrder === "asc"
        ? a.total_price - b.total_price
        : b.total_price - a.total_price;
    }
    return 0;
  });

  const filteredOrders = sortedOrders.filter((order) => {
    return (
      (!filterStatus || order.status === filterStatus) &&
      (!filterPrice || order.total_price === filterPrice)
    );
  });
  const statusTextMap = {
    1: 'Siparişi Hazırlanıyor',
    2: 'Sipariş Kargoya Verildi',
    3: 'Teslim Edildi',
  };
  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOrder(null);
  };
  const handleUpdateOrderStatus = async (order) => {
    let newStatus = null;
  
    if (order.status === 0) {
      newStatus = 1;
    } else if (order.status === 1) {
      newStatus = 2;
    } else if (order.status === 2) {
      newStatus = 3;
    }
  
    if (newStatus !== null) {
      try {
        const updatedOrder = await updateOrderStatus(order.order_id, newStatus, token);
        console.log('Sipariş durumu güncellendi:', updatedOrder);
        showNotification("Sipariş Güncelleme Başarılı!", "success");
  
        // Sipariş durumu güncellendikten sonra 2 saniye bekleyip sayfayı yeniliyoruz
        setTimeout(() => {
          window.location.reload();  // Sayfayı 2 saniye sonra yenile
        }, 2000);
      } catch (error) {
        console.error('Sipariş durumu güncellenemedi:', error);
      }
    }
  };
  
  
  if (loading) {
    return <Typography>Yükleniyor...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
      <Box       sx={{p:3}}>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}

    >
     <Box display="flex" alignItems="center">
      <IconButton onClick={handleBackClick} style={{ marginRight: '10px' }}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4" gutterBottom>
        Siparişlerim
      </Typography>
    </Box>
      
      {/* Filtreleme ve Sıralama */}
      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel>Durum</InputLabel>
            <Select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <MenuItem value="">Hepsi</MenuItem>
              <MenuItem value={3}>Kargoya Teslim Edildi</MenuItem>
              <MenuItem value={2}>Hazırlanıyor</MenuItem>
              <MenuItem value={1}>Onay Bekleniyor</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl fullWidth>
            <InputLabel>Fiyat</InputLabel>
            <Select
              value={filterPrice}
              onChange={(e) => setFilterPrice(e.target.value)}
            >
              <MenuItem value="">Hepsi</MenuItem>
              {orders.map((order) => (
                <MenuItem key={order.id} value={order.total_price}>
                  {order.total_price} TL
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Sipariş Tablosu */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "date"}
                  direction={sortOrder}
                  onClick={() => handleSortRequest("date")}
                >
                  Sipariş Tarihi
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === "total_price"}
                  direction={sortOrder}
                  onClick={() => handleSortRequest("total_price")}
                >
                  Toplam Fiyat
                </TableSortLabel>
              </TableCell>
              <TableCell>Durum</TableCell>
              <TableCell>Detayları Gör</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOrders.map((order) => {
              const { color, text } = getStatusDetails(order.status);
              return (
                <TableRow key={order.id}>
                  <TableCell>{formatDate(order.order_date)}</TableCell>
                  <TableCell>{order.total_price} TL</TableCell>
                  <TableCell style={{ color }}>{text}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpenModal(order)}
                    >
                      Detayları Gör
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal
  open={openModal}
  onClose={handleCloseModal}
  sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
>
  <Box
    sx={{
      backgroundColor: 'white',
      padding: '24px',
      minWidth: '800px',
      maxWidth: '100%',
      maxHeight: '80%',
      overflowY: 'auto',
      borderRadius: '8px',
      boxShadow: 24,
    }}
  >
    <Typography variant="h4" sx={{ textAlign: 'center' }} gutterBottom>
      Sipariş Detayları
    </Typography>

    {selectedOrder && (
      <Box>
        {/* Sipariş Bilgileri */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
  {/* Sipariş Bilgileri */}
  <Box sx={{ border: '2px solid #9c7338', borderRadius: '12px', p: 2, flex: 1 }}>
    <Typography variant="h6" style={{ color: 'red' }}>
      Sipariş Bilgileri:
    </Typography>
    <Typography variant="body1">Sipariş No: {selectedOrder.order_id}</Typography>
    <Typography variant="body1">Sipariş Tarihi: {formatDate(selectedOrder.order_date)}</Typography>
    <Typography variant="body1">
      Durum: <span style={{ color: getStatusDetails(selectedOrder.status).color }}>
        {getStatusDetails(selectedOrder.status).text}
      </span>
    </Typography>
    <Typography variant="body1">Toplam Fiyat: {selectedOrder.total_price} TL</Typography>
  </Box>

  {/* Kullanıcı Bilgileri */}
  <Box sx={{ border: '2px solid #9c7338', borderRadius: '12px', p: 2, flex: 1 }}>
    <Typography variant="h6" style={{ color: 'red' }}>
      Kullanıcı Bilgileri:
    </Typography>
    <Typography variant="body1">Ad Soyad: {`${selectedOrder.user_first_name} ${selectedOrder.user_last_name}`}</Typography>
    <Typography variant="body1">E-posta: {selectedOrder.user_email}</Typography>
    <Typography variant="body1">Telefon: {selectedOrder.user_phone}</Typography>
  </Box>
</Box>

        {/* Adres Bilgileri */}
        <Box sx={{ border: '2px solid #9c7338', borderRadius: '12px', p: 2, my: 2 }}>
          <Typography variant="h6" style={{ color: 'red' }}>
            Adres:
          </Typography>
          <Typography variant="body1">Şehir: {selectedOrder.user_city}</Typography>
          <Typography variant="body1">İlçe: {selectedOrder.user_district}</Typography>
          <Typography variant="body1">Adres: {selectedOrder.user_address}</Typography>
        </Box>

        <Box sx={{ border: '2px solid #9c7338', borderRadius: '12px', p: 2, my: 2 }}>
          <Typography variant="h6" style={{ color: 'red' }}>
            Fatura Adresi:
          </Typography>
          {selectedOrder.billing_full_name && selectedOrder.billing_city && selectedOrder.billing_district && selectedOrder.billing_address && selectedOrder.tax_office && selectedOrder.tax_number ? (
  <>
    <Typography variant="body1">Ad Soyad: {selectedOrder.billing_full_name}</Typography>
    <Typography variant="body1">Şehir: {selectedOrder.billing_city}</Typography>
    <Typography variant="body1">İlçe: {selectedOrder.billing_district}</Typography>
    <Typography variant="body1">Adres: {selectedOrder.billing_address}</Typography>
    <Typography variant="body1">Vergi Dairesi: {selectedOrder.tax_office}</Typography>
    <Typography variant="body1">Vergi No: {selectedOrder.tax_number}</Typography>
  </>
) : (
  <Typography variant="body1">Fatura adresi normal adrese gönderilecek.</Typography>
)}
        </Box>

        {/* Sipariş Edilen Ürünler */}
        <Box sx={{ border: '2px solid #9c7338', borderRadius: '12px', p: 2, my: 2 }}>
          <Typography variant="h6" style={{ color: 'red' }}>
            Sipariş Edilen Ürünler:
          </Typography>
          {selectedOrder.products &&
            selectedOrder.products.map((product) => (
              <Box key={product.id} style={{ marginBottom: '8px' }}>
                <Typography variant="body1">
                  Ürün: {product.product_name} - Adet: {product.quantity}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
    )}

<Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: '16px' }}>
{selectedOrder && selectedOrder.status !== 3 && (
    <Button
      onClick={() => handleUpdateOrderStatus(selectedOrder)}
      color="secondary"
      variant="contained"
    >
      {statusTextMap[selectedOrder.status] || 'Durum Bilinmiyor'}
    </Button>
  )}

  <Button onClick={handleCloseModal} color="primary" variant="outlined">
    Kapat
  </Button>
</Box>


  </Box>
</Modal>

    </motion.div>
    </Box>
  );
};

export default Orders;
