import axios from "axios";



// Ürünleri Getir
export const fetchProducts = async (filters) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/product`, {
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error; // Hata yönetimi için hatayı tekrar fırlatıyoruz
  }
};

export const fetchProductById = async (id) => {
  try {
    // Ürünü getir
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/product/${id}`);
    const product = response.data;

    if (!product || !product.id) {
      throw new Error("Ürün bulunamadı.");
    }

    // Ürün puanını getir
    const ratingResponse = await axios.get(`${process.env.REACT_APP_API_URL}/comment/rating/${product.id}`);

    return {
      ...product,
      averageRating: ratingResponse.data.averageRating || 0,
      totalRatings: ratingResponse.data.totalRatings || 0,
    };
  } catch (error) {
    console.error(`Ürün veya puan getirme hatası (ID: ${id}):`, error);
    throw error; // Hata yönetimi için hatayı tekrar fırlatıyoruz
  }
};

export const fetchReviewsByProductId = async (productId, token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/comment/${productId.productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data || []; // Eğer null gelirse boş array döndür
  } catch (error) {
    console.error("Yorumlar yüklenirken hata:", error);
    return []; // Hata durumunda boş array döndür
  }
};
export const addReview = async (data, token) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/comment`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Yorum gönderme hatası:", error);
    console.error("Hata detayı:", error.response?.data);
    throw error.response?.data?.message || "Yorum eklenirken bir hata oluştu";
  }
};