import React, { useState, useEffect } from "react";
import axios from "axios";
import useAuth from "./useAuth.js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useNotification } from "../../components/NotificationContext";
import {
  Box,
  Typography,
  Button,
  Input,
  IconButton,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import UploadFileIcon from "@mui/icons-material/UploadFile";

const AdminPDF = () => {
  useAuth();
  const [fileName, setFileName] = useState("");
  const [, setFilePath] = useState("");
  const [pdfList, setPdfList] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const showNotification = useNotification();
  useEffect(() => {
    fetchPdfList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const fetchPdfList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pdf`);
      const data = response.data.data;
      if (Array.isArray(data)) {
        setPdfList(data);
      } else {
        showNotification("PDF Dosyası Getirilemedi!", "error");
        setPdfList([]);
      }
    } catch (error) {
      showNotification("PDF Dosyası Getirilemedi!", "error");
      setPdfList([]);
    }
  };
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".pdf")) {
      setFileName(file.name);
      setFilePath(file.path || file.name);
    } else {
      showNotification("Lütfen PDF Dosyası Seçin!", "error");
    }
  };

  const sendPdfRequest = async () => {
    if (!fileName) {
      showNotification("Lütfen PDF Dosyası Seçin!", "error");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("pdf", document.getElementById("upload-button").files[0]);
  
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        showNotification("Yetkilendirme Hatası Lütfen Giriş Yapın!", "error");
        return;
      }
  
      await axios.post(`${process.env.REACT_APP_API_URL}/pdf`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      showNotification("PDF Yüklendi!", "success");
      localStorage.setItem("notificationMessage", "PDF Yüklendi!");
      localStorage.setItem("notificationSeverity", "success");
      setFileName("");
      setFilePath("");
      fetchPdfList();
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        showNotification("Yetkilendirme Hatası Lütfen Giriş Yapın!", "error");
      }
    }
  };
  
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ margin: "auto", padding: 2 }}>
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
        <IconButton onClick={handleBackClick} style={{ marginRight: '10px' }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" align="center" gutterBottom>
          Admin PDF Yönetimi
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            sx={{ display: "none" }}
            id="upload-button"
          />
          <label htmlFor="upload-button">
            <Button
              variant="contained"
              component="span"
              startIcon={<UploadFileIcon />}
            >
              PDF Yükle
            </Button>
          </label>
        </Box>
        <Divider sx={{ marginY: 2 }} />
        {fileName && (
          <Box display="flex" flexDirection="column" align="center" gap={1}>
            <Typography variant="body1">Seçilen Dosya: {fileName}</Typography>
            <Box display="flex" gap={3} sx={{ my: 1 }}>
              <Button
                variant="contained"
                color="success"
                onClick={sendPdfRequest}
                fullWidth
                disabled={loading}
              >
                {loading ? "Yükleniyor..." : "Yükle"}
              </Button>
            </Box>
          </Box>
        )}
        {!fileName && (
          <Typography color="textSecondary" align="center">
            Henüz bir dosya seçilmedi.
          </Typography>
        )}
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h5" gutterBottom>
          Yüklü PDF'ler
        </Typography>
        <List>
          {pdfList.map((pdf) => (
            <ListItem key={pdf.id} divider>
              <ListItemText primary={pdf.file_name} />
              <Button
                variant="outlined"
                color="primary"
                href={`${process.env.REACT_APP_BASE_URL}${pdf.file_path}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                PDF'yi Görüntüle
              </Button>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default AdminPDF;
