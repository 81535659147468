import React, { useState,useEffect } from "react";
import { List, ListItem, ListItemText, Button, Box,CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import AddressModal from "./AddressModal";
import { cities } from "../data/cities";
import { getAddresses, deleteAddress } from '../services/accountService';
import { useNotification } from './NotificationContext';

function Addresses({ id }) {
  const [addresses, setAddresses] = useState([]);
  const [open, setOpen] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");

  const [loading, setLoading] = useState(false);
  const showNotification = useNotification();
  const token = localStorage.getItem('token');
  useEffect(() => {
    const fetchAddresses = async () => {
      setLoading(true);
      try {
        const data = await getAddresses(token,id);
        const addressArray = Array.isArray(data) ? data : Object.values(data);
        setAddresses(addressArray);
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
  
    fetchAddresses();
  }, [token]);

  const handleDelete = async (id) => {
    try {
      // Adresi silme API çağrısını yapıyoruz
      await deleteAddress(token, id);
  
      // Silinen adresi state'den çıkarıyoruz
      setAddresses((prev) => prev.filter((address) => address.address_id !== id));
  
      // Başarılı bir şekilde silindiğini bildiren bir not gösteriyoruz
      showNotification('Adres başarıyla silindi.', 'success');
    } catch (error) {
      showNotification('Adres silinirken hata oluştu.', 'error');
    }
  };




  const handleAddAddress = async () => {
    if (city && district) {
      try {
        // Backend API'ye POST isteği gönderiyoruz
        const response = await fetch(`${process.env.REACT_APP_API_URL}/address/${id}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            address: newAddress,
            city: city,
            state: district,
          }),
        });
  
        if (!response.ok) {
          throw new Error('Adres eklenemedi');
        }
  
        // Backend'den dönen yeni adresi alıyoruz
        const Address = await response.json(); // Backend'den dönen tüm adres bilgileri
  
        // Yeni adresi listeye ekliyoruz
        setAddresses((prev) => [...prev, Address]);
  
        // Başarılı bildirim göster
        showNotification('Adres başarıyla eklendi.', 'success');
  
        // Modalı kapat
        setOpen(false);
        setCity("");
        setDistrict("");
        setNewAddress("");
      } catch (error) {
        showNotification('Adres eklenirken hata oluştu.', 'error');
      }
    }
  };
  

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <List>
      {addresses.length > 0 ? (
  addresses.map((item) => {
    return (
      <ListItem
        key={item.address_id}
        secondaryAction={
          <Button sx={{ color: "red" }} onClick={() => handleDelete(item.address_id)}>
            Sil
          </Button>
        }
      >
        <ListItemText primary={`${item.city} ${item.state}`} />
        <ListItemText primary={item.address} />
      </ListItem>
    );
  })
) : (
  <ListItem>
    <ListItemText primary="Adres bulunamadı" />
  </ListItem>
)}
</List>
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          sx={{

            background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
            backgroundSize: "200% 100%", // Gradient genişliği artırıldı
            backgroundPosition: "right center", // Başlangıç pozisyonu
            color: "#fff",
            fontWeight: "bold",

            borderRadius: "8px", // Köşeleri yuvarlama
            padding: "10px 20px",
            textDecoration: "none",
            border: "none",
            cursor: "pointer",
            transition: "background-position 0.5s ease, transform 0.3s ease",
            "&:hover": {
              backgroundPosition: "left center", // Hover durumunda sola geçiş
              transform: "scale(1.01)", // Hafif büyüme efekti
              color: "#F0F8FF", // Daha parlak beyaz
              boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
            },
          }}

          onClick={() => setOpen(true)}
        >
          Adres Ekle
        </Button>
      </Box>
      <AddressModal
        open={open}
        onClose={() => setOpen(false)}
        cities={cities}
        city={city}
        setCity={setCity}
        district={district}
        setDistrict={setDistrict}
        newAddress={newAddress}
        setNewAddress={setNewAddress}
        onAddAddress={handleAddAddress}
      />
    </motion.div>
  );
}

export default Addresses;
