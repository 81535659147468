import React, { useState } from "react";
import { Box, TextField, Typography,Grid } from "@mui/material";

const GuestOrderForm = ({ isLoggedIn,onFormDataChange  }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName:"",
    email: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    onFormDataChange(updatedFormData);
  };


  

  if (isLoggedIn === true) {
    return null; // Formu göstermiyoruz
  }

  return (
    <Box
      component="form"
      sx={{
        border:"2px solid #9c7338", p:3,borderRadius:"8px",
        mx: "auto",
        boxShadow: 0,
        mb:4,
        bgcolor: "transparent",
      }}
    >
      <Typography variant="h5" gutterBottom sx={{textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",}}>
        Sipariş Bilgileri
      </Typography>
      <Grid Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
      <TextField
        label="Ad"
        name="firstName"
        fullWidth
        value={formData.firstName}
        onChange={handleChange}
        error={errors.firstName}
        helperText={errors.firstName && "Bu alan zorunludur."}
        margin="normal"
        sx={{
            "& label.Mui-focused": { color: "#000" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#9c7338" },
              "&:hover fieldset": { borderColor: "#fff" },
              "&.Mui-focused fieldset": { borderColor: "#fff" },
            },
          }}
      />
</Grid>
<Grid item xs={12} sm={6}>
      <TextField
        label="Soyad"
        name="lastName"
        fullWidth
        value={formData.lastName}
        onChange={handleChange}
        error={errors.lastName}
        helperText={errors.lastName && "Bu alan zorunludur."}
        margin="normal"
        sx={{
            "& label.Mui-focused": { color: "#000" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#9c7338" },
              "&:hover fieldset": { borderColor: "#fff" },
              "&.Mui-focused fieldset": { borderColor: "#fff" },
            },
          }}
      />
</Grid>
</Grid>
      <TextField
        label="E-posta"
        name="email"
        fullWidth
        value={formData.email}
        onChange={handleChange}
        error={errors.email}
        helperText={errors.email && "Geçerli bir e-posta adresi giriniz."}
        margin="normal"
        sx={{
            "& label.Mui-focused": { color: "#000" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#9c7338" },
              "&:hover fieldset": { borderColor: "#fff" },
              "&.Mui-focused fieldset": { borderColor: "#fff" },
            },
          }}
      />

      <TextField
        label="Telefon Numarası"
        name="phone"
        fullWidth
        value={formData.phone}
        onChange={handleChange}
        error={errors.phone}
        helperText={
          errors.phone &&
          "Geçerli bir telefon numarası giriniz (10-15 rakam)."
        }
        margin="normal"
        sx={{
            "& label.Mui-focused": { color: "#000" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "#9c7338" },
              "&:hover fieldset": { borderColor: "#fff" },
              "&.Mui-focused fieldset": { borderColor: "#fff" },
            },
          }}
      />
    </Box>
  );
};

export default GuestOrderForm;
