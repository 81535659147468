import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, FormControl, Box,Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputMask from "react-input-mask";

// Kart bilgileri için schema (doğrulama) tanımlama
const schema = yup.object().shape({
  cardNumber: yup.string().matches(/^\d{4} \d{4} \d{4} \d{4}$/, "Geçerli bir kart numarası girin").required("Kart numarası gerekli"),
  expiryDate: yup.string().matches(/^(0[1-9]|1[0-2])\/([0-9]{2})$/, "Geçerli bir geçerlilik tarihi girin (MM/YY)").required("Geçerlilik tarihi gerekli"),  firstName: yup.string().required("Ad gereklidir"),
  lastName: yup.string().required("Soyad gereklidir"),
});

function CardModal({ open, handleClose, handleCardAdd }) {
  const [cardType, setCardType] = useState("");
  const [isCardValid, setIsCardValid] = useState(false);
  const { handleSubmit, formState: { errors }, reset, setValue, watch } = useForm({
    resolver: yupResolver(schema),
  });

  const validateLuhn = (cardNumber) => {
    let sum = 0;
    let shouldDouble = false;
  
    // Kart numarasındaki her rakamı tersten kontrol et
    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i));
  
      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9; // Eğer iki katına çıktığında 9'dan büyükse, 9'u çıkar
      }
  
      sum += digit;
      shouldDouble = !shouldDouble;
    }
  
    return sum % 10 === 0; // Eğer toplam 10'a bölünebiliyorsa geçerli
  };
  
  const handleCardNumberChange = (event) => {
    let cardNumber = event.target.value.replace(/[^0-9 ]/g, ""); // Sadece rakamları al
  
    // Kart numarasını dörtlü gruplar halinde boşluk ekle
    if (cardNumber.length > 4 && cardNumber.length <= 8) {
      cardNumber = cardNumber.slice(0, 4) + " " + cardNumber.slice(4);
    } else if (cardNumber.length > 8 && cardNumber.length <= 12) {
      cardNumber = cardNumber.slice(0, 8) + " " + cardNumber.slice(8);
    } else if (cardNumber.length > 12 && cardNumber.length <= 16) {
      cardNumber = cardNumber.slice(0, 12) + " " + cardNumber.slice(12);
    }
  
    const firstDigit = cardNumber[0]; // İlk rakamı al
    const luhnnumber = cardNumber;
    // Luhn algoritmasını kontrol et
    if (luhnnumber.length === 19 && !validateLuhn(luhnnumber.replace(/\D/g, ""))) {  // boşlukları kaldırıp kontrol ediyoruz
      setCardType("Invalid"); // Eğer geçersizse, kart türünü "Invalid" olarak ayarla
      setIsCardValid(false); // Kart geçersiz olduğunda butonu devre dışı bırak
      return; // Geçersiz kart numarası girildiği için işlem yapma
    }
  
    // Kart türünü belirleme
    if (cardNumber.length === 19) {
      if (firstDigit === "4") {
        setCardType("Visa");
      } else if (firstDigit === "5") {
        setCardType("MasterCard");
      } else if (firstDigit === "3" || cardNumber.startsWith("65") || cardNumber.startsWith("97")) {
        setCardType("Troy");
      } else {
        setCardType(""); // Geçersiz kart numarası
      }
    } else {
      setCardType(""); // Kart numarası eksik
    }
    // Formdaki cardNumber değerini güncelleme
    setValue("cardNumber", cardNumber); 
  
    // Kart numarası 16 haneli ve geçerli mi?
    setIsCardValid(luhnnumber.replace(/\D/g, "").length === 16 && validateLuhn(luhnnumber.replace(/\D/g, ""))); 
  };
  
  const onSubmit = (data) => {
    if (!isCardValid) {
      return; // Eğer kart geçerli değilse, formu gönderme
    }
    handleCardAdd(data); // Kart ekleme işlemi için callback
    handleClose(); // Modal'ı kapat
    reset(); // Formu sıfırla
    setCardType(""); // Kart türünü sıfırla
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{background: "linear-gradient(to right, #11284A 0%, #1C427A 50%, #0E213D 100%)", color: "white"}}>
        Kart Bilgileri Ekle
      </DialogTitle>
      <DialogContent sx={{background: "linear-gradient(to right, #11284A 0%, #1C427A 50%, #0E213D 100%)", color: "white"}}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth margin="normal">
  <TextField
    label="Ad"
    value={watch("firstName")}
    onChange={(e) => setValue("firstName", e.target.value)}
    error={!!errors.firstName}
    helperText={errors.firstName?.message}
    fullWidth
    sx={{
        mt: 2,
        color: "white", 
        ".MuiInputBase-root": {
          color: "white", 
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "white", 
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "white", 
        },
      }}
      InputLabelProps={{
        sx: {
          color: "white", 
          "&.Mui-focused": {
            color: "white", 
          },
        },
      }}
  />
</FormControl>
<FormControl fullWidth margin="normal">
  <TextField
    label="Soyad"
    value={watch("lastName")}
    onChange={(e) => setValue("lastName", e.target.value)}
    error={!!errors.lastName}
    helperText={errors.lastName?.message}
    fullWidth
    sx={{
        mt: 2,
        color: "white", 
        ".MuiInputBase-root": {
          color: "white", 
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "white", 
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "white", 
        },
      }}
      InputLabelProps={{
        sx: {
          color: "white", 
          "&.Mui-focused": {
            color: "white", 
          },
        },
      }}
  />
</FormControl>

          <FormControl fullWidth margin="normal" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputMask
              mask="9999 9999 9999 9999"
              value={watch("cardNumber")}
              onChange={(e) => {
                setValue("cardNumber", e.target.value);
                handleCardNumberChange(e); // Kart türünü belirle
              }}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  label="Kart Numarası"
                  fullWidth
                  error={!!errors.cardNumber}
                  helperText={errors.cardNumber?.message}
                  sx={{
                    mt: 2,
                    color: "white",
                    ".MuiInputBase-root": {
                      color: "white", 
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", 
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", 
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "white", 
                      "&.Mui-focused": {
                        color: "white", 
                      },
                    },
                  }}
                />
              )}
            </InputMask>

            {/* Kart Türü İkonu */}
            {cardType && (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: 1 }}>
                {cardType === "Visa" && <img src="/visa.png" alt="Visa Icon" width="30" />}
                {cardType === "MasterCard" && <img src="/mastercard.png" alt="MasterCard Icon" width="30" />}
                {cardType === "Troy" && <img src="/troy.png" alt="Troy Icon" width="30" />}
                {cardType === "Invalid" && <span style={{ color: 'red' }}>Geçersiz Kart Numarası</span>}
              </Box>
            )}
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputMask
              mask="99/99"
              value={watch("expiryDate")}
              onChange={(e) => setValue("expiryDate", e.target.value)} 
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  label="Geçerlilik Tarihi (MM/YY)"
                  fullWidth
                  error={!!errors.expiryDate}
                  helperText={errors.expiryDate?.message}
                  sx={{
                    mt: 2,
                    color: "white", 
                    ".MuiInputBase-root": {
                      color: "white", 
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", 
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", 
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: "white", 
                      "&.Mui-focused": {
                        color: "white", 
                      },
                    },
                  }}
                />
              )}
            </InputMask>
          </FormControl>
          <Typography variant="Body2" gutterBottom>
      Kart Bilgileriniz Sistemimize Kayıt Edilmez.
    </Typography>

          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                color: "white",
                ":hover": { backgroundColor: "#313131" },
              }}
            >
              İptal
            </Button>
            <Button
              type="submit"
              disabled={!isCardValid}
              sx={{
                color: "white",
                backgroundColor: isCardValid ? "#4CAF50" : "#9E9E9E",
                ":hover": {
                  backgroundColor: isCardValid ? "#45A049" : "#9E9E9E",
                },
              }}
            >
              Kaydet
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CardModal;
