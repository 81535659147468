import React, { useState,useEffect } from "react";
import { Box, TextField, Button, FormControlLabel, Checkbox, Typography, Grid, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import Navbar from "../components/navbar";
import Footer from '../components/Footer';
import ShoppingCart from "../components/ShoppingCart";
import { useNavigate } from 'react-router-dom';
import { useNotification } from "../components/NotificationContext";
const Register = () => {
  const navigate = useNavigate(); 
  const showNotification = useNotification();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    isAgreementChecked: false,
    isCommunicationAccepted: false, // E-posta ve SMS kabulü
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token"); // Token'ı al
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "phone") {
      // Sadece sayıları al
      const numericValue = value.replace(/[^0-9]/g, '');
    
      // Maskelenmiş değeri oluştur
      let maskedValue = '';
    
      if (numericValue.length > 0) {
        maskedValue += numericValue.substring(0, 4);
      }
      if (numericValue.length > 4) {
        maskedValue += ' ' + numericValue.substring(4, 7);
      }
      if (numericValue.length > 7) {
        maskedValue += ' ' + numericValue.substring(7, 9);
      }
      if (numericValue.length > 9) {
        maskedValue += ' ' + numericValue.substring(9, 11);
      }
    
      // Form verisini güncelle
      setFormData((prevState) => ({
        ...prevState,
        [name]: maskedValue,
      }));
    
      // Alan boşsa temizle
      if (numericValue.length === 0) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: '',
        }));
        return;
      }
    
      // Maskelenmiş değerin uzunluğuna göre kontrol yap
      if (maskedValue.length === 16) {
        const phoneRegex = /^\d{4} \d{3} \d{2} \d{2}$/;
        if (!phoneRegex.test(maskedValue)) {
          setErrorMessage('Geçerli bir telefon numarası girin.');
          setOpenSnackbar(true);
          return;
        }
      }
    
      setOpenSnackbar(false);
      return;
    }
    
    
    
    const regex = /^[A-Za-z]*$/;
    if ((name === "firstName" || name === "lastName") && !regex.test(value) && value !== "") {
      setErrorMessage("Sadece harfler girilebilir.");
      setOpenSnackbar(true);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setOpenSnackbar(false); 
    }
  };
  
  
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // E-posta doğrulama
    if (formData.email && !formData.email.includes('@')) {
      setErrorMessage("Geçerli bir e-posta adresi girin.");
      setOpenSnackbar(true);
      return; 
    }
  
    // Şifreler eşleşiyor mu?
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Şifreler Eşleşmiyor.");
      setOpenSnackbar(true);
      return;
    }
  
    // Şifre geçerlilik kontrolleri
    const password = formData.password;
    if (password.length < 8) {
      setErrorMessage("Şifre en az 8 karakter olmalı.");
      setOpenSnackbar(true);
      return;
    }
  

  
    // Telefon numarası doğrulama
    if (!formData.phone || formData.phone.length !== 14) {
      setErrorMessage("Telefon numarasını eksiksiz girin.");
      setOpenSnackbar(true);
      return;
    }
  
    if (formData.phone && !formData.phone.startsWith("05")) {
      setErrorMessage("Telefon numarası '05' ile başlamalı.");
      setOpenSnackbar(true);
      return;
    }
  
    // Kullanıcı sözleşmesi kontrolü
    if (formData.isAgreementChecked) {
      //console.log(formData);  // Form başarıyla gönderilecekse
    } else {
      setErrorMessage("Lütfen kullanıcı sözleşmesini kabul edin.");
      setOpenSnackbar(true);
    }

// API'ye POST isteği gönderme
try {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, formData);

 //console.log(response.data.status);
  if (response.data.status==="OK") {
      
    showNotification('Kayıt Başarılı!', 'success');
    localStorage.setItem('notificationMessage', 'Kayıt Başarılı!');
    localStorage.setItem('notificationSeverity', 'success');
    navigate("/");

  }
} catch (error) {
  showNotification("Kayıt başarısız! Lütfen bilgilerinizi kontrol edin.", "error");
}

  };

  

  return (
    <div style={{ height: "100%", width: "100%", padding: "0", margin: "0" }}>
      <Navbar />
      <main>
        <Box sx={{ padding: 3 }}>
          <ShoppingCart />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              maxWidth: "700px",
              margin: "auto",
              padding: 3,
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.9)",
              borderRadius: "8px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(5px)", 
              WebkitBackdropFilter: "blur(10px)", 
              color:"#fff"
            }}
          >
            <Typography variant="h4" sx={{ marginBottom: 3 }}>Kayıt Ol</Typography>
            <form onSubmit={handleSubmit}>
              {/* Ad ve Soyad */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                <TextField
  label="Ad"
  variant="outlined"
  fullWidth
  name="firstName"
  value={formData.firstName}
  onChange={handleChange}
  required
  inputProps={{
    pattern: "[A-Za-z]+", 
    title: "Sadece harfler girilebilir"
  }}
  sx={{
    "& .MuiInputLabel-root": {
      color: "white", 
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ccc",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
    "& .MuiInputBase-input": {
      color: "white", 
    },
    "& .MuiInputBase-input:focus": {
      color: "white", 
    },
  }}
/>

                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Soyad"
                    variant="outlined"
                    fullWidth
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    inputProps={{
                      pattern: "[A-Za-z]+", 
                      title: "Sadece harfler girilebilir"
                    }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "white", 
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fff",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "white", // Yazı beyaz
                      },
                      "& .MuiInputBase-input:focus": {
                        color: "white", // Focus durumunda yazı beyaz
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* E-posta ve Telefon */}
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    label="E-posta"
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "white", // Label beyaz
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fff",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "white", // Yazı beyaz
                      },
                      "& .MuiInputBase-input:focus": {
                        color: "white", // Focus durumunda yazı beyaz
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Telefon"
                    variant="outlined"
                    fullWidth
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "white", // Label beyaz
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fff",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "white", // Yazı beyaz
                      },
                      "& .MuiInputBase-input:focus": {
                        color: "white", // Focus durumunda yazı beyaz
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Şifre ve Şifre Tekrarı */}
              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Şifre"
                    type="password"
                    variant="outlined"
                    fullWidth
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "white", // Label beyaz
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fff",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "white", // Yazı beyaz
                      },
                      "& .MuiInputBase-input:focus": {
                        color: "white", // Focus durumunda yazı beyaz
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Şifre Tekrarı"
                    type="password"
                    variant="outlined"
                    fullWidth
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    required
                    sx={{
                      "& .MuiInputLabel-root": {
                        color: "white", // Label beyaz
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fff",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "white", // Yazı beyaz
                      },
                      "& .MuiInputBase-input:focus": {
                        color: "white", // Focus durumunda yazı beyaz
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Sözleşme Kabulü */}
              <FormControlLabel
  control={
    <Checkbox
      name="isAgreementChecked"
      checked={formData.isAgreementChecked}
      onChange={handleCheckboxChange}
      required
      sx={{
        color:"white"
      }}
    />
  }
  label={
    <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
      Sağladığım kişisel verilerin Lokman Özbek Et ve Et Ürünleri tarafından Gizlilik ve Kişisel Verilerin Korunması Politikası’nda belirtilen amaçlar doğrultusunda işlenmesine onay verir, Üyelik Sözleşmesi’ni kabul ederim.
    </Typography>
  }
  sx={{ marginTop: 2 }}
/>


              {/* E-posta ve SMS Kabulü */}
              <FormControlLabel
  control={
    <Checkbox
      name="isCommunicationAccepted"
      checked={formData.isCommunicationAccepted}
      onChange={handleCheckboxChange}
      sx={{
        color:"white"
      }}
    />
  }
  label={
    <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
      Lokman Özbek Et ve Et Ürünleri tarafından, Gizlilik ve Kişisel Verilerin Korunması Politikası kapsamında, iletişim bilgilerime ticari e-ileti gönderilmesine ve sağladığım kişisel verilerin bu kapsamda kullanılmasına izin veriyorum.
    </Typography>
  }
  sx={{ marginTop: 2 }}
/>



              {/* Gönder Butonu */}
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: "100%",
                  background: "linear-gradient(to right, #000 0%, #FFD700 50%, #FFC300 100%)",
                  backgroundSize: "200% 100%", // Gradient genişliği artırıldı
                  backgroundPosition: "right center", // Başlangıç pozisyonu
                  color: "#000",
                  fontWeight: "bold",
                  mt: 4,
                  borderRadius: "25px", // Köşeleri yuvarlama
                  padding: "10px 20px",
                  textDecoration: "none",
                  border: "none",
                  cursor: "pointer",
                  transition: "background-position 0.5s ease, transform 0.3s ease",
                  "&:hover": {
                    backgroundPosition: "left center", // Hover durumunda sola geçiş
                    transform: "scale(1.05)", // Hafif büyüme efekti
                    color: "#FFF", // Daha parlak beyaz
                    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
                  },
                }}
              >
                Kayıt Ol
              </Button>
            </form>
          </Box>
        </Box>
        <Footer />
        <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      </main>
    </div>
  );
};

export default Register;
