import React, {  useRef } from "react";
import { Box, Typography} from "@mui/material";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
import ShoppingCart from "../components/ShoppingCart";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { motion } from "framer-motion";
const Catalog = () => {

  const textRef = useRef(null);
  const textRef2 = useRef(null);


  
  const handleViewCatalog = () => {
    const pdfUrl = `${process.env.REACT_APP_API_URL}/pdf/view`;
    window.location.href = pdfUrl;
  };


  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "0",
        margin: "0",
      }}
    >
      <Navbar />

      <Box
      sx={{width:{xs:"90%",md:"95%"},mx:"auto"}}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Typography variant="h2" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif", }}>
              KATALOĞUMUZ
            </Typography>
          </motion.div>
        </Box>

      <main>
        <Box sx={{ p: 3, display: "flex", justifyContent: "space-between",my:3 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Kataloğumuz Yapım Aşamasındadır.
              </Typography>
          {/* <Box
            sx={{
              width: "45%",
              height: "200px",
              backgroundColor: "#f5f5f5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              cursor: "pointer",
              boxShadow: 3,
              position: "relative",
              transition: "transform 0.3s ease",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}

            >
              <PictureAsPdfIcon sx={{ fontSize: "50px", color: "#1C427A" }} />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Katalog
              </Typography>

              <Typography
                ref={textRef} 
                sx={{
                  color: "gray",
                  opacity: 0,
                  position: "absolute",
                  bottom: 10,
                  visibility: "block",
                  transition: "opacity 0.3s ease",

                }}
                
              >
                İndirmek için tıklayın
              </Typography>
              <Typography
                sx={{
                  color: "gray",
                  
                  position: "absolute",
                  bottom: 10,
                  display:{xs:"block",md:"none"},
                  transition: "opacity 0.3s ease",

                }}
                
              >
                İndirmek için tıklayın
              </Typography>
            </Box>

            <a
              href={`${process.env.REACT_APP_API_URL}/pdf/download`}
              download
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
                textDecoration: "none",
              }}
              onMouseEnter={() => {

                if (textRef.current) textRef.current.style.opacity = 1;
              }}
              onMouseLeave={() => {

                if (textRef.current) textRef.current.style.opacity = 0;
              }}
              aria-label="Download catalog"
            />
          </Box>

     
          <Box
                        onClick={handleViewCatalog}
            sx={{
              width: "45%",
              height: "200px",
              backgroundColor: "#f5f5f5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              cursor: "pointer",
              boxShadow: 3,
              position: "relative",
              transition: "transform 0.3s ease",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)"; 
              if (textRef2.current) textRef2.current.style.opacity = 1; 
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              if (textRef2.current) textRef2.current.style.opacity = 0;
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}

            >

              <DocumentScannerIcon sx={{ fontSize: "50px", color: "#1C427A" }} />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                E-Katalog
              </Typography>
              <Typography
    ref={textRef2} 
    sx={{
      color: "gray",
      position: "absolute",
      bottom: 10,
      opacity: 0, 
      transition: "opacity 0.3s ease", 
    }}
  >
    E-Katalog Görüntüle
  </Typography>
  <Typography
                sx={{
                  color: "gray",
                  
                  position: "absolute",
                  bottom: 10,
                  display:{xs:"block",md:"none"},
                  transition: "opacity 0.3s ease",

                }}
                
              >
                E-Katalog Görüntüle
              </Typography>

            </Box>
          </Box> */}
        </Box>

        <ShoppingCart />
      </main>

    

      <Footer />
    </div>
  );
};

export default Catalog;
