import React,{useState,useEffect} from "react";
import { Box, Card, CardMedia, CardContent, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import '../styles/ProductCard.css';
import { fetchProducts } from "../services/productService";

const ProductList = ({ searchQuery = "", filters = { category: "", priceRange: [0, 5000] } }) => {
  const navigate = useNavigate();
  const MotionCardMedia = motion(CardMedia);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const data = await fetchProducts();
        setProducts(data);
      } catch (error) {
        console.error("Error loading products:", error);
      }
    };

    loadProducts();
  }, []);


  const filteredProducts = products.filter((product) => {
    const matchesSearch = product.name.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = !filters.category || product.category === filters.category;
    const matchesPrice =
      product.price >= filters.priceRange[0] && product.price <= filters.priceRange[1];
    return matchesSearch && matchesCategory && matchesPrice;
  });


  const handleCardClick = (productName) => {
    navigate(`/urunlerimiz/${productName}`);
  };

  return (
    <Box sx={{ p: 2, mt: 1,pr:{xs:2,md:10} }}>
      <Grid container spacing={2}>
        {filteredProducts.map((product) => (
          <Grid sx={{position:"relative"}} item xs={6} sm={6} md={3} key={product.id}>
            <div className="neon-card">
              <Card onClick={() => handleCardClick(product.id)} sx={{    minHeight: {xs:"none",md:"415px"}}}>
              <MotionCardMedia
  component="img"
  image={`${process.env.REACT_APP_BASE_URL}${product.photo_url}`}
  alt={product.name}
  sx={{
    objectFit: "contain",
    width: "100%",
    height: { xs: "150px", sm: "200px", md: "200px" },
    my:4

  }}
  whileHover={{
    scale: 1.1,
    transition: { duration: 0.3 },
  }}
/>
                <CardContent sx={{ p: { xs: 0, sm: 1 },m:0 }}>
                <Typography
  sx={{
    color: "black",
    mb: { xs: 1, md: 4 },
    ml: 1,
    fontFamily: "'Poppins', sans-serif",
    fontSize: { xs: "12px", sm: "14px", md: "15px" },
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",

  }}
  variant="h6"
>
{window.innerWidth <= 600 // Telefon modunda olup olmadığını kontrol et
    ? product.name.split("|")[0].length > 16
      ? `${product.name.split("|")[0].slice(0, 16)}...`
      : product.name.split("|")[0]
    : product.name.split("|")[0]}{" "}
  | <br />
  {product.name.split("|")[1]}
</Typography>




                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: "bold",
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: { xs: "12px", sm: "18px" },
                      ml:1
                    }}
                  >
                    {`${product.price} TL`}
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductList;
