import React from "react";
import { Container, Typography } from "@mui/material";
import Navbar from "../components/navbar";
import Footer from '../components/Footer';
const UyelikSozlesmesi = () => {
  return (
    <>
    <Navbar />
    <Container>
    <Typography variant="h4" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",mb:2 }}>
              Üyelik Sözleşmesi
            </Typography>
            <Typography variant="body1" paragraph>
            Bu internet sitesine girip üye olmanız herhangi bir bilgiyi kullanmanız aşağıdaki koşulları kabul ettiğiniz anlamına gelir.
      </Typography>
      <Typography variant="body1" paragraph>
      Bu internet sitesine girilmesi sitedeki bilgilerin ve diğer verilerin programların vs. kullanılması sebebiyle, 
sözleşmenin ihlali, haksız fiil, ya da başkaca sebeplere binaen, doğabilecek doğrudan ya da dolaylı hiçbir 
zarardan LOKMAN ÖZBEK ÖZBEK SUCUKLARI - www.ozbeksucuklari.com.tr sorumlu değildir. 
www.ozbeksucuklari.com.tr  , sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde; 
işlemin kesintiye uğraması, hata, ihmal, kesinti hususunda herhangi bir sorumluluk kabul etmez.
 www.ozbeksucuklari.com.tr , internet sitesine girilmesi yolu ile kullanıcıların, bir başkasına ait IP adresi, 
elektronik posta adresi, kullanıcı adı gibi kişisel bilgileri kullanması halinde bundan dolayı doğabilecek 
hiç bir hukuki ve cezai yükümlülüğü kabul etmemektedir.
 www.ozbeksucuklari.com.tr , işbu site ve site uzantısında mevcut her tür hizmet, ürün, kullanma 
koşulları ile sitede sunulan bilgileri önceden bir ihtara gerek olmaksızın değiştirme, yeniden organize 
etme, yayını durdurma hakkını saklı tutar. Değişiklikler sitede yayım anında yürürlüğe girer ve 
değişikliklerin takip edilmesi kullanıcıların sorumluluğu altındadır. Sitenin kullanımı ya da siteye giriş ile 
bu değişiklikler de kabul edilmiş sayılır.
 www.ozbeksucuklari.com.tr'nin , kullanıcı tarafından siteye kayıt formunda yazılmış olan veya daha 
sonra kendisi tarafından güncellenen adresi, e-posta adresi, sabit ve mobil telefon hatları ve diğer iletişim 
bilgileri üzerinden mektup, e-posta, SMS, telefon görüşmesi ve diğer yollarla iletişim, pazarlama, reklam, 
tanıtım, bildirim ve diğer amaçlarla kullanıcıya ulaşma hakkı bulunmaktadır. Kullanıcı işbu sözleşmeyi 
kabul etmekle aksine bir yazılı bildirimi olmadığı müddetçe www.ozbeksucuklari.com.tr ‘nin kendisine 
yönelik yukarıda belirtilen iletişim faaliyetlerinde bulunabileceğini kabul ve beyan etmektedir. Kullanıcı, 
işbu Sözleşmeyi onaylayarak, www.ozbeksucuklari.com.tr ile paylaşmış olduğu tüm bilgilerin şahsına ait 
olduğunu ve bu bilgilerin, satış ve pazarlama faaliyetlerinin yürütülebilmesi amacıyla ve her türlü iletişim 
aracına uygun bildiriminin sağlanması için tüm  www.ozbeksucuklari.com.tr İş ortakları ile de 
paylaşılabileceğine onay vermektedir.
      </Typography>
      <Typography variant="body1" paragraph>
      Bu internet sitesi  www.ozbeksucuklari.com.tr ’nin kontrolü altında olmayan başka internet sitelerine 
bağlantı veya referans içerebilir.
 www.ozbeksucuklari.com.tr bu sitelerin içerikleri veya içerdikleri diğer bağlantılardan sorumlu değildir.
 www.ozbeksucuklari.com.tr  bu internet sitesi genel görünüm ve dizaynı ile internet sitesi tüm bilgi, 
resim, marka, alan adı, logo, ikon, demonstratif, yazılı, elektronik, grafik veya makinede okunabilir 
şekilde sunulan teknik veriler, bilgisayar yazılımları, uygulanan satış sistemi, iş metodu ve iş modeli de 
dahil tüm materyallerin (“Materyaller”) ve bunlara ilişkin fikri ve sınai mülkiyet haklarının sahibi veya 
lisans sahibidir ve yasal koruma altındadır. Internet sitesinde bulunan hiçbir materyal; önceden izin 
alınmadan ve kaynak gösterilmeden, kod ve yazılım da dahil olmak üzere, değiştirilemez, kopyalanamaz, 
başka bir lisana çevrilemez, yeniden yayımlanamaz, başka bir bilgisayara yüklenemez, postalanamaz, 
iletilemez, sunulamaz ya da dağıtılamaz. Internet sitesinin bütünü veya bir kısmı başka bir internet 
sitesinde izinsiz olarak kullanılamaz. Aksine hareketler hukuki ve cezai sorumluluğu gerektirir.
 www.ozbeksucuklari.com.tr 'nin burada açıkça belirtilmeyen diğer tüm hakları saklıdır.
 İnternet sitesini ziyaret eden kullanıcıların bilgileri (ziyaret süresi, zamanı, görüntülenen sayfalar) takip 
edilmekte ve kayıt altına alınmaktadır. Bu bilgiler, gizlilik koşulları dahilinde, daha iyi hizmet 
verebilmek, internet sitesinin kullanıcılarına sunduğu deneyimi geliştirmek, içeriği genişletmek ve 
iyileştirmek amacı ile reklam vb. konularda işbirliği yaptığımız firmalarla paylaşılmaktadır.
 Kullanıcı, kayıt formunu doldurup onayladığı veya bu sistemi kullanarak herhangi bir hizmet almaya 
başladığı andan itibaren ya da sipariş verdiğinde işbu kullanıcı sözleşmesi yürürlüğe girmektedir.
 www.ozbeksucuklari.com.tr dilediği zaman işbu sözleşmeyi ve internet sitesindeki bulunan yasal uyarı 
sayfasının içeriğini güncelleme hak ve yetkisini saklı tutmaktadır ve kullanıcılarına siteye veya her girişte 
yasal uyarı ve kullanıcı sözleşmesi sayfasını ziyaret etmelerini tavsiye etmektedir.
 Müşteri, işbu Gizlilik Sözleşmesini onaylayarak www.ozbeksucuklari.com.tr   
ve 
her 
ile paylaşmış olduğu 
bilgilerin şahsına ait olduğunu ve bu bilgilerin, satış ve pazarlama faaliyetlerinin yürütülebilmesi 
amacıyla 
türlü 
iletişim 
aracına 
uygun 
bildiriminin 
sağlanması 
www.ozbeksucuklari.com.tr'nin İş ortakları ile de paylaşılabileceğine onay vermektedir.
 için
 www.ozbeksucuklari.com.tr 'nin , üyelik formlarından topladığı bilgileri, söz konusu üyenin haberi ya da 
aksi bir talimatı olmaksızın, üçüncü şahıslarla kesinlikle paylaşmamakta, faaliyet dışı hiçbir nedenle 
ticari amaçla kullanmamakta veya satmamaktadır.
      </Typography>
      <Typography variant="body1" paragraph>
      www.ozbeksucuklari.com.tr , e-posta adresleri ve üyelik formlarında istediği kişisel bilgilerin haricinde 
site kullanımı sırasında izlediği, ziyaretçi hareket ve tercihlerini analiz ederek yorumlamakta veya 
yorumlatmaktadır. Kişisel bilgiler içermeyen bu istatistiksel veriler,  www.ozbeksucuklari.com.tr 'nin 
müşterilerine daha özel ve etkin bir alışveriş deneyimi yaşatmak amacıyla www.ozbeksucuklari.com.tr iş 
ortakları ile paylaşılabilmektedir.
 Müşteri bilgileri, ancak resmi makamlarca usulü dairesinde bu bilgilerin talep edilmesi halinde ve 
yürürlükteki emredici mevzuat hükümleri gereğince resmi makamlara açıklama yapmak zorunda olduğu 
durumlarda resmi makamlara açıklanabilecektir.
 Müşterinin sisteme girdiği tüm bilgilere sadece Müşteri ulaşabilmekte ve bu bilgileri sadece Müşteri 
değiştirebilmektedir. Bir başkasının bu bilgilere ulaşması ve bunları değiştirmesi mümkün değildir. 
Müşterinin üyelik adresi ve şifresinin üçüncü bir kişi tarafından biliniyor ve üyeliğin kullanılıyor 
olmasından dolayı www.ozbeksucuklari.com.tr  kesinlikle sorumlu değildir.
 www.ozbeksucuklari.com.tr'nin , müşterilerine daha iyi hizmet verebilmek amacıyla bazı kişisel 
bilgilerinizi 
(isim,yaş,ilgialanlarınızı,e-posta 
vb.)sizlerden 
talep 
etmektedir 
www.ozbeksucuklari.com.tr'nin bilgisayarlarında toplanan bu bilgiler, dönemsel kampanya çalışmaları, 
müşteri profillerine yönelik özel promosyon faaliyetlerinin kurgulanması ve istenmeyen e-postaların 
iletilmemesine yönelik müşteri “sınıflandırma” ve kişisel bilgiler vasıtasıyla oluşturulacak reklam, 
tanıtım, pazarlama faaliyetlerinin devam etmesi amacıyla saklanmakta ve bu amaçlarla kullanılmaktadır.
 Ödeme sayfasında istenen kredi kartı bilgileriniz, siteden alışveriş yapan siz değerli müşterilerimizin 
güvenliğini en üst seviyede tutmak amacıyla hiçbir şekilde www.ozbeksucuklari.com.tr 'nin veya ona 
hizmet veren şirketlerin sunucularında tutulmamaktadır. Bu şekilde ödemeye yönelik tüm işlemlerin 
kullanıcı ara yüzü üzerinden banka ve bilgisayarınız arasında gerçekleşmesi sağlanmaktadır.
      </Typography>
      <Typography variant="h5" paragraph>
      GİZLİLİK VE KULLANIM
      </Typography>
      <Typography variant="body1" paragraph>
      www.ozbeksucuklari.com.tr internet sayfalarına girdiğinizde, aşağıdaki şartları kabul etmiş sayılırsınız: 
Üyelerimizin-Müşterilerimizin www.ozbeksucuklari.com.tr'ye vermiş olduğu bilgilerin gizliliği, 
korunması ve ilgili diğer hususlarda aşağıda belirtilmektedir.
 Sitemize girilen bilgilerin ve işlemlerin güvenliği için gerekli önlemler, bilgi ve işlemin mahiyetine göre 
"www.ozbeksucuklari.com.tr" ve/veya ilgili Kart Kuruluşlarınca sistem ve internet altyapısında 
alınmıştır. Tüm kredi kartı işlemleri ve onayları tarafımızdan bağımsız olarak ilgili Banka ve benzeri 
Kart Kuruluşları‘nca online olarak aranızda gerçekleştirilmektedir (Kredi kartı şifresi gibi bilgiler " 
www.ozbeksucuklari.com.tr tarafından KESİNLİKLE görülmez ,tutulmaz ve kaydedilmez).
      </Typography>
    </Container>
    <Footer/>
    </>
  );
};

export default UyelikSozlesmesi;
