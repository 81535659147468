import React, { createContext, useContext, useState, useEffect } from "react";
import { Snackbar, Alert, Slide } from "@mui/material";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "info", // success, error, warning, info
  });

  useEffect(() => {
    // Sayfa yenilendiğinde yerel depolamadan bildirim verisini al
    const storedMessage = localStorage.getItem("notificationMessage");
    const storedSeverity = localStorage.getItem("notificationSeverity");

    if (storedMessage) {
      setNotification({ open: true, message: storedMessage, severity: storedSeverity });
      
      // Bildirim gösterildikten sonra yerel depolamayı temizle
      localStorage.removeItem("notificationMessage");
      localStorage.removeItem("notificationSeverity");
    }
  }, []);

  const showNotification = (message, severity = "info") => {
    setNotification({ open: true, message, severity });
  };

  const hideNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      <Snackbar
        open={notification.open}
        autoHideDuration={3000}
        onClose={hideNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={SlideTransition}
        onExited={hideNotification}
      >
        <Alert
          onClose={hideNotification}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};
