import React, { useState } from "react";
import { IconButton, Drawer, Typography, Box, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeFromCart,decreaseQuantity   } from "../redux/cartSlice";
import Badge from "@mui/material/Badge";
import { motion } from "framer-motion";
const ShoppingCart = () => {
  const [isCartOpen, setCartOpen] = useState(false);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/sepetim");
  };
  const cartItems = useSelector((state) => state.cart.items);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const dispatch = useDispatch();
  // Örnek ürünler
  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  const handleDecreaseQuantity = (id) => {
    dispatch(decreaseQuantity(id));
  };
  
  const increaseQuantity = (id) => {
    dispatch(addToCart({ id })); // Redux action ile quantity artırılır
  };
  
  const removeItem = (id) => {
    dispatch(removeFromCart(id));
  };
  
  const clearCart = () => {
    dispatch({ type: "cart/clearCart" }); // `cartSlice`'da clearCart action'ı olmalı
  };

  // Toplam tutar hesaplama
  const shippingFee = totalAmount < 2000 ? 150 : 0;
  const totalWithShipping = totalAmount + shippingFee;
  const toggleCart = (open) => () => {
    setCartOpen(prevState => !prevState);
  };

  return (
    <div>
      {/* Alışveriş Sepeti Butonu */}
      <IconButton
        edge="end"
        color="inherit"
        onClick={toggleCart(true)}
        sx={{
          position: "fixed",
          right: isCartOpen ? "352px" : "0px",
          "@media (max-width: 600px)": {
            right: isCartOpen ? "302px" : "0px",
          },
          transition: "right 0.225s cubic-bezier(0, 0, 0.2, 1)",
          top: "50%",
          transform: "translateY(-50%)",
          height: { xs: "80px", sm: "120px" },
          zIndex: 1201,
          background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "20px 0 0 20px",
          padding: { xs: "0px 15px", sm: "10px 20px" },
        }}
      >
              <Badge
      badgeContent={totalItems}
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: "#9c7338",
          color: "#fff",
          fontSize: "12px",
          fontWeight: "bold",
          height: "20px",
          minWidth: "20px",
          borderRadius: "50%",
          boxShadow: "0 0 4px rgba(0, 0, 0, 0.5)",
            top: {xs:"-45px",sm:"-65px",md:"-65px"}, // Yukarıdan 10px
            left: "-20px", // Soldan 15px
            transform: "none", // Varsayılan `translate` özelliğini iptal eder
        },
      }}
    >
      </Badge>
        <ShoppingCartIcon sx={{ color: "#fff", fontSize: { xs: "18px", sm: "24px" } }} />
      </IconButton>


      {/* Sağdan Açılan Drawer */}
      <Drawer
        anchor="right"
        open={isCartOpen}
        onClose={toggleCart(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "300px",
            "@media (max-width: 600px)": {
              width: "250px",
            },
            padding: "20px",
            display: "flex",
            backgroundColor: "#D2D2D5",
            flexDirection: "column",
            justifyContent: "space-between",
           
          },
        }}
      >
        {/* Ürün Listesi */}
        <Box
  sx={{
    maxHeight: "500px",
    overflowY: "auto",
    padding: "10px",
    borderBottom: "1px solid #9c7338",
    "@media (max-width: 600px)": {
      borderBottom: "none",
    },
  }}
>
  <List sx={{ padding: 0 }}>
    {cartItems.length > 0 ? (
      cartItems.map((item) => (
        <ListItem
          key={item.id}
          sx={{
            display: "flex",
            flexDirection: "column", // Dikey düzene geçiş
            alignItems: "flex-start",
            borderBottom: "1px solid #9c7338",
            padding: "10px 0",
            position: "relative", // Silme butonunu sağ üst köşeye yerleştirebilmek için
            overflowX:"hidden",
            
          }}
        >

          {/* Ürün Bilgisi ve Silme Butonu */}
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <ListItemAvatar>
          <Box
  sx={{
    width: 66,
    height: 66,
    overflow: "hidden", // Çerçevenin dışına taşan kısmı gizler
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  }}
>
  <img
    src={item.image}
    alt={item.name}
    style={{
      width: "100%",
      height: "100%",
      objectFit: "contain", // Görüntüyü boyutlara sığdırır
    }}
  />
</Box>
          </ListItemAvatar>
            <ListItemText
              primary={
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    marginLeft: "10px",
                    flexGrow: 1, // Ismin alanı genişletilsin
                    textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
                    fontFamily: "'Sansita', sans-serif",
                    color:"#fff"
                  }}
                >
                  {item.name}
                </Typography>
              }
              sx={{ flexGrow: 1 }}
            />

            {/* Sil Butonu */}
            <IconButton
              edge="end"
              color="error"
              onClick={() => removeItem(item.id)}
              sx={{
                position: "absolute",
                top: "20%",
                right: "0px",
                fontSize: "18px",
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>

          {/* Ürün Adedi ve Fiyat */}
          <Box
            sx={{
              mt:1,
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between", // Adet ve fiyatı aralarına boşluk bırakacak şekilde
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => handleDecreaseQuantity(item.id)} sx={{ padding: 0, }}>
                <RemoveIcon />
              </IconButton>
              <Typography
                variant="body1"
                sx={{
                  padding: "8px 5px",
                  borderRadius: "4px",
                  margin: "0 3px",
                  
                }}
              >
                {item.quantity}
              </Typography>
              <IconButton onClick={() => increaseQuantity(item.id)} sx={{ padding: 0 }}>
                <AddIcon />
              </IconButton>
            </Box>

            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              {`${(item.price * item.quantity).toFixed(2)} TL`}
            </Typography>
          </Box>
        </ListItem>
      ))
    ) : (
      <Typography variant="body1" sx={{fontFamily:"'Poppins', sans-serif"}}>Sepetiniz boş.</Typography>
    )}
  </List>
</Box>

        {/* Toplam Tutar ve Alt Butonlar */}
        {cartItems.length > 0 && (
        <Box sx={{ mt: 2,borderTop: "1px solid #000",pt: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "16px", color: "#333", mb: 1, }} >
        <Typography variant="body1" sx={{fontFamily:"'Poppins', sans-serif"}}>Ürünler Toplamı:</Typography>
        <Typography variant="body1"  sx={{fontFamily:"'Poppins', sans-serif"}} >
      {totalAmount.toFixed(2)} TL
    </Typography>
        </Box>
        <Box sx={{ display: "flex",fontFamily:"'Poppins', sans-serif", justifyContent: "space-between", alignItems: "center", fontSize: "16px", color: "#333", mb: 1, }} >
  <Typography variant="body1">Kargo Ücreti:</Typography>
  
  {shippingFee > 0 ? (
    <Typography variant="body1" sx={{fontFamily:"'Poppins', sans-serif"}}>
      {shippingFee} TL
    </Typography>
  ) : (
    <Typography variant="body1"  sx={{ textDecoration: "line-through",fontFamily:"'Poppins', sans-serif" }}>
      150 TL
    </Typography>
  )}
</Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "16px", color: "#333", mb: 1, }} >
    <Typography variant="h6" sx={{ fontFamily:"'Poppins', sans-serif" }}>Toplam:</Typography>
    <Typography variant="h6"  sx={{  fontWeight: "bold", color: "#1C427A",fontFamily:"'Poppins', sans-serif" }} >
    {totalWithShipping.toFixed(2)} TL
    </Typography>
  </Box>
  {totalAmount < 2000 && (
  <Typography
    variant="body2"
    sx={{ color: "#000", textAlign:"center", fontFamily: "'Poppins', sans-serif", mt: 1 }}
  >
    {`${(2000 - totalAmount).toFixed(2)} TL daha ekleyin, kargo ücretsiz!`}
  </Typography>
)}
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2, mb: 4, height: "60px" }}>
          <Button
  component={motion.button}
  whileTap={{ scale: 0.95 }}
  sx={{
    width: "48%",
    background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
    backgroundSize: "200% 100%", // Gradient genişliği artırıldı
    backgroundPosition: "right center", // Başlangıç pozisyonu
    color: "#fff",
    fontWeight: "bold",

    borderRadius: "8px", // Köşeleri yuvarlama
    padding: "10px 20px",
    textDecoration: "none",
    border: "none",
    cursor: "pointer",
    transition: "background-position 0.5s ease, transform 0.3s ease",
    "&:hover": {
      backgroundPosition: "left center", // Hover durumunda sola geçiş
      transform: "scale(1.01)", // Hafif büyüme efekti
      color: "#F0F8FF", // Daha parlak beyaz
      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
    },
  }}
  onClick={handleButtonClick}
>
  Sepete Git
</Button>

            <Button
              component={motion.button}
              whileTap={{ scale: 0.95 }}
              sx={{
                width: "48%",
                background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
                backgroundSize: "200% 100%", // Gradient genişliği artırıldı
                backgroundPosition: "right center", // Başlangıç pozisyonu
                color: "#fff",
                fontWeight: "bold",
            
                borderRadius: "8px", // Köşeleri yuvarlama
                padding: "10px 20px",
                textDecoration: "none",
                border: "none",
                cursor: "pointer",
                transition: "background-position 0.5s ease, transform 0.3s ease",
                "&:hover": {
                  backgroundPosition: "left center", // Hover durumunda sola geçiş
                  transform: "scale(1.01)", // Hafif büyüme efekti
                  color: "#F0F8FF", // Daha parlak beyaz
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
                },
              }}
              onClick={clearCart}
            >
              Temizle
            </Button>
          </Box>
        </Box>)}
      </Drawer>
    </div>
  );
};

export default ShoppingCart;
