import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      navigate('/');
      return;
    }

    axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-token`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(response => {
      if (!response.data.valid) {
        navigate('/');
      }
    })
    .catch(() => {
      navigate('/');
    });
  }, [navigate]);
};

export default useAuth;
