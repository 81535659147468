import React from "react";
import { Box, Typography, Grid } from "@mui/material";

const ProductFeatures = ({ details }) => {
  const nutritionData = [
    { label: "ENERJİ (KCAL/KJ)", value: details.enerji_kcal || "N/A",text: "(KCAL/KJ)" },
    { label: "PROTEİN (G)", value: details.protein || "N/A",text: "G"  },
    { label: "YAĞ (G)", value: details.yag || "N/A",text: "G"  },
    { label: "DOYMUŞ YAĞ (G)", value: details.doymus_yag || "N/A",text: "G"  },
    { label: "KARBONHİDRAT (G)", value: details.karbonhidrat || "N/A",text: "G"  },
    { label: "ŞEKERLER (G)", value: details.seker || "N/A",text: "G"  },
    { label: "TUZ (G)", value: details.tuz || "N/A",text: "G"  },
  ];

  // İkon Verileri
  const iconData = [
    { icon: "/agirlik.png", label: "Koli Ağırlığı", value: details.koli_agirligi || "N/A",text: "KG"  },
    { icon: "/kutu.png", label: "Koli İçi Adeti", value: details.koli_ici_adet || "N/A",text: "Adet"  },
    { icon: "/saat.png", label: "Raf Ömrü", value: details.raf_omru || "N/A",text: "Gün"  },
  ];
  return (
    <Box sx={{ p: {sx:0,md:3}, backgroundColor: "transparent", color: "#000",boxShadow:"none" }}>
      <Grid container>
        {/* Sol Taraf: Besin Değerleri Tablosu */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 3,
              backgroundColor: "transparent",
              borderRadius: "10px",
              boxShadow:"none"
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", mb: 3, textAlign: "center",fontFamily: "'Sansita', sans-serif",fontSize:{xs:"16px",md:"34px"} }}
            >
              Besin ve Enerji Değerleri (100 G. için)
            </Typography>
            {nutritionData.map((item, index) => (
             <Box
             key={index}
             sx={{
               display: "flex",
               justifyContent: "space-between",
               py: 1,
               pr: 1,
               fontSize: "16px",
               position: "relative",
               
               "&::after": {
                 content: '""',
                 position: "absolute",
                 bottom: 0,
                 left: "50%", // Çizginin başlangıç konumu
                 width: "50%", // Çizginin boyutu
                 height: "1px", // Yükseklik ekleyelim
                 background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)", // Gradyan arka plan
               },
             }}
           >
           
                <Typography sx={{fontFamily: "'Poppins', sans-serif",fontSize:{xs:"12px",md:"16px"}}}>{item.label}</Typography>
                <Typography sx={{fontFamily: "'Poppins', sans-serif",fontSize:{xs:"12px",md:"16px"}}}>{item.value} {item.text}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Sağ Taraf: İkonlu Yazılar */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              p: 3,
              backgroundColor: "transparent",
              borderRadius: "10px",
              boxShadow: "none",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            {iconData.map((item, index) => (
              <Box
  key={index}
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",  
    color: "#fff",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "50%",
      width: "50%",
      height: "1px",
      background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
    },
  }}
>
<Box
  sx={{
    width: "100px", 
    height: "101px",
    "@media (max-width: 768px)": {
      width: "50px",
      height: "51px",
    },
  }}
/>
               <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
  <Typography
    variant="body1"
    sx={{
      fontWeight: "bold",
      fontSize:{xs:"12px",md:"20px"},
      color: "#9c7338",
      textAlign: "right", // Yazıyı sağa hizalama
      fontFamily: "'Poppins', sans-serif"
    }}
  >
    {item.label}
  </Typography>
  <Typography
    variant="body2"
    sx={{
      fontSize:{xs:"12px",md:"16px"}, 
      color: "#72747a",
      textAlign: "right", // Yazıyı sağa hizalama
      fontFamily: "'Poppins', sans-serif"
    }}
  >
    {item.value} {item.text}
  </Typography>
</Box>

              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductFeatures;
