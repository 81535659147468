import React from "react";
import { Box, Typography, Container, Grid, Paper } from "@mui/material";
import { motion } from "framer-motion";
import Navbar from "../components/navbar";
import Footer from '../components/Footer';
import ShoppingCart from "../components/ShoppingCart";

const About = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "0",
        margin: "0"
      }}
    >
      <Navbar />
      <Box
      sx={{width:{xs:"90%",md:"75%"},mx:"auto"}}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Typography variant="h2" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif", }}>
              Hakkımızda
            </Typography>
          </motion.div>
        </Box>
      <main>
        <Container
          sx={{
            mt: 5,
            paddingBottom: "3rem",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#000",
                mb: 4,
                fontSize: {xs: "0.9rem",sm: "1rem",md: "1.2rem"},
                fontFamily: "'Poppins', sans-serif",
              }}
            >
               90'lı yıllarda sektöre giren ve yatırımlarını arttırarak sürdüren Lokman Özbek Sucukları, kurulduğu günden bu yana kalite ve hijyen kurallarını ön planda tutarak, ilk hedef olarak tüketicinin güvenini kazanmayı hedeflemiştir. Firma her geçen gün büyümesine devam etmekte ve üretiminde göstermiş olduğu titizlik ve çaba sayesinde et mamüllerinde, Afyon kapsamında rakipleri arasında öncelikli yer edinmiştir. Gıda sektörünün etürünleri alanında geleneksel lezzetlerimizi uluslararası hizmet standartlarında müşteri memnuniyetini esas tutarak, kalite yönetimini ve gıda güvenliği ve ISO 22000 kalite güvence sistemi ile belgeledik. Firmamız başta Afyonkarahisar ve çevresinde bşlayan talepler, daha sonrasında Ege, İç Anadolu ve Akdeniz illerine kadar yayıldı. Perakende üretim ile başlayan teşebbüs günden güne büyüyerek tonlarla ifade edilen bir kapasiteye ulaşmıştır.
            </Typography>
          </motion.div>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Paper
                  sx={{

                    color:"#000",
                    boxShadow: 0,
                    backgroundColor:"transparent",
                  }}
                >
                  <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2,color:"#fff",fontFamily: "'Sansita', sans-serif",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)" }}>
                    Misyonumuz
                  </Typography>
                  <Typography sx={{ color: "#000",fontFamily: "'Poppins', sans-serif", }}>
                  Sektöre geçmiş birikimleriyle olgunluğunu, gençliğinin verdiği dinamizimle yenilikçiliğini katan, müşterinin güvenip aradığı bir marka olabilmek için her ürünü aynı titizlikle üreten, emeğe, çevreye ve çalışanına saygılı, doğal kaynakları verimli kullanan, takip eden değil takip edilen bir marka olup, oluşturduğu değerlerle gıda sektörüne yeni bir soluk katabilmektir.
                  </Typography>
                </Paper>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Paper
                  sx={{
                    backgroundColor:"transparent",
                    boxShadow: 0,
                  }}
                >
                  <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2,color:"#fff",fontFamily: "'Sansita', sans-serif",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)" }}>
                    Vizyonumuz
                  </Typography>
                  <Typography sx={{ color: "#000",fontFamily: "'Poppins', sans-serif", }}>
                  Ülkemizdeki her evi Özbek markasıyla tanıştırmak, kaynak kullanımında verimliliği arttıran üretim tekniklerini kullanmak tedarikçiden müşteriye kadar üretim zincirindeki herkeste güven oluşturabilmek, AR-GE çalışmalarını daha etkin hale getirip bu konuda üniversitelerle ve üçüncü kuruluşlarla koordineli çalışarak ürün kalitesini ve çeşitliliğini arttırmak.
                  </Typography>
                </Paper>
              </motion.div>
            </Grid>
          </Grid>
        </Container>

        <Box sx={{ mt: 5 }}>
          <ShoppingCart />
        </Box>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Footer />
        </motion.div>
      </main>
    </div>
  );
};

export default About;
