import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Checkbox, FormControlLabel, Button, Grid, Typography, Paper, MenuItem,IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNotification } from "../../components/NotificationContext";
const ProductEdit = () => {
  useAuth();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productData, setProductData] = useState({
    description: '',
    name: '',
    price: '',
    in_stock: false,
  });
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const showNotification = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get( `${process.env.REACT_APP_API_URL}/product`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProducts(response.data);
      } catch (error) {
        showNotification("Ürünler Getirilemedi!", "error");
      }
    };
    fetchProducts();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setProductData({
      description: product.description,
      name: product.name,
      price: product.price,
      in_stock: product.in_stock,
      sort_order: product.sort_order,
    });

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setProductData((prevData) => ({
      ...prevData,
      in_stock: !prevData.in_stock,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file);
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    if (!selectedProduct) return;
  
    const token = localStorage.getItem('token');
    
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/product/${selectedProduct.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      showNotification("Ürün Silme Başarılı!", "success");
      localStorage.setItem("notificationMessage", "Ürün Silme Başarılı!");
      localStorage.setItem("notificationSeverity", "success");
      setLoading(false);
      setProducts(products.filter((product) => product.id !== selectedProduct.id));
      setSelectedProduct(null);
      setProductData({
        description: '',
        name: '',
        price: '',
        in_stock: false,
      });
      setImagePreview('');
    } catch (error) {
      showNotification("Ürün Silme Yapılamadı!", "error");
      setLoading(false);
    }
  };
  
  const handleBackClick = () => {
    navigate(-1);
  };


  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!selectedProduct) return;
  
    const token = localStorage.getItem('token');
  
    const formData = new FormData();
    formData.append('name', productData.name);
    formData.append('description', productData.description);
    formData.append('price', productData.price);
    formData.append('in_stock', productData.in_stock);
    formData.append('sort_order', productData.sort_order);

    if (image) {
      formData.append('photo_url', image);
    } else if (selectedProduct.photo_url) {
      formData.append('photo_url', selectedProduct.photo_url);
    }
  
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/product/${selectedProduct.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showNotification("Ürün Güncelleme Başarılı!", "success");
      localStorage.setItem("notificationMessage", "Ürün Güncelleme Başarılı!");
      localStorage.setItem("notificationSeverity", "success");
      setLoading(false);
      setTimeout(() => {
        navigate('/AdminPanel');
      });
    } catch (error) {
      showNotification("Ürün Güncelleme Yapılamadı!", "error");
      setLoading(false);
    }
  };
  

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{mt:5}}>
      <Grid item xs={12} sm={10} md={10}>
        <Paper elevation={5} style={{ padding: '30px', borderRadius: '15px', backgroundColor: '#ffffff' }}>
        <IconButton onClick={handleBackClick} style={{ marginRight: '10px' }}>
        <ArrowBackIcon />
      </IconButton>
          <Typography variant="h4" gutterBottom align="center" style={{ fontWeight: 600, color: '#3f51b5' }}>
            Ürün Güncelle
          </Typography>
          <div>
            <TextField
              select
              fullWidth
              label="Ürün Seç"
              value={selectedProduct?.id || ''}
              onChange={(e) => handleProductSelect(products.find(p => p.id === e.target.value))}
              variant="outlined"

            >
             {products.map((product) => (
        <MenuItem key={product.id} value={product.id}>
          <img 
            src={`${process.env.REACT_APP_BASE_URL}${product.photo_url}`}
            alt={product.name}
            style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: '4px' }}
          />
          {product.name}
        </MenuItem>
      ))}
            </TextField>
          </div>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Ürün ismi"
              name="name"
              value={productData.name}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
           <TextField
      name="description"
      label="Açıklama"
      value={productData.description}
      onChange={handleChange}
      placeholder="Açıklama"
      multiline
      minRows={4}
      maxRows={6}
      variant="outlined"
      fullWidth
      style={{
        marginBottom: '15px',
        fontSize: '16px',
      }}
    />
            <TextField
              fullWidth
              label="Ücret"
              name="price"
              value={productData.price}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              type="number"
              style={{ marginBottom: '15px' }}
            />
                        <TextField
              fullWidth
              label="Ürün Sırası"
              name="sort_order"
              value={productData.sort_order ?? ""}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              type="number"
              style={{ marginBottom: '15px' }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={productData.in_stock}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="Stockta var mı"
              style={{ marginBottom: '20px' }}
            />
            <div style={{ marginBottom: '20px' }}>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="upload-image"
              />
              <label htmlFor="upload-image">
                <Button variant="contained" color="secondary" component="span">
                  Fotoğraf Yükle
                </Button>
              </label>
              <div
  style={{
    marginTop: '10px',
    backgroundColor: 'grey',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }}
>
  <Typography
    variant="h5"
    style={{ fontWeight: 'bold', color: '#fff', textAlign: 'center' }}
  >
    Fotoğraflar
  </Typography>
  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
    {/* Önceden Yüklenmiş Fotoğraf */}
    <div style={{ textAlign: 'center' }}>
      <Typography
        variant="subtitle1"
        style={{ color: '#fff', fontWeight: 'bold' }}
      >
        Önceki Fotoğraf
      </Typography>
      {selectedProduct?.photo_url ? (
        <img
          src={`${process.env.REACT_APP_BASE_URL}${selectedProduct.photo_url}`}
          alt="Önceki Fotoğraf"
          style={{
            maxWidth: '100%',
            maxHeight: '300px',
            objectFit: 'contain',
            borderRadius: '8px',
            border: '2px solid gold',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        />
      ) : (
        <Typography
          variant="body2"
          style={{
            color: '#fff',
            textAlign: 'center',
            fontStyle: 'italic',
            height: '300px',
            width: '200px',
            border: '2px solid gold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Önceki fotoğraf mevcut değil.
        </Typography>
      )}
    </div>

    {/* Yeni Seçilen Fotoğraf */}
    <div style={{ textAlign: 'center' }}>
      <Typography
        variant="subtitle1"
        style={{ color: '#fff', fontWeight: 'bold' }}
      >
        Yeni Fotoğraf
      </Typography>
      {imagePreview ? (
        <div style={{ position: 'relative' }}>
          <img
            src={imagePreview}
            alt="Yeni Fotoğraf"
            style={{
              maxWidth: '100%',
              maxHeight: '300px',
              objectFit: 'contain',
              borderRadius: '8px',
              border: '2px solid gold',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          />
          <button
            onClick={() => {
              setImage(null); // Yeni fotoğrafı sıfırla
              setImagePreview(''); // Görsel önizlemeyi sıfırla
            }}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: 'red',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              cursor: 'pointer',
              padding: '5px 10px',
              fontSize: '16px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
          >
            ✕
          </button>
        </div>
      ) : (
        <Typography
          variant="body2"
          style={{
            color: '#fff',
            textAlign: 'center',
            fontStyle: 'italic',
            height: '300px',
            width: '200px',
            border: '2px solid gold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Yeni fotoğraf seçilmedi.
        </Typography>
      )}
    </div>
  </div>
</div>


            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              style={{
                marginTop: '20px',
                padding: '12px',
                fontWeight: 600,
                transition: 'background-color 0.3s',
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = '#303f9f')}
              onMouseOut={(e) => (e.target.style.backgroundColor = '#3f51b5')}
            >
              {loading ? "Güncelleniyor..." : "Güncelle"}
            </Button>
          </form>

          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disabled={loading}
            style={{
                marginTop: '10px',
                padding: '12px',
                fontWeight: 600,
                backgroundColor: '#f50057',
                transition: 'background-color 0.3s',
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = '#c51162')}
            onMouseOut={(e) => (e.target.style.backgroundColor = '#f50057')}
            onClick={handleDelete}
            >
            {loading ? "Siliniyor..." : "Sil"}
            </Button>



        </Paper>
      </Grid>


    </Grid>
  );
};

export default ProductEdit;
