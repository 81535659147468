export const cities = {
  Adana: [
    "Seyhan", "Yüreğir", "Çukurova", "Sarıçam", "Aladağ", "Feke", "İmamoğlu", 
    "Karataş", "Kozan", "Pozantı", "Saimbeyli", "Ceyhan", "Yumurtalık", "Tufanbeyli"
  ],
  Adıyaman: [
    "Merkez", "Kahta", "Gölbaşı", "Samsat", "Besni", "Çelikhan", "Gerger", 
    "Kâhta", "Merkez", "Sincik", "Tut"
  ],
  Afyonkarahisar: [
    "Merkez", "Sandıklı", "Dinar", "Şuhut", "Bolvadin", "Çay", "Kızılören", 
    "Emirdağ", "İhsaniye", "Sultandağı", "Başmakçı", "Hocalar", "Bayat"
  ],
  Ağrı: [
    "Merkez", "Doğubayazıt", "Patnos", "Tutak", "Eleşkirt", "Diyadin", "Taşlıçay", 
    "Hamur", "Ayaş", "Merkez"
  ],
  Aksaray: [
    "Merkez", "Eğriöz", "Kızılkaya", "Sarayönü", "Ağaçören", "Kızılkaya", "Ortaköy", 
    "Güzelyurt", "İhsaniye"
  ],
  Ankara: [
    "Çankaya", "Keçiören", "Yenimahalle", "Mamak", "Altındağ", "Etimesgut", 
    "Sincan", "Pursaklar", "Akyurt", "Bala", "Çubuk", "Elmadağ", "Gölbaşı", 
    "Güdül", "Haymana", "Kazan", "Kızılcahamam", "Nallıhan", "Polatlı", "Şereflikoçhisar"
  ],
  
  Antalya: [
    "Muradpaşa", "Konyaaltı", "Kepez", "Aksu", "Döşemealtı", "Serik", "Alanya", 
    "Kemer", "Manavgat", "Kumluca", "Demre", "Finike", "Kaş", "Korkuteli", "Gündoğmuş", 
    "İbradı", "Akseki", "Elmalı", "Köprülü"
  ],
  Artvin: [
    "Merkez", "Arhavi", "Borçka", "Hopa", "Murgul", "Şavşat", "Yusufeli", "Deriner"
  ],
  Aydın: [
    "Efeler", "Söke", "Nazilli", "Kuşadası", "Didim", "Bozdoğan", "Çine", "Germencik", 
    "Karacasu", "Karpuzlu", "Köşk", "Sultanhisar", "Yenipazar"
  ],
  Balıkesir: [
    "Merkez", "Bandırma", "Ayvalık", "Edremit", "Susurluk", "Karesi", "Gönen", "Sındırgı", 
    "Dursunbey", "Bigadiç", "Kepsut", "İvrindi", "Marmara", "Altıeylül"
  ],
  Bartın: ["Merkez", "Amasra", "Ulus", "Kurucaşile"],
  Batman: [
    "Merkez", "Beşiri", "Gercüş", "Hasankeyf", "Sason", "Kozluk"
  ],
  Bayburt: ["Merkez", "Aydıntepe", "Demirözü"],
  Bilecik: [
    "Merkez", "İnhisar", "Bozüyük", "Gölpazarı", "Osmaneli", "Pazaryeri", "Söğüt"
  ],
  Bingöl: [
    "Merkez", "Genç", "Kığı", "Solhan", "Yedisu", "Adaklı", "Karlıova"
  ],
  Bitlis: [
    "Merkez", "Ahlat", "Hizan", "Mutki", "Tatvan", "Güroymak"
  ],
  Bolu: [
    "Merkez", "Gerede", "Düzce", "Mudurnu", "Göynük", "Seben", "Kıbrıscık", "Mengen"
  ],
  
  Burdur: [
    "Merkez", "Gölhisar", "Bucak", "Karamanlı", "Tefenni", "Çavdır", "Kemer", "Ağlasun", "Altınyayla"
  ],
  Bursa: [
    "Osmangazi", "Nilüfer", "Yıldırım", "İnegöl", "Mudanya", "Gemlik", "Orhangazi", 
    "Büyükorhan", "Keles", "Gürsu", "Harmancık", "Yenişehir", "İznik", "Mustafakemalpaşa", "Orhaneli"
  ],
  Çanakkale: [
    "Merkez", "Biga", "Çan", "Lapseki", "Ezine", "Ayvacık", 
    "Gelibolu", "Eceabat", "Yenice", "Bayramiç", "Kale"
  ],
  
  Çorum: [
    "Merkez", "Alaca", "İskilip", "Osmancık", "Sungurlu", 
    "Dodurga", "Kargı", "Laçin", "Mecitözü", "Ortaköy", "Uğurludağ"
  ],
  
  Denizli: [
    "Merkez", "Pamukkale", "Merkezefendi", "Buldan", "Çal", 
    "Çivril", "Acıpayam", "Babadağ", "Baklan", "Beyağaç", 
    "Bozkurt", "Honaz", "Kale", "Sarayköy", "Tavas"
  ],
  
  Diyarbakır: [
    "Merkez", "Bağlar", "Kayapınar", "Sur", "Eğil", 
    "Bismil", "Çermik", "Çınar", "Dicle", "Hani", 
    "Kulp", "Lice", "Silvan", "Yenişehir"
  ],
  
  Düzce: [
    "Merkez", "Akçakoca", "Çilimli", "Gölköy", 
    "Yığılca", "Cumayeri", "Kaynaşlı"
  ],
  
  Edirne: [
    "Merkez", "Keşan", "Lalapaşa", "Uzunköprü", 
    "Meriç", "İpsala", "Havsa", "Süloğlu"
  ],
  Elazığ: [
    "Merkez", "Keban", "Arıcak", "Alacakaya", 
    "Palu", "Maden", "Sivrice", "Kovancılar", 
    "Kurşunlu", "Çat"
  ],
  
  Erzincan: [
    "Merkez", "Üzümlü", "İliç", "Kemaliye", 
    "Refahiye", "Çayırlı", "Otlukbeli", "Aşkale", 
    "Erzincan Merkez"
  ],
  Erzurum: [
    "Merkez", "Palandöken", "Yakutiye", "Aziziye", 
    "Narman", "Hınıs", "Köprüköy", "Olur", 
    "Şenkaya", "İspir", "Aşkale", "Tekman", "Çat"
  ],
  Eskişehir: [
    "Odunpazarı", "Tepebaşı", "Sarıcakaya", "Mahmudiye", 
    "Günyüzü", "Alpu", "Beylikova", "Çifteler", 
    "İnönü", "Mihalıççık", "Seyitgazi"
  ],
  Gaziantep: [
    "Şahinbey", "Şehitkamil", "Oğuzeli", "Karkamış", 
    "Nizip", "Araban", "Yavuzeli"
  ],
  
  Giresun: [
    "Merkez", "Bulancak", "Espiye", "Şebinkarahisar", 
    "Keşap", "Alucra", "Dereli", "Piraziz", "Yağlıdere", 
    "Eynesil", "Çamoluk", "Köyönü"
  ],
  
  Gümüşhane: [
    "Merkez", "Kelkit", "Şiran", "Torul", "Köse"
  ],
  
  Hakkâri: [
    "Merkez", "Çukurca", "Yüksekova", "Şemdinli"
  ],
  
  Hatay: [
    "Antakya", "İskenderun", "Dörtyol", "Defne", "Reyhanlı"
  ],
  
  Isparta: [
    "Merkez", "Eğirdir", "Keçiborlu", "Atabey", "Gelendost"
  ],
  
  İstanbul: [
    "Kadıköy", "Beşiktaş", "Üsküdar", "Fatih", "Beyoğlu", 
    "Bakırköy", "Şişli", "Sarıyer", "Ataşehir", "Kartal",
    "Avcılar", "Büyükçekmece", "Pendik", "Maltepe", "Silivri", 
    "Zeytinburnu", "Kağıthane", "Beylikdüzü", "Esenyurt", "Arnavutköy", 
    "Başakşehir", "Sultanbeyli", "Esenler", "Tuzla", "Bayrampaşa",
    "Küçükçekmece", "Çekmeköy", "Sancaktepe", "Şile", "Beykoz",
    "Gaziosmanpaşa", "Avcılar", "Bağcılar", "Büyükçekmece"
  ],
  İzmir: [
    "Bornova", "Karşıyaka", "Konak", "Bayraklı", "Çiğli", 
    "Buca", "Menemen", "Aliağa", "Ödemiş", "Seferihisar", 
    "Tire", "Kemalpaşa", "Urla", "Gaziemir", "Balçova", 
    "Narlıdere", "Foça", "Selçuk", "Bergama", "Menderes", 
    "Kınık", "Dikili", "Çeşme", "Karaburun", "Beydağ"
  ],
  
  Kahramanmaraş: [
    "Merkez", "Elbistan", "Afşin", "Andırın", "Dulkadiroğlu", 
    "Onikişubat", "Çağlayancerit", "Ekinözü", "Türkoğlu"
  ],
  
  Karabük: [
    "Merkez", "Safranbolu", "Eflani", "Eskipazar", "Ovacık", 
    "Yenice"
  ],
  
  Kırıkkale: [
    "Merkez", "Delice", "Keskin", "Sulakyurt", "Bahşili", 
    "Çelebi", "Yahşihan"
  ],
  
  Kırklareli: [
    "Merkez", "Lüleburgaz", "Vize", "Pınarhisar", "Demirköy", 
    "Kofçaz", "Babaeski"
  ],
  
  Kırşehir: [
    "Merkez", "Mucur", "Kaman", "Çiçekdağı", "Akpınar", "Boztepe"
  ],
  
  Kilis: ["Merkez", "Polateli", "Musabeyli"],
  Kocaeli: ["Merkez", "İzmit", "Gebze", "Derince", "Çayırova", "Dilovası", "Kartepe"],
  Konya: ["Merkez", "Selçuklu", "Meram", "Karatay", "Akşehir", "Ilgın", "Seydişehir", "Ereğli", "Kulu", "Cihanbeyli", "Kızılören", "Hüyük", "Çumra", "Beyşehir", "Hadim", "Bozkır", "Tuzlukçu", "Yunak", "Derbent", "Güneysınır", "Sarayönü", "Altınekin", "Akören"],
  Kütahya: ["Merkez", "Tavşanlı", "Simav", "Gediz", "Domaniç", "Hisarcık", "Evciler", "Pazarlar", "Aslanapa", "Şaphane", "Altıntaş"],

  Malatya: [
    "Merkez", "Battalgazi", "Yeşilyurt", "Akçadağ", "Doğanşehir", "Hekimhan", "Pütürge", "Arapgir", "Kale", "Doğanyol", "Yazıhan", "Akıncılar"
  ],
  Manisa: [
    "Merkez", "Salihli", "Alaşehir", "Turgutlu", "Demirtaş", "Soma", "Gördes", "Kırkağaç", "Selendi", "Köprübaşı", "Ahmetli", "Saruhanlı", "Şehzadeler", "Yunusemre"
  ],
  Mardin: [
    "Merkez", "Artuklu", "Kızıltepe", "Mazıdağı", "Midyat", "Dargeçit", "Derik", "Nusaybin", "Savur", "Ömerli", "Yeşilli"
  ],
  Mersin: [
    "Merkez", "Mezitli", "Tarsus", "Akdeniz", "Erdemli", "Silifke", "Anamur", "Bozyazı", "Mut", "Aydıncık", "Çamlıyayla"
  ],
  Muğla: [
    "Marmaris", "Bodrum", "Fethiye", "Dalaman", "Muğla Merkez", "Ula", "Köyceğiz", "Ortaca", "Seydikemer", "Datça", "Menteşe", "Beylikova", "Gökova"
  ],
  Nevşehir: [
    "Merkez", "Avanos", "Gülşehir", "Derinkuyu", "Hacıbektaş", "Acıgöl", "Kozaklı", "Nevşehir", "Ürgüp", "Ortahisar"
  ],
  Niğde: [
    "Merkez", "Bor", "Altunhisar", "Ulukışla", "Çiftlik", "Çamardı", "Kemerhisar"
  ],
  Ordu: [
    "Merkez", "Altınordu", "Ünye", "Fatsa", "Perşembe", "Aybastı", "Korgan", "Kumru", "Gölköy", "Çamaş", "İkizce", "Mesudiye", "Kabataş", "Gülyalı", "Çatalpınar"
  ],
  Osmaniye: [
    "Merkez", "Düziçi", "Toprakkale", "Kadirli", "Bahçe", "Sumbas", "Hadırlı", "Kozan"
  ],
  Rize: [
    "Merkez", "Ardeşen", "Çamlıhemşin", "Fındıklı", "Pazar", "Derepazarı", "İyidere", "Kalkandere", "Çayeli", "Güneysu", "Kavaklı", "Rize Merkez"
  ],
  Sakarya: [
    "Adapazarı", "Akyazı", "Geyve", "Hendek", "Sapanca", "Ferizli", "Karapürçek", "Kocaali", "Pamukova", "Söğütlü", "Kaynarca", "Arifiye"
  ],
  Samsun: [
    "Merkez", "Atakum", "İlkadım", "Bafra", "Terme", "Vezirköprü", "Çarşamba", "Ladik", "Salıpazarı", "Asarcık", "Tekkeköy", "Kavak"
  ],
  Siirt: [
    "Merkez", "Eruh", "Pervari", "Şirvan", "Baykan", "Tillo"
  ],
  Sinop: [
    "Merkez", "Ayancık", "Gerze", "Boyabat", "Durağan", "Erfelek", "Türkeli", "Saraydüzü", "Sinop Merkez"
  ],
  Sivas: [
    "Merkez", "Yıldızeli", "Şarkışla", "Kangal", "Zara", "Divriği", "Gölova", "Hafik", "İmranlı", "Koyulhisar", "Sivrialan", "Suşehri", "Yedisu", "Kangal", "Şarkışla"
  ],
  Tekirdağ: [
    "Merkez", "Çorlu", "Marmara Ereğlisi", "Süleymanpaşa", "Ergene", "Muratlı", "Kapaklı", "Şarköy", "Hayrabolu", "Çerkezköy"
  ],
  Tokat: [
    "Merkez", "Zile", "Niksar", "Reşadiye", "Turhal", "Erbaa", "Pazar", "Almus", "Sulusaray"
  ],
  Trabzon: [
    "Merkez", "Akçaabat", "Ortahisar", "Sürmene", "Of", "Çarşıbaşı", "Düzköy", "Hayrat", "Köprübaşı", "Maçka", "Vakfıkebir", "Arsin", "Yomra", "Beşikdüzü", "Tonya"
  ],
  Tunceli: [
    "Merkez", "Pülümür", "Hozat", "Mazgirt", "Pertek", "Ovacık", "Çemişgezek"
  ],
  Şanlıurfa: [
    "Merkez", "Haliliye", "Eyyübiye", "Karaköprü", "Viranşehir", "Birecik", "Bozova", "Ceylanpınar", "Harran", "Kızıltepe", "Suruç"
  ],
  Uşak: [
    "Merkez", "Banaz", "Eşme", "Sivaslı", "Ulubey", "Karahallı"
  ],
  Van: [
    "Merkez", "İpekyolu", "Edremit", "Tuşba", "Çatak", "Gevaş", "Muradiye", "Bahçesaray", "Erciş", "Özalp"
  ],
  Yalova: [
    "Merkez", "Çiftlikköy", "Altınova", "Armutlu", "Termal", "Çınarcık", "Karamürsel", "Tuzla"
  ],
  Yozgat: [
    "Merkez", "Akdağmadeni", "Boğazlıyan", "Çekerek", "Saraykent", "Şefaatli", "Sorgun", "Aydıncık", "Kadışehri", "Yerköy"
  ],
  Zonguldak: [
    "Merkez", "Kozlu", "Ereğli", "Devrek", "Çaycuma", "Alaplı", "Gökçebey", "Kilimli"
  ]
};

  