// components/Footer.js
import React from "react";
import { Container, Grid, Typography, Link, IconButton,Box } from "@mui/material";
import { Facebook, YouTube, Instagram, Phone, WhatsApp,Factory,Email } from "@mui/icons-material";
import '../styles/Footer.css'; // CSS dosyasını dışarıdan ekliyoruz

const Footer = () => {
  return (
    <footer className="footer">
      <Container maxWidth="lg">
        <Grid container spacing={1} direction="row" justifyContent="space-between">
          {/* Logo ve adres kısmı */}
          <Grid item xs={12} md={6}>
            <div className="footer-logo">
              <img src="/ozbek-logo.png" alt="Logo" className="logo" />

              <Typography variant="body1" color="light" sx={{fontSize:{ xs: '12px', sm: '16px' }}}>
  <Factory sx={{ verticalAlign: 'bottom', marginRight: 1,fontSize: '2rem' }} />
  Fabrika: 1. Cadde, 4. Sokak, No:5, Afyon Organize Sanayi Bölgesi, 
      Merkez/Afyonkarahisar
</Typography>
<Typography variant="body1" color="light" sx={{my:1,fontSize:{ xs: '12px', sm: '16px' }}}>
  <Phone sx={{ verticalAlign: 'middle', marginRight: 1,fontSize: '2rem' }} />
  Telefon: 0 (272) 221 1095 | 0 (850) 420 6 925
</Typography>
<Typography variant="body1" color="light" sx={{fontSize:{ xs: '12px', sm: '16px' }}}>
  <WhatsApp sx={{ verticalAlign: 'middle', marginRight: 1,fontSize: '2rem',color:"#25D366" }} />
  Özbek Sucukları Whatsapp: +90 549 221 10 95
</Typography>
<Typography variant="body1" color="light" sx={{mt:1,fontSize:{ xs: '12px', sm: '16px' }}}>
  <Email sx={{ verticalAlign: 'middle', marginRight: 1,fontSize: '2rem' }} />
  E-Posta: ozbek@ozbeksucuklari.com.tr
</Typography>
            </div>
             {/* Sosyal medya ikonları */}
              <Box sx={{mt:2}}> 
            <Typography variant="h6">Bizi Takip Edin</Typography>
            <div className="social-icons">
            <IconButton  sx={{ backgroundColor: 'white',  '&:hover': { backgroundColor: '#f0f0f0'  } }} className="glass-btn blue-btn" color="primary" href="https://www.facebook.com/OzbekSucuk/?locale=tr_TR" target="_blank">
                <Facebook />
              </IconButton>
              <IconButton sx={{ backgroundColor: 'white', color: 'red',  '&:hover': { backgroundColor: '#f0f0f0'  } }} className="glass-btn red-btn"  href="#" target="_blank">
                <YouTube />
              </IconButton>
              <IconButton sx={{ backgroundColor: 'white',  '&:hover': { backgroundColor: '#f0f0f0'  } }} className="glass-btn amber-btn" color="secondary" href="https://www.instagram.com/ozbeksucuk?igsh=b2VvaHF1MXZ3YTI1" target="_blank">
                <Instagram />
              </IconButton>
            </div>
            </Box>
          </Grid>

          {/* Navbar linkleri */}
          <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
  <Typography variant="h6">Bağlantılar</Typography>
  <ul className="footer-links" style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
  <li><Link href="/hakkimizda" color="inherit">Hakkımızda</Link></li>
  <li><Link href="/tesisimiz" color="inherit">Tesisimiz</Link></li>
  <li><Link href="/belgelerimiz" color="inherit">Belgelerimiz</Link></li>
  <li><Link href="/urunlerimiz" color="inherit">Ürünler</Link></li>
  <li><Link href="/iletisim" color="inherit">İletişim</Link></li>
  <li><Link href="/katalog" color="inherit">Katalog</Link></li>
  <li><Link href="/kayitol" color="inherit">Kayıt OL</Link></li>
  <li><Link href="/mesafeli-satis-sozlesmesi" color="inherit">Mesafeli Satış Sözleşmesi</Link></li>
  <li><Link href="/uyelik-sozlesmesi" color="inherit">Üyelik Sözleşmesi</Link></li>
  <li><Link href="/kargo-sureci" color="inherit">Kargo Süreci</Link></li>
  <li><Link href="/kvkk" color="inherit">KVKK</Link></li>
</ul>
  
  {/* Fotoğraf ekleniyor */}
  <div className="footer-image" style={{ marginTop: '20px' }}>
    <img src="/payment.png" alt="Footer" style={{ width: '100%', borderRadius: '8px' }} />
  </div>
</Grid>

          

         
        </Grid>

        {/* Copyright kısmı */}
        <div className="footer-bottom">
          <Typography variant="body2" color="light" align="center">
            © {new Date().getFullYear()} Özbek Sucukları. Tüm Hakları Saklıdır.
          </Typography>
          <Typography variant="body2" sx={{color:"grey"}} align="center">Made by&nbsp; 
  <Link href="https://www.linkedin.com/in/ertu%C4%9Frul-candan-a16022229/"color="inherit" sx={{textDecoration:"none"}} >Luminex Design</Link> &amp; <Link href="https://www.linkedin.com/in/abdullah-yilmazkol/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=tr" sx={{textDecoration:"none"}} color="inherit">Abdullah Yılmazkol</Link>
</Typography>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
