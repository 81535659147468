import React from "react";
import { Box,Typography } from "@mui/material";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
import ContactDetails from "../components/ContactDetails";
import ContactForm from "../components/ContactForm";
import { motion } from "framer-motion";
const Contact = () => {
  return (
    <Box>
            <Navbar />
            <Box
      sx={{width:{xs:"90%",md:"95%"},mx:"auto"}}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Typography variant="h2" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif", }}>
              İLETİŞİM BİLGİLERİMİZ
            </Typography>
          </motion.div>
        </Box>

      <main>
        <Box sx={{ p: {xs:3,md:3},my:4 }}>
          <ContactDetails />
          <ContactForm />
        </Box>
        <Footer />
      </main>
    </Box>
  );
};

export default Contact;
