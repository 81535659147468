import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { ArrowRightAlt as InfoIcon } from '@mui/icons-material';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import MosqueIcon from '@mui/icons-material/Mosque';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShieldIcon from '@mui/icons-material/Shield';
import '../styles/About.css';

const features = [
  {
    icon: <LocalDiningIcon />, // EŞSİZ LEZZET ikonu
    title: "EŞSİZ LEZZET",
  },
  {
    icon: <MosqueIcon />, // HELAL SERTİFİKASI ikonu
    title: "HELAL SERTİFİKASI",
  },
  {
    icon: <LocalShippingIcon />, // ÜCRETSİZ KARGO ikonu
    title: "ÜCRETSİZ KARGO",
    description: "(2000 TL ve üzeri alışverişlerinizde)",
  },
  {
    icon: <ShieldIcon />, // GÜVENLİ ALIŞVERİŞ ikonu
    title: "GÜVENLİ ALIŞVERİŞ",
  },
];

const About = () => {
  return (
    <>
      <section className="about-section">
        <div className="about-card">
          <div className="about-text">
            <p>
            En son teknolojilerle donatılan ÖZBEK SUCUKLARI, 2010 yılı itibari ile yeni tesisinde üretime başladı. 10000 m2 toplam 2500 m2 kapalı alana sahip bu dev tesis, sucuk, şarküteri, ileri işlenmiş ürünlerin üretimini gerçekleştirmektedir.
            </p>
          </div>

          <div className="about-button-container">
            <Tooltip arrow>
              <Button variant="contained" className="about-button" onClick={() => (window.location.href = `/hakkimizda`)}>
                <span className="button-text">Detaylar</span>
                <InfoIcon className="info-icon" />
              </Button>
            </Tooltip>
          </div>
        </div>
      </section>

      <div className="ayricaliklarimiz-container">
        <div className="features-grid">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              <div className="circle-wrapper">
                <div className="outer-circle">
                  <div className="inner-circle">
                    {feature.icon} {/* Her özelliğe uygun ikon burada */}
                  </div>
                </div>
              </div>
              <h4>{feature.title}</h4>
              {feature.description && <p>{feature.description}</p>}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default About;
