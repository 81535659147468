import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Grid,
} from "@mui/material";
import { Delete, Add, Remove } from "@mui/icons-material";
import { motion } from "framer-motion";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart, decreaseQuantity } from "../redux/cartSlice";
import { useNavigate } from "react-router-dom";
const ShoppingCart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.cart.items);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const handleQuantityChange = (id, delta) => {
    if (delta > 0) {
      dispatch(addToCart({ id })); // Miktar arttırma
    } else {
      dispatch(decreaseQuantity(id)); // Miktar azaltma
    }
  };

  const handleDeleteProduct = (id) => {
    dispatch(removeFromCart(id)); // Ürünü sepetten silme
  };

  const shippingFee = totalAmount < 2000 ? 150 : 0;
  const totalPrice = totalAmount + shippingFee;
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          maxWidth: "1200px",
          margin: "auto",
          p: { xs: 1, sm: 3 },
          my: 4,
          color: "white",
        }}
      >
        {/* Başlık */}
        <Typography
          variant="h3"
          sx={{ fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif", }}
          gutterBottom
          component={motion.div}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Sepetim
        </Typography>

        {products.length === 0 ? (
          <Typography variant="h5" sx={{ textAlign: "center", my: 4 }}>
            Sepetiniz boş, ürün ekleyin
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {/* Ürün Listesi */}
            <Grid item xs={12} md={8}>
              {products.map((product) => (
                <Box
                  key={product.id}
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }} // Mobilde dikey, küçük ekranlarda yatay
                  alignItems="center"
                  justifyContent="space-between"
                  position="relative"
                  p={{ xs: "10px 10px 10px 5px", sm: 3 }}
                  sx={{
                    border: "2px solid #9c7338",
                    borderRadius:"8px",
                    boxShadow: "0",
                    mb: 2,
                  }}
                  component={motion.div}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Box display="flex" alignItems="center">
                    <img
                      src={product.image}
                      alt={product.name}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                        borderRadius: "8px",
                      }}
                    />
                    <Box ml={2}>
                      <Typography variant="h6" sx={{ width: "200px",color:"#000",fontFamily: "'Poppins', sans-serif", }}>
                        {product.name}
                      </Typography>
                      <Typography sx={{ color: "#ba9a69",fontFamily: "'Poppins', sans-serif", }}>
                        ({product.quantity} Adet)
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: { xs: "100%", sm: "50%" },
                      justifyContent: "space-between", // Öğeleri yan yana yerleştir
                      py: 1,
                      flexWrap: "wrap", // Ekran küçükse öğelerin alt satıra geçmesini sağlar
                    }}
                  >
                    {/* Miktar arttırma ve azaltma butonları */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        ml: 1.5,
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <IconButton
                        sx={{ color: "#ba9a69" }}
                        onClick={() => handleQuantityChange(product.id, -1)}
                      >
                        <Remove />
                      </IconButton>
                      <Typography sx={{ color: "#ba9a69" }} mx={1}>
                        {product.quantity}
                      </Typography>
                      <IconButton
                        sx={{ color: "#ba9a69" }}
                        onClick={() => handleQuantityChange(product.id, 1)}
                      >
                        <Add />
                      </IconButton>
                    </Box>

                    {/* Fiyat */}
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: "bold",
                        color: "#ba9a69",
                        width: "90px",
                        textAlign: "right", // Fiyatı sağa hizalar
                        fontSize: { xs: "20px", sm: "21px", md: "20px" }, // Mobilde fontu küçültme
                      }}
                    >
                      {product.price * product.quantity} TL
                    </Typography>
                  </Box>

                  <IconButton
                    color="error"
                    size="small"
                    onClick={() => handleDeleteProduct(product.id)}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              ))}
            </Grid>

            {/* Sipariş Özeti */}
            <Grid item xs={12} md={4}>
              <Box
                p={3}
                sx={{
                  border: "2px solid #9c7338",
                }}
                borderRadius="8px"
                boxShadow="0"
                component={motion.div}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Typography variant="h5" gutterBottom sx={{textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",}}>
                  Sipariş Özeti
                </Typography>
                <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    overflow: "hidden",
    mb:2
  }}
>
  <Divider
    sx={{
      borderWidth: "2px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
</Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography sx={{color:"#000",fontFamily: "'Poppins', sans-serif"}}>Ürünler</Typography>
                  <Typography sx={{color:"#000",fontFamily: "'Poppins', sans-serif"}}>{totalAmount} TL</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
  <Typography sx={{color:"#000",fontFamily: "'Poppins', sans-serif"}}>Kargo</Typography>
  {shippingFee > 0 ? (
    <Typography sx={{color:"#000",fontFamily: "'Poppins', sans-serif"}}>150 TL</Typography>
  ) : (
    <Typography sx={{ textDecoration: "line-through", color: "gray",fontFamily: "'Poppins', sans-serif" }}>
      150 TL
    </Typography>
  )}
</Box>
<Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    overflow: "hidden",
    mb:2
  }}
>
  <Divider
    sx={{
      borderWidth: "2px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
</Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography variant="h6" sx={{ color: "#9c7338",fontFamily: "'Poppins', sans-serif" }}>
                    Toplam (KDV dahil)
                  </Typography>
                  <Typography variant="h5" sx={{ color: "#9c7338", fontWeight: "bold",fontFamily: "'Poppins', sans-serif" }}>
                    {totalPrice } TL
                  </Typography>
                </Box>
                <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    overflow: "hidden",
    mb:2
  }}
>
  <Divider
    sx={{
      borderWidth: "2px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
</Box>
                {totalAmount < 2000 && (
  <Typography
    variant="body2"
    sx={{ color: "#000", textAlign:"center", fontFamily: "'Poppins', sans-serif", my: 1 }}
  >
    {`${(2000 - totalAmount)} TL daha ekleyin, kargo ücretsiz!`}
  </Typography>
)}
                <Button
      variant="contained"
      onClick={() => navigate("/sepetim/odeme")}
      component={motion.button}
      whileTap={{ scale: 0.95 }}
      sx={{
        width: "100%",
        background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
        backgroundSize: "200% 100%", // Gradient genişliği artırıldı
        backgroundPosition: "right center", // Başlangıç pozisyonu
        color: "#fff",
        fontWeight: "bold",

        borderRadius: "8px", // Köşeleri yuvarlama
        padding: "10px 20px",
        textDecoration: "none",
        border: "none",
        cursor: "pointer",
        transition: "background-position 0.5s ease, transform 0.3s ease",
        "&:hover": {
          backgroundPosition: "left center", // Hover durumunda sola geçiş
          transform: "scale(1.01)", // Hafif büyüme efekti
          color: "#F0F8FF", // Daha parlak beyaz
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
        },
      }}
    >
      Ödemeye Devam Et
    </Button>
    <Button
      variant="contained"
      onClick={() => navigate("/urunlerimiz")}
      component={motion.button}
      whileTap={{ scale: 0.95 }}
      sx={{
        width: "100%",
        background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
        backgroundSize: "200% 100%", // Gradient genişliği artırıldı
        backgroundPosition: "right center", // Başlangıç pozisyonu
        color: "#fff",
        fontWeight: "bold",
        mt:2,
        borderRadius: "8px", // Köşeleri yuvarlama
        padding: "10px 20px",
        textDecoration: "none",
        border: "none",
        cursor: "pointer",
        transition: "background-position 0.5s ease, transform 0.3s ease",
        "&:hover": {
          backgroundPosition: "left center", // Hover durumunda sola geçiş
          transform: "scale(1.01)", // Hafif büyüme efekti
          color: "#F0F8FF", // Daha parlak beyaz
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
        },
      }}
    >
      Alışverişe Geri Dön
    </Button>

              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Footer />
    </Box>
  );
};

export default ShoppingCart;
