import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Snackbar,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  IconButton
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
const ProductDetailEdit = () => {
  const [productData, setProductData] = useState({
    product_id: "",
    enerji_kcal: "",
    protein: "",
    yag: "",
    doymus_yag: "",
    karbonhidrat: "",
    seker: "",
    tuz: "",
    koli_agirligi: "",
    koli_ici_adet: "",
    raf_omru: "",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [products, setProducts] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state for products
  const navigate = useNavigate();
  // Fetch the list of products when the component mounts
  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/product`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProducts();
  }, []);

  // Fetch selected product details when the product is selected
  useEffect(() => {
    const fetchProductDetails = async () => {
      if (selectedProductId) {
        setIsLoading(true);
        try {
            const token = localStorage.getItem("token");
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/product/detail/${selectedProductId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setProductData(response.data);
        } catch (error) {
          console.error("Error fetching product details:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchProductDetails();
  }, [selectedProductId]);

  // Form Alanı Değişiklikleri
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Formu Gönderme
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      await axios.patch(`${process.env.REACT_APP_API_URL}/product/detail/${selectedProductId}`, productData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSnackbar({
        open: true,
        message: "Ürün başarıyla güncellendi!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error updating product:", error);
      setSnackbar({
        open: true,
        message: "Ürün güncellenirken bir hata oluştu!",
        severity: "error",
      });
    }
  };
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{mt:5}} >
      <Grid item xs={12} sm={10} md={10}>
        <Paper elevation={3} style={{ padding: "20px", borderRadius: "10px" }}>
        <IconButton onClick={handleBackClick} style={{ marginRight: '10px' }}>
        <ArrowBackIcon />
      </IconButton>
          <Typography variant="h5" gutterBottom align="center" style={{ fontWeight: "bold", marginBottom: "20px" }}>
            Ürün Detay Düzenle
          </Typography>

          <FormControl fullWidth style={{ marginBottom: "20px" }}>
            <InputLabel>Ürün Seç</InputLabel>
            <Select
              value={selectedProductId}
              onChange={(e) => setSelectedProductId(e.target.value)}
              label="Ürün Seç"
            >
              {isLoading ? (
                <MenuItem disabled>
                  Yükleniyor...
                  <CircularProgress size={24} style={{ marginLeft: "10px" }} />
                </MenuItem>
              ) : (
                products.length > 0 ? (
                  products.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
          <img 
            src={`${process.env.REACT_APP_BASE_URL}${product.photo_url}`}
            alt={product.name}
            style={{ width: '40px', height: '40px', marginRight: '10px', borderRadius: '4px' }}
          />
          {product.name}
        </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>Ürün bulunamadı</MenuItem>
                )
              )}
            </Select>
          </FormControl>

          {selectedProductId && (
            <form onSubmit={handleSubmit}>
              {[
                { name: "enerji_kcal", label: "Enerji (kcal)" },
                { name: "protein", label: "Protein (g)" },
                { name: "yag", label: "Yağ (g)" },
                { name: "doymus_yag", label: "Doymuş Yağ (g)" },
                { name: "karbonhidrat", label: "Karbonhidrat (g)" },
                { name: "seker", label: "Şeker (g)" },
                { name: "tuz", label: "Tuz (g)" },
                { name: "koli_agirligi", label: "Koli Ağırlığı (kg)" },
                { name: "koli_ici_adet", label: "Koli İçi Adet" },
                { name: "raf_omru", label: "Raf Ömrü (gün)" },
              ].map((field) => (
                <TextField
                  key={field.name}
                  fullWidth
                  label={field.label}
                  name={field.name}
                  value={productData[field.name]}
                  onChange={handleChange}
                  style={{ marginBottom: "15px" }}
                />
              ))}
              <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginBottom: "10px" }}>
                Güncelle
              </Button>
            </form>
          )}
        </Paper>
      </Grid>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Grid>
  );
};

export default ProductDetailEdit;
