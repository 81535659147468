import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const AddressModal = ({
  open,
  onClose,
  cities,
  city,
  setCity,
  district,
  setDistrict,
  newAddress,
  setNewAddress,
  onAddAddress,
}) => (
  <Dialog open={open} onClose={onClose} fullWidth>
    <DialogTitle
      sx={{
        display: "flex",
        justifyContent: "space-between",
        background:
          "linear-gradient(to right, #11284A 0%, #1C427A 50%, #0E213D 100%)",
        color: "white",
      }}
    >
      Yeni Adres Ekle
      <IconButton onClick={onClose} sx={{ color: "white" }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent
      sx={{
        background: "linear-gradient(to top, #11284A 0%, #1C427A 50%, #0E213D 100%)",
        color: "white",
      }}
    >
      <Box sx={{ mt: 2 }}>
        <Select
          fullWidth
          value={city}
          onChange={(e) => {
            setCity(e.target.value);
            setDistrict("");
          }}
          displayEmpty
          sx={{
            color: "white",
            ".MuiSelect-icon": { color: "white" },
            ".MuiOutlinedInput-notchedOutline": { borderColor: "white" },
            "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
          }}
        >
          <MenuItem value="" disabled sx={{ color: "black" }}>
            Şehir Seçin
          </MenuItem>
          {Object.keys(cities).map((cityName) => (
            <MenuItem key={cityName} value={cityName} sx={{ color: "black" }}>
              {cityName}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {city && (
        <Box sx={{ mt: 2 }}>
          <Select
            fullWidth
            value={district}
            onChange={(e) => setDistrict(e.target.value)}
            displayEmpty
            sx={{
              color: "white",
              ".MuiSelect-icon": { color: "white" },
              ".MuiOutlinedInput-notchedOutline": { borderColor: "white" },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
            }}
          >
            <MenuItem value="" disabled sx={{ color: "black" }}>
              İlçe Seçin
            </MenuItem>
            {cities[city].map((districtName) => (
              <MenuItem
                key={districtName}
                value={districtName}
                sx={{ color: "black" }}
              >
                {districtName}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      <TextField
        label="Adres"
        fullWidth
        multiline
        rows={4}
        value={newAddress}
        onChange={(e) => setNewAddress(e.target.value)}
        sx={{
          mt: 2,
          color: "white",
          ".MuiInputBase-root": { color: "white" },
          ".MuiOutlinedInput-notchedOutline": { borderColor: "white" },
          "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
        }}
        InputLabelProps={{
          sx: {
            color: "white",
            "&.Mui-focused": { color: "white" },
          },
        }}
      />
    </DialogContent>
    <DialogActions
      sx={{
        background: "linear-gradient(to right, #11284A 0%, #1C427A 50%, #0E213D 100%)",
      }}
    >
      <Button
        onClick={onClose}
        sx={{
          color: "white",
          ":hover": { backgroundColor: "silver" },
        }}
      >
        İptal
      </Button>
      <Button
        onClick={onAddAddress}
        variant="contained"
        disabled={!city || !district}
        sx={{
          backgroundColor: "silver",
          color: "white",
          ":hover": { backgroundColor: "#000" },
        }}
      >
        Ekle
      </Button>
    </DialogActions>
  </Dialog>
);

export default AddressModal;
