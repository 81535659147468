import React from "react";
import { Container, Typography, List, ListItem } from "@mui/material";
import Navbar from "../components/navbar";
import Footer from '../components/Footer';
const KargoSureci = () => {
  return (
    <>
    <Navbar />
    <Container>
    <Typography variant="h4" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",mb:2 }}>
              KARGO SÜRECİ
            </Typography>
      <Typography variant="body1" paragraph>
        Sitemizden verdiğiniz siparişler, sipariş alındıktan sonra en hızlı şekilde hazırlanıp kargoya teslim edilir. 
        2000 TL ve üzeri siparişlerde ücretsiz kargo hizmeti sunulmaktadır. Kargo süreci şu şekilde işler:
      </Typography>
      <List>
        <ListItem>
          <Typography variant="body1">
            <strong>Sipariş Onayı:</strong> Siparişiniz başarıyla alındığında, 
            siparişinizin durumu "Hazırlanıyor" olarak güncellenir.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
            <strong>Sipariş Hazırlığı:</strong> Siparişiniz, ürünlerinizin temin edilip hazırlanmasıyla ilgili 
            gerekli işlemler başlatılır. Bu süreç, siparişinize bağlı olarak değişebilir.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
            <strong>Kargo Teslimatı:</strong> Siparişiniz, hazırlık süreci tamamlandıktan sonra 
            kargoya teslim edilir. Kargo teslim süresi, Afyonkarahisar'a olan yakınlık durumuna göre 
            1 ile 4 iş günü arasında değişmektedir.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
            <strong>Çalışma Saatleri:</strong> Siparişler hafta içi iş günlerinde işlenir. 
            Haftasonu ve bayram tatillerinde siparişler işleme alınmaz.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant="body1">
            <strong>Ücretsiz Kargo:</strong> 2000 TL ve üzeri siparişlerinizde, ücretsiz kargo hizmetinden 
            faydalanabilirsiniz.
          </Typography>
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        Kargo süreciyle ilgili herhangi bir sorun ya da sorunuz olursa, bizimle iletişime geçebilirsiniz. 
        Siparişlerinizi güvenli ve hızlı bir şekilde teslim etmek için sürekli olarak çalışıyoruz.
      </Typography>
    </Container>
    <Footer/>
    </>
  );
};

export default KargoSureci;
