import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { motion } from "framer-motion";
import Navbar from "../components/navbar";
import Footer from '../components/Footer';
import ShoppingCart from "../components/ShoppingCart";
import Slider from '../components/Slider';
const slideData = [
  { src: "/slider.webp"},
  { src: "/slider.webp"},
  { src: "/slider.webp"},
  { src: "/slider.webp"},
];
const Facility = () => {
  return (
    <div
      style={{

      }}
    >
      <Navbar />
      <main>
      <Box
      sx={{width:{xs:"90%",md:"75%"},mx:"auto"}}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Typography variant="h2" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif", }}>
              TESİSİMİZ
            </Typography>
          </motion.div>
        </Box>
        <Container
          sx={{
            mt: 2,
            paddingBottom: "3rem",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#000",
                fontSize: {xs: "0.9rem",sm: "1rem",md: "1.2rem"},
                fontFamily: "'Poppins', sans-serif",
              }}
            >
               90'lı yıllarda sektöre giren ve yatırımlarını arttırarak sürdüren Lokman Özbek Sucukları, kurulduğu günden bu yana kalite ve hijyen kurallarını ön planda tutarak, ilk hedef olarak tüketicinin güvenini kazanmayı hedeflemiştir. Firma her geçen gün büyümesine devam etmekte ve üretiminde göstermiş olduğu titizlik ve çaba sayesinde et mamüllerinde, Afyon kapsamında rakipleri arasında öncelikli yer edinmiştir. Gıda sektörünün etürünleri alanında geleneksel lezzetlerimizi uluslararası hizmet standartlarında müşteri memnuniyetini esas tutarak, kalite yönetimini ve gıda güvenliği ve ISO 22000 kalite güvence sistemi ile belgeledik. Firmamız başta Afyonkarahisar ve çevresinde bşlayan talepler, daha sonrasında Ege, İç Anadolu ve Akdeniz illerine kadar yayıldı. Perakende üretim ile başlayan teşebbüs günden güne büyüyerek tonlarla ifade edilen bir kapasiteye ulaşmıştır.
            </Typography>
          </motion.div>
          <ShoppingCart />
          </Container>
          <Box
      sx={{width:{xs:"90%",md:"75%"},mx:"auto"}}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Typography variant="h2" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",fontSize: {xs: "30px",sm: "40px",md: "60px"}, }}>
            TESİS FOTOĞRAFLARIMIZ
            </Typography>
          </motion.div>
        </Box>
        <Box sx={{ width: "90%",mb:5,mx:"auto" }}>
        <Slider slideData={slideData} />
        </Box>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Footer />
        </motion.div>
      </main>
    </div>
  );
};

export default Facility;
