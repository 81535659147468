import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import { Pagination, EffectCoverflow } from "swiper/modules";
import "../styles/MainProduct.css"; // Stil dosyasını import edelim

const MainProduct = () => {
  const [products, setProducts] = useState([]); // Ürünler için state tanımla
  const [loading, setLoading] = useState(true); // Yüklenme durumunu takip et
  const [error, setError] = useState(null); // Hata durumunu takip et

  // Backend'den ürünleri çek
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/product`); // Backend API endpoint
        if (!response.ok) {
          throw new Error("Ürünler yüklenirken bir hata oluştu");
        }
        const data = await response.json();
        setProducts(data); // Ürünleri state'e aktar
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div>Hata: {error}</div>;
  }

  return (
    <div className="products-slider-container">
      {/* Ürün başlığı */}
      <div className="products-title">Ürünlerimiz</div>

      {/* Swiper Slider */}
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
        breakpoints={{
          0: { slidesPerView: 2 },
          640: { slidesPerView: 3 },
          1024: { slidesPerView: 4 },
          1200: { slidesPerView: 5 },
        }}
      >
        {/* Ürünleri map ile oluştur */}
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <div className="product-card">
              <img
                src={`${process.env.REACT_APP_BASE_URL}${product.photo_url}`}
                alt={product.title}
                className="product-image"
                style={{ objectFit: "contain" }}
              />
              <div className="product-info">
                <h3 className="product-title">{product.name}</h3>
                <button
                  className="product-button"
                  onClick={() => (window.location.href = `/urunlerimiz/${product.id}`)} // Yönlendirme
                >
                  Ürüne Git
                </button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MainProduct;
