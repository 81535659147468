import React from "react";
import { Container, Typography } from "@mui/material";
import Navbar from "../components/navbar";
import Footer from '../components/Footer';
const Kvkk = () => {
  return (
    <>
    <Navbar />
    <Container>
    <Typography variant="h4" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",mb:2 }}>
              KVKK
            </Typography>
      <Typography variant="h5" paragraph>
      RIZA METNİ
      </Typography>
      <Typography variant="h5" paragraph>
      KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN RIZA METNİ
      </Typography>
      <Typography variant="body1" paragraph>
      Şirketimiz LOKMAN ÖZBEK ÖZBEK SUCUKLARI  tarafından  www.ozbeksucuklari.com.tr  internet 
sitesi üzerinden yürütülen faaliyetlerle ilgili olarak sizleri bilgilendirmek isteriz. Bir elektronik ticaret 
platformu olan web sitemizde alışveriş yapabilmek için üyelik şartı bulunmamaktadır. Dolayısıyla 
platformumuzda üyelik bir hizmet koşulu olarak sunulmamaktadır. Üye olmadan alışveriş yapma 
seçeneğinden farklı olarak üyelik seçeneği; alışkanlıkları, alışveriş geçmişi, incelemeleri takip edilip 
değerlendirilerek kendisine özgü kişiselleştirilmiş bir hizmet almak isteyen kullanıcılar için 
tasarlanmıştır. Kişiye özgü bu avantajların sunulması için ise bazı kişisel verilerinizin aşağıda 
detaylandırıldığı şekilde belirtilen amaçlarla işlenmesi, anlaşmalı profesyonel iş ortaklarımızla 
paylaşılması ve teknoloji alanındaki tedarikçilerimiz tarafından sağlanan programlarımıza ve/veya 
sistemlerimize kaydedilmesi gerekebilmektedir. Siz müşterilerimizin memnuniyetinin sağlanması için 
işlenmesi gereken kişisel verilerinizden bazıları; Kişisel Verilerin Korunması Kanunu’nda yer alan ve 
rızaya tabi olmayan veri işleme şartlarının kapsamında olmaması nedeniyle, ancak siz müşterilerimizin 
konu özelinde beyan edeceği açık rızaya bağlıdır. Açık rızanıza bağlı olarak;
      </Typography>
      <Typography variant="h5" paragraph>
      İŞLENEN KİŞİSEL VERİLER VE TOPLAMA YÖNTEMLERİ:
      </Typography>
      <Typography variant="body1" paragraph>
      Siz Müşterilerimizin çevrimiçi ve/veya basılı formlar vasıtasıyla paylaştığı kimlik bilgileriniz (isim, 
soyisim), iletişim bilgileriniz (e-posta adresi, fatura ve teslimat adresleri, cep telefonu 
numarası); internet sitemizi kullanımız sırasında toplanan müşteri işlem bilgileriniz (talep bilgileri, 
sipariş bilgileri, fatura bilgileri, müşteri yorumları), pazarlama bilgileriniz (çerez kayıtları, alışveriş 
geçmişi, açık veya örtülü kullanıcı reytingleri), işlem güvenliği bilgileri (IP Adresi Bilgileri, İnternet 
Sitesi Giriş Çıkış Bilgileri, Kullanıcı Adı Bilgileri, Şifre Bilgileri, Bağlantı Zamanı / Süresi gibi Trafik 
Verileri) internet sitemizi kullanırken internet tarayıcınızdaki tercihlerinize göre toplanabilecek lokasyon 
verileriniz işlenecektir
      </Typography>
      <Typography variant="h5" paragraph>
      İŞLENEN KİŞİSEL VERİLERİN İŞLEME AMAÇLARI VE HUKUKİ SEBEBİ:
      </Typography>
      <Typography variant="body1" paragraph>
      Yukarıda sayılan kişisel verileriniz, rıza göstererek üyemiz olmanız halinde; size özel reklam, promosyon 
ve kampanyaların oluşturulması, hedef kitle belirlenmesi, müşteri hareketleriniz takip edilerek kullanıcı 
deneyiminizi arttırıcı faaliyetlerin yürütülmesi ve internet sitemizin geliştirilmesi ve müşteri ihtiyaçlarına 
göre kişiselleştirilmesi, doğrudan ve doğrudan olmayan pazarlama, kişiye özel pazarlama ve yeniden 
pazarlama faaliyetlerinin yürütülmesi, kişiye özel segmentasyon, hedefleme, analiz ve şirket içi 
raporlama faaliyetlerinin yürütülmesi, pazar araştırmaları, müşteri memnuniyeti aktivitelerinin 
planlanması ve icrası ile müşteri ilişkileri yönetimi süreçlerinin planlanması ve icrası amaçlarıyla dahil 
olmak üzere şirketimizin sunduğu ürün ve/veya hizmetlere bağlılık oluşturulması ve/veya arttırılması 
süreçlerinin planlanması ve icrası, tüm bu açıklanan kapsamlarda sizinle iletişim faaliyetlerinin 
yürütülmesi kapsamında; KVKK’nın 5. maddesinin 1. fıkrası “açık rıza” hukuki sebebine bağlı olarak 
işlenebilecek ve aynı amaçlar ve hukuki sebep kapsamında yurtiçi ve yurtdışındaki üçüncü kişilerle 
paylaşılabilecektir.
      </Typography>
      <Typography variant="h5" paragraph>
      İŞLENEN KİŞİSEL VERİLERE İLİŞKİN HAKLARINIZ:
      </Typography>
      <Typography variant="body1" paragraph>
      KVKK’nın 11. maddesi kapsamında;
 Kişisel verilerinizin işlenip işlenmediğini öğrenme,
 Kişisel verileriz işlenmişse buna ilişkin bilgi talep etme,
 Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
 Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
 Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,
 KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini 
isteme,
 Eksik veya yanlış verilerinizin düzeltilmesi ile kişisel verilerinizin silinmesi veya yok edilmesini talep 
etmesi halinde, bu durumun kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
 İşlenen verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhe bir 
sonucun ortaya çıkması durumunda sonuca itiraz etme ve
 Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde bu zararın 
giderilmesini talep etme,
Haklarına sahipsiniz.
 Yukarıda yer alan tüm kişisel verilerinizin belirtilen işleme amaçları ile sınırlı olmak üzere işlenmesine 
hiçbir baskı altında kalmadan ve açık biçimde muvafakat ettiğinizi, üye ol sayfasındaki “üye ol” 
butonuna tıklayarak kabul etmiş olacaksınız.
      </Typography>
      <Typography variant="h5" paragraph>
      AYDINLATMA METNİ
      </Typography>
      <Typography variant="h5" paragraph>
      KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN AYDINLATMA METNİ
      </Typography>
      <Typography variant="body1" paragraph>
      Afyon LOKMAN ÖZBEK ÖZBEK SUCUKLARI tarafından aktarılan kişisel verilerin korunması konusundaki 
temel bilgilere aşağıda yer verilmiştir. Şirketimiz, 6698 sayılı Kişisel Verilerin Korunması Kanunu 
(“KVKK”) m. 10’dan doğan aydınlatma yükümlülüğünü yerine getirmek amacıyla aşağıdaki 
açıklamaları müşterilerimizin ve web-sitemizi kullanan 3. kişilerin dikkatine sunar. Şirketimiz işbu 
Kişisel Verilerin Korunması Hakkında Açıklama metnini yürürlükteki mevzuatta yapılabilecek 
değişiklikler çerçevesinde her zaman güncelleme hakkını saklı tutar.
 1) Şirketimizin kişisel verileri toplamasının yasal dayanağı nedir?
 Müşterilerimizin kişisel verilerinin kullanılması konusunda çeşitli kanunlarda düzenlemeler 
bulunmaktadır. En başta KVKK ile kişisel verilerin korunması esasları belirlenmiştir. Ayrıca 6563 Sayılı 
Elektronik Ticaretin Düzenlenmesi Hakkında Kanun da kişisel verilerin korunmasına ilişkin hüküm 
içermektedir. 5237 Sayılı Türk Ceza Kanunu hükümleri yoluyla da kişisel verilerin korunması için bazı 
hallerde cezai yaptırımlar öngörülmüştür.
 Diğer yandan, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler 
Yönetmeliği’nden doğan yükümlülüklerimizin ifası amacıyla verilerin toplanması ve kullanılması 
gerekmektedir.
 2) Şirketimiz kişisel verilerin toplanmasında hangi yöntemleri kullanıyor?
 www.ozbeksucuklari.com.tr web sitesinden işlem yapan müşterilerimizin verdikleri veriler, 
müşterilerimizin rızaları ve mevzuat hükümleri uyarınca şirketimiz tarafından işlenmektedir.
 Şirketimize ait olan www.ozbeksucuklari.com.tr web sitesi çerez (cookie) kullanan bir sitedir. Çerez; 
kullanılmakta olan cihazın internet tarayıcısına ya da sabit diskine depolanarak söz konusu cihazın tespit 
edilmesine 
olanak 
tanıyan, 
çoğunlukla 
harf 
ve 
sayılardan 
oluşan 
bir 
dosyadır. 
www.ozbeksucuklari.com.tr, ziyaretçilerine daha iyi hizmet verebilmek amacıyla ve yasal yükümlülüğü 
çerçevesinde, işbu Kişisel Verilerin Korunması Hakkında Açıklama metninde belirlenen amaçlar ve 
kapsam dışında kullanılmamak kaydı ile gezinme bilgilerinizi toplayacak, işleyecek, üçüncü kişilerle 
paylaşacak ve güvenli olarak saklayacaktır.
 www.ozbeksucuklari.com.tr çerezleri; günlük dosyaları, boş gif dosyaları ve/veya üçüncü taraf 
kaynakları yoluyla topladığı bilgileri tercihlerinizle ilgili bir özet oluşturmak amacıyla depolar. 
www.ozbeksucuklari.com.tr size özel tanıtım yapmak, promosyonlar ve pazarlama teklifleri sunmak, 
web sitesinin içeriğini size göre iyileştirmek ve/veya tercihlerinizi belirlemek amacıyla; site üzerinde 
gezinme 
bilgilerinizi 
ve/veya 
site 
üzerindeki 
kullanım 
geçmişinizi 
izleyebilmektedir. 
www.ozbeksucuklari.com.tr çevrimiçi ve çevrimdışı olarak toplanan bilgiler gibi farklı yöntemlerle veya 
farklı zamanlarda site üzerinde sizden toplanan bilgileri eşleştirebilir ve bu bilgileri üçüncü taraflar gibi 
başka kaynaklardan alınan bilgilerle birlikte kullanabilir.
 İnternet tarayıcınızın "yardım" dosyasında verilen talimatları izleyerek veya “www.allaboutcookies.org” 
veya “www.youronlinechoices.eu” adresini ziyaret ederek kalıcı çerezleri kaldırabilir ve hem oturum 
çerezlerini hem de kalıcı çerezleri reddedebilirsiniz.Kalıcı çerezleri veya oturum çerezlerini reddederseniz, web sitesini kullanmaya devam edebilirsiniz, 
fakat web sitesinin tüm işlevlerine erişemeyebilirsiniz veya erişiminiz sınırlı olabilir.
      </Typography>
      <Typography variant="body1" paragraph>
      3) İnternet Sitesi Çerezleri Nasıl Kullanılmaktadır?
 www.ozbeksucuklari.com.tr; yaptığınız tercihleri hatırlamak ve web sitesi kullanımınızı kişiselleştirmek 
için kullanır. Bu kullanım parolanızı kaydeden ve web sitesi oturumunuzun sürekli açık kalmasını 
sağlayan, böylece her ziyaretinizde birden fazla kez parola girme zahmetinden kurtaran çerezleri ve web 
sitesine daha sonraki ziyaretlerinizde sizi hatırlayan ve tanıyan çerezleri içerir.
 www.ozbeksucuklari.com.tr web sitesine nereden bağlandığınız, web sitesi üzerinde hangi içeriği 
görüntülediğiniz ve ziyaretinizin süresi gibi web sitesini nasıl kullandığınızın izlenmesi dahil olmak 
üzere; web sitesini nasıl kullandığınızı belirlemek için kullanır.
 www.ozbeksucuklari.com.tr web sitesindeki çerezler ilgi alanlarınıza ve size daha uygun içerik ve 
reklamları sunmak için reklam/tanıtım amacıyla kullanılır. Bu şekilde, web sitesini kullandığınızda size 
daha uygun içerikleri, kişiye özel kampanya ve ürünleri sunar ve daha önceden istemediğinizi 
belirttiğiniz içerik veya fırsatları bir daha sunmaz.
 4) İnternet Sitesi üçüncü taraf çerezlerini reklam ve yeniden hedefleme için nasıl kullanmaktadır?
 www.ozbeksucuklari.com.tr web sitesi çerezleri ayrıca; arama motorlarını, web sitesi ve/veya web 
sitesinin reklam verdiği internet sitelerini ziyaret ettiğinizde ilginizi çekebileceğini düşündüğü reklamları 
size sunabilmek için “reklam teknolojisini” devreye sokmak amacıyla kullanabilir. Reklam teknolojisi, 
size özel reklamlar sunabilmek için web sitesine ve web sitesinin reklam verdiği web sitelerine yaptığınız 
önceki ziyaretlerle ilgili bilgileri kullanır. Bu reklamları sunarken, web sitesinin sizi tanıyabilmesi 
amacıyla tarayıcınıza benzersiz bir üçüncü taraf çerezi yerleştirilebilir. İnternet tarayıcınızın "yardım" 
dosyasında 
verilen 
talimatları 
izleyerek 
veya 
“www.allaboutcookies.org” 
ya 
da 
“www.youronlinechoices.eu” adresini ziyaret ederek kalıcı çerezleri kaldırabilir ve hem oturum 
çerezlerini hem de kalıcı çerezleri reddedebilirsiniz. Kalıcı çerezleri veya oturum çerezlerini 
reddederseniz, web sitesini kullanmaya devam edebilirsiniz, fakat web sitesinin tüm işlevlerine 
erişemeyebilirsiniz veya erişiminiz sınırlı olabilir.
 5) Şirketimiz kişisel verileri hangi amaçlarla kullanıyor?
 Şirketimiz, mevzuatın izin verdiği durumlarda ve ölçüde kişisel bilgilerinizi kaydedebilecek, 
saklayabilecek, güncelleyebilecek, üçüncü kişilere açıklayabilecek, devredebilecek, sınıflandırabilecek 
ve işleyebilecektir.
 Kişisel verileriniz şu amaçlarla kullanılmaktadır:
 Web sitesi üzerinden işlem yapanın/yaptıranın kimlik bilgilerini teyit etmek,
 İletişim için adres ve diğer gerekli bilgileri kaydetmek,
 Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun’un ilgili maddeleri akdettiğimiz 
sözleşmelerin koşulları, güncel durumu ve güncellemeler ile ilgili müşterilerimiz ile iletişime geçmek, 
gerekli bilgilendirmeleri yapabilmek,
 Elektronik veya kağıt ortamında işleme dayanak olacak tüm kayıt ve belgeleri düzenlemek,
 Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında Kanun’un ilgili maddeleri akdettiğimiz 
sözleşmeler uyarınca üstlenilen yükümlülükleri ifa etmek,
 Kamu güvenliğine ilişkin hususlarda talep halinde ve mevzuat gereği kamu görevlilerine bilgi verebilmek,
 “Müşterilerimizin bize bildirdiği ilgi alanlarını dikkate alarak” müşterilerimizin ilgilenebileceği 
ürünlerimiz hakkında müşterilerimize bilgi verebilmek, kampanyaları aktarmak,
 Web sitesinden alışveriş yapan müşterilerimizi tanıyabilmek ve müşteri çevresi analizinde kullanabilmek, 
çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmek ve bu kapsamda müşteri memnuniyetinin 
sağlanabilmesi için anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anketler 
düzenlemek,
 Anlaşmalı kurumlarımız ve çözüm ortaklarımız tarafından müşterilerimize öneri sunabilmek, 
hizmetlerimizle ilgili müşterilerimizi bilgilendirebilmek,
 Hizmetlerimiz ile ilgili müşteri şikayet ve önerilerini değerlendirebilmek,
 Yasal yükümlülüklerimizi yerine getirebilmek ve yürürlükteki mevzuattan doğan haklarımızı 
kullanabilmek,
      </Typography>
      <Typography variant="body1" paragraph>
      6) Şirketimiz kişisel verilerinizi nasıl koruyor?
 Şirketimiz ile paylaşılan kişisel veriler, Şirketimiz gözetimi ve kontrolü altındadır. Şirketimiz, 
yürürlükteki ilgili mevzuat hükümleri gereğince bilginin gizliliğinin ve bütünlüğünün korunması 
amacıyla gerekli organizasyonu kurmak ve teknik önlemleri almak ve uyarlamak konusunda veri 
sorumlusu sıfatıyla sorumluluğu üstlenmiştir. Bu konudaki yükümlülüğümüzün bilincinde olarak veri 
gizliliğini konu alan uluslararası ve ulusal teknik standartlara uygun surette periyodik aralıklarda sızma 
testleri yaptırılmakta ve bu kapsamda veri işleme politikalarımızı her zaman güncellediğimizi bilginize 
sunarız.
 7) Şirketimiz kişisel verilerinizi paylaşıyor mu?
 Müşterilerimize ait kişisel verilerin üçüncü kişiler ile paylaşımı, müşterilerin izni çerçevesinde 
gerçekleşmekte ve kural olarak müşterimizin onayı olmaksızın kişisel verileri üçüncü kişilere 
aktarılmamaktadır.
 Bununla birlikte, yasal yükümlülüklerimiz nedeniyle ve bunlarla sınırlı olmak üzere mahkemeler ve diğer 
kamu kurumları ile kişisel veriler paylaşılmaktadır. Ayrıca, taahhüt ettiğimiz hizmetleri sağlayabilmek ve 
verilen hizmetlerin kalite kontrolünü yapabilmek için anlaşmalı üçüncü kişilere kişisel veri aktarımı 
yapılmaktadır.
 Üçüncü kişilere veri aktarımı sırasında hak ihlallerini önlemek için gerekli teknik ve hukuki önlemler 
alınmaktadır. Bununla birlikte, kişisel verileri alan üçüncü kişinin veri koruma politikalarından dolayı ve 
üçüncü kişinin sorumluluğundaki risk alanında meydana gelen ihlallerden şirketimiz sorumlu değildir.
 Kişisel verileriniz şirketimizin hissedarlarıyla, doğrudan/dolaylı yurtiçi/yurtdışı iştiraklerimize, 
faaliyetlerimizi yürütebilmek için işbirliği yaptığımız program ortağı kurum, kuruluşlarla, verilerin bulut 
ortamında saklanması hizmeti aldığımız yurtiçi/yurtdışı kişi ve kurumlarla, müşterilerimize ticari 
elektronik iletilerin gönderilmesi konusunda anlaşmalı olduğumuz yurtiçi/yurtdışındaki kuruluşlarla, 
Bankalararası Kart Merkeziyle, anlaşmalı olduğumuz bankalarla ve sizlere daha iyi hizmet sunabilmek 
ve müşteri memnuniyetini sağlayabilmek için çeşitli pazarlama faaliyetleri kapsamında yurtiçi ve 
yurtdışındaki çeşitli ajans, reklam şirketleri ve anket şirketleriyle ve yurtiçi/yurtdışı diğer üçüncü kişilere 
ilgili iş ortaklarımızla paylaşılabilmektedir.
 8) Kişisel Verilerin Korunması Kanunu’ndan doğan haklarınız nelerdir?
 KVKK uyarınca kişisel verilerinizin;
 İşlenip işlenmediğini öğrenme,
 İşlenmişse bilgi talep etme,
 İşlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,
 Yurt içinde / yurt dışında aktarıldığı 3. kişileri bilme,
 Eksik / yanlış işlenmişse düzeltilmesini isteme,
 KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini / yok edilmesini isteme,
 Aktarıldığı 3. kişilere yukarıda sayılan (d) ve (e) bentleri uyarınca yapılan işlemlerin bildirilmesini 
isteme,
 Münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına 
itiraz etme,
 KVKK’ya aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme 
haklarına sahip olduğunuzu hatırlatmak isteriz.
      </Typography>
      <Typography variant="body1" paragraph>
      9) Kişisel verilerle ilgili mevzuat değişikliklerinden nasıl haberdar olabilirim?
 KVKK uyarınca sahip olduğunuz haklar şirketimizin yükümlülükleridir. Kişisel verilerinizi bu bilinçle ve 
mevzuatın gerektirdiği ölçüde işlediğimizi, yasal değişikliklerin olması halinde sayfamızda yer alan bu 
bilgileri yeni mevzuata uygun güncelleyeceğimizi, yapılan güncellemeleri de bu sayfa üzerinden her 
zaman kolaylıkla takip edebileceğinizi size bildirmek isteriz.
 10) Verinin güncel ve doğru tutulduğundan nasıl emin olabilirim?
 KVKK’nın 4. maddesi uyarınca şirketimiz kişisel verilerinizi doğru ve güncel olarak tutma yükümlülüğü 
bulunmaktadır. Bu kapsamda şirketimizin yürürlükteki mevzuattan doğan yükümlülüklerini yerine 
getirebilmesi için müşterilerimizin şirketimizle doğru ve güncel verilerini paylaşması gerekmektedir. 
Verilerinizin herhangi bir surette değişikliğe uğraması halinde aşağıda belirtilen iletişim kanallarından 
bizimle iletişime geçerek verilerinizi güncellemenizi rica ederiz.
 11) Şirketimize kişisel verilerinizle ilgili soru sormak ister misiniz?
 Veri sahibi olarak KVKK kapsamındaki haklarınıza ilişkin taleplerinizi, Başvuru Formu ile bize 
bildirebilirsiniz. Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi, şirketimize iletmeniz 
durumunda şirketimiz talebinizi en geç 30 (otuz) gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, 
Kişisel Verileri Koruma Kurulu tarafından veyahut mevzuat tarafından bir ücret öngörülmesi halinde, 
şirketimiz tarafından belirlenen tarifedeki ücret talep edilebilecektir.
 Bize kişisel verilerinizle ilgili her türlü soru ve görüşleriniz için info@ozbeksucuklari.com.tr  e-posta 
adresinden dilediğiniz zaman ulaşabilirsiniz.
      </Typography>
      <Typography variant="body1" paragraph>
      Başvuru yolları:
      </Typography>
      <Typography variant="body1" paragraph>
      1- Yazılı Olarak: OSB 1.Cadde 4 .Sokak No:5 Merkez/AFYONKARAHİSAR
      </Typography>
      <Typography variant="body1" paragraph>
      2- E-Posta Yoluyla: info@ozbeksucuklari.com.tr
      </Typography>
      <Typography variant="h6" paragraph>
      VERİ SORUMLUSU
      </Typography>
      <Typography variant="body1" paragraph>
      Unvan: LOKMAN ÖZBEK ÖZBEK SUCUKLARI
      </Typography>
      <Typography variant="body1" paragraph>
      Mersis No:1250847599000011
      </Typography>
      <Typography variant="body1" paragraph>
      Adres:OSB 1.Cadde 4 .Sokak No:5 Merkez/AFYONKARAHİSAR
      </Typography>
    </Container>
    <Footer/>
    </>
  );
};

export default Kvkk;
