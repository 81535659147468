import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { updateUserInfo } from "../services/accountService"; // accountService'den fonksiyonu import et

import { useNotification } from "./NotificationContext";
function UserInfo({ userInfo, loading }) {
  const [formData, setFormData] = useState({
    email: userInfo?.email,
    phone: userInfo?.phone,
  });
  const showNotification = useNotification();
  useEffect(() => {
    if (userInfo) {
      setFormData({
        email: userInfo.email, // email verisini güncelliyoruz
        phone: userInfo.phone, // phone verisini güncelliyoruz
      });
    }
  }, [userInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      // Sadece sayıları al
      const numericValue = value.replace(/[^0-9]/g, "");
      let maskedValue = numericValue;

      if (numericValue.length > 4) {
        maskedValue = `${numericValue.substring(0, 4)} ${numericValue.substring(
          4,
          7
        )}`;
      }
      if (numericValue.length > 7) {
        maskedValue = `${maskedValue} ${numericValue.substring(7, 9)}`;
      }
      if (numericValue.length > 9) {
        maskedValue = `${maskedValue} ${numericValue.substring(9, 11)}`;
      }

      setFormData((prevState) => ({
        ...prevState,
        [name]: maskedValue,
      }));

      return;
    }

    // Diğer inputlar için genel değişiklik
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // E-posta doğrulama
    if (formData.email && !formData.email.includes('@')) {
      showNotification("Geçerli Bir E-mail Adresi Giriniz.", "error");

      return;
    }

    // Telefon numarası doğrulama
    if (!formData.phone || formData.phone.length !== 14) {
      showNotification("Telefon Numaranızı Eksiksiz Giriniz.", "error");
      return;
    }

    if (formData.phone && !formData.phone.startsWith("05")) {
      showNotification("Telefon Numaranız 05 İle Başlamalı", "error");
      return;
    }

    try {
      await updateUserInfo(formData.email, formData.phone, userInfo.userId);
      showNotification("Bilgiler Güncellendi!", "succses");
    } catch (error) {
      console.error("Bilgiler güncellenirken hata oluştu:", error);
      showNotification("Bir Hata Oluştu Tekrar Deneyin.", "error");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <motion.div
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Kullanıcı Bilgileri
      </Typography>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          disabled
          fullWidth
          value={`${userInfo?.first_name} ${userInfo?.last_name}`}
          label="Ad Soyad"
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          value={formData.email}
          label="E-posta"
          name="email"
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 2 }}
        />
        <TextField
          fullWidth
          value={formData.phone}
          label="Telefon"
          name="phone"
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 2 }}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{

            background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
            backgroundSize: "200% 100%", // Gradient genişliği artırıldı
            backgroundPosition: "right center", // Başlangıç pozisyonu
            color: "#fff",
            fontWeight: "bold",

            borderRadius: "8px", // Köşeleri yuvarlama
            padding: "10px 20px",
            textDecoration: "none",
            border: "none",
            cursor: "pointer",
            transition: "background-position 0.5s ease, transform 0.3s ease",
            "&:hover": {
              backgroundPosition: "left center", // Hover durumunda sola geçiş
              transform: "scale(1.01)", // Hafif büyüme efekti
              color: "#F0F8FF", // Daha parlak beyaz
              boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
            },
          }}

        >
          Bilgilerimi Güncelle
        </Button>
      </Box>
    </motion.div>
  );
}

export default UserInfo;
