import React, { useState, useLayoutEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from "./auth/AuthContext";
import { motion, AnimatePresence } from "framer-motion";
import WhatsAppButton from "./components/WhatsAppButton";
import CookieBanner from "./components/CookieBanner";
import LogoAnimation from "./components/LogoAnimation"; // Animasyon bileşeni
import Home from "./pages/Home";
import About from "./pages/About";
import Facility from "./pages/Facility";
import Documents from "./pages/Documents";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import Catalog from "./pages/Catalog";
import Register from "./pages/Register";
import AydinlatmaMetni from "./pages/AydinlatmaMetni";
import KargoSureci from "./pages/KargoSureci";
import Mss from "./pages/Mss";
import Kvkk from "./pages/Kvkk";
import UyelikSozlesmesi from "./pages/UyelikSozlesmesi";
import ShoppingCart from "./pages/ShoppingCart";
import ProductDetails from "./pages/ProductDetails";
import AccountPage from "./pages/AccountPage";
import Payment from "./pages/Payment";
import AdminPanel from "./pages/admin/AdminPanel";
import ProductAdd from "./pages/admin/ProductAdd";
import ProductEdit from "./pages/admin/ProductEdit";
import ShowForm from "./pages/admin/ShowForm";
import AdminPDF from "./pages/admin/AdminPdf";
import Orders from "./pages/admin/Orders";
import ConfirmPayment from "./pages/ConfirmPayment";
import ProductDetailEdit from "./pages/admin/ProductDetailEdit";

const App = () => {
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    const handleLoad = () => {
      clearTimeout(timeoutId); // Eğer yükleme tamamlarsa zamanlayıcıyı iptal et
      setLoading(false);
    };

    // 5 saniye sonra hala yüklenmediyse `loading` true kalsın
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 2000);

    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
      clearTimeout(timeoutId); // Komponent unmount olursa zamanlayıcıyı temizle
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <AnimatePresence>
            {loading ? (
              <motion.div
                key="loading"
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
                style={{
                  position: "fixed",
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "#D2D2D5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LogoAnimation />
              </motion.div>
            ) : (
              <Router>
                <main>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/hakkimizda" element={<About />} />
                    <Route path="/tesisimiz" element={<Facility />} />
                    <Route path="/belgelerimiz" element={<Documents />} />
                    <Route path="/urunlerimiz" element={<Products />} />
                    <Route path="/iletisim" element={<Contact />} />
                    <Route path="/katalog" element={<Catalog />} />
                    <Route path="/kayitol" element={<Register />} />
                    <Route path="/sepetim" element={<ShoppingCart />} />
                    <Route path="/urunlerimiz/:productName" element={<ProductDetails />} />
                    <Route path="/hesabim" element={<AccountPage />} />
                    <Route path="/sepetim/odeme" element={<Payment />} />
                    <Route path="/sepetim/odeme/siparis/:orderId" element={<ConfirmPayment />} />
                    <Route path="/aydinlatma-metni" element={<AydinlatmaMetni />} />
                    <Route path="/kargo-sureci" element={<KargoSureci />} />
                    <Route path="/mesafeli-satis-sozlesmesi" element={<Mss />} />
                    <Route path="/kvkk" element={<Kvkk />} />
                    <Route path="/uyelik-sozlesmesi" element={<UyelikSozlesmesi />} />
                    {/* Admin Panel Rotaları */}
                    <Route path="/AdminPanel" element={<AdminPanel />} />
                    <Route path="/ProductAdd" element={<ProductAdd />} />
                    <Route path="/ProductEdit" element={<ProductEdit />} />
                    <Route path="/ShowForm" element={<ShowForm />} />
                    <Route path="/AdminPDF" element={<AdminPDF />} />
                    <Route path="/Orders" element={<Orders />} />
                    <Route path="/ProductDetailEdit" element={<ProductDetailEdit />} />
                  </Routes>
                  <WhatsAppButton />
                  <CookieBanner />
                </main>
              </Router>
            )}
          </AnimatePresence>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
