import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button, Link, Slide, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

import { useNotification } from "./NotificationContext";
import { useAuth } from "../auth/AuthContext";
const modalStyle = {
  position: "absolute",
  top: "25%",
  left: "31%",
  width: "30%",
  height: 300,
  transform: "translate(-50%, -25%)",
  background: "linear-gradient(to right, #11284A 0%, #1C427A 50%, #0E213D 100%)",
  boxShadow: 24,
  padding: 6,
  borderRadius: "22px", // İçerik kutusunun yuvarlatılması
  textAlign: "center",
  "&::before": {
    content: '""',
    position: "absolute",
    inset: 0,
    borderRadius: "22px", // Dış sınır yuvarlaması
    border: "6px solid transparent", // Şeffaf border
    background: "linear-gradient(180deg, #967241 0%, #f5ebd3 100%) border-box",
    mask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
    maskComposite: "exclude", // Sadece dış kenarlarda gradyan
  },
  "@media (max-width: 600px)": {
    left: "0%", // Konum sağa kaydırılmaz, tam ekran
    width: "auto", // Ekranın tamamını kullan
    height: "auto", // Yükseklik içerik boyutuna göre ayarlanır
  },
};

const inputStyle = {
  "& .MuiInputBase-root": {
    color: "white", // Yazıları beyaz yapıyoruz
    backgroundColor: "#B0B0B0", // Gümüş renkli arka plan
    borderRadius: "8px", // Köşe yuvarlama
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#C0C0C0", // Gümüş renkli border
    },
    "&:hover fieldset": {
      borderColor: "#A0A0A0", // Hover durumunda border rengi
    },
    "&.Mui-focused fieldset": {
      borderColor: "#808080", // Focus durumunda border rengi
    },
  },
  "& .MuiFormLabel-root": {
    color: "#1C427A",
    "&.Mui-focused": {
      color: "white", 
    },
  },
};

const buttonStyle = {
  background: "linear-gradient(to right, #11284A 0%, #1C427A 50%, #0E213D 100%)",
  backgroundSize: "200% 100%", // Gradient genişliği artırıldı
  backgroundPosition: "right center", // Başlangıç pozisyonu
  color: "#fff",
  border: "none",
  borderRadius: "25px",
  padding: "10px 20px",
  textDecoration: "none",
  transition: "background-position 0.5s ease, transform 0.3s ease",
  fontWeight: "bold", // Buton metnini kalın yapıyoruz
  fontSize: "16px", // Buton metnini büyütüyoruz
  "&:hover": {
    backgroundPosition: "left center", // Hover durumunda sola geçiş
    transform: "scale(1.05)", // Hafif büyüme efekti
    color: "#F0F8FF", // Daha parlak beyaz
    border: "none",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
  },
};

const LoginModal = ({ open, handleClose }) => {
  const [email, setEmail] = useState(""); // Email state'i
  const [password, setPassword] = useState(""); // Şifre state'i
  const [loading, setLoading] = useState(false); // Yükleniyor durumu
  const [error, setError] = useState(""); // Hata mesajı
  const { login } = useAuth();

  const showNotification = useNotification();
  const handleLogin = async () => {

    setLoading(true);
    setError(""); // Reset error message
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        email,
        password,
      });
      localStorage.setItem('token', response.data.token);
      const { role } = response.data; 
  
      login(role);
  
      showNotification("Giriş başarılı! Hoş geldiniz.", "success");
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      showNotification("Giriş başarısız! Lütfen bilgilerinizi kontrol edin.", "error");
    }
  };
  

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
    >
      <Slide direction="down" in={open} mountOnEnter unmountOnExit>
        <Box sx={modalStyle}>
          <IconButton
            onClick={handleClose} // Modal'ı kapatacak
            sx={{
              position: "absolute",
              top: 8,
              right: 8, // Sağ üst köşe
              color: "silver", // Beyaz renk
              zIndex: 2, // Üst katmanda kalması için
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="login-modal-title" variant="h6" component="h2" mb={2}
            sx={{
              fontWeight: "bold", // Kalın yap
              fontSize: "24px", // Daha büyük yap
              color: "white", // Beyaz yap
            }}
          >
            Giriş Yap
          </Typography>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Email state'ini güncelliyoruz
            sx={inputStyle} // Input stilini burada uyguluyoruz
          />
          <TextField
            label="Şifre"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Şifre state'ini güncelliyoruz
            sx={inputStyle} // Input stilini burada uyguluyoruz
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin} // Giriş yap butonuna tıklandığında handleLogin fonksiyonu çalışacak
            sx={{ ...buttonStyle, mt: 2, mb: 2 }}
            disabled={loading} // Yükleniyor durumunda buton pasif olacak
          >
            {loading ? "Yükleniyor..." : "Giriş Yap"} {/* Yükleniyor metni */}
          </Button>
          {error && (
            <Typography variant="body2" color="error" align="center">
              {error}
            </Typography>
          )}
          <Typography
            id="login-modal-description"
            variant="body2"
            color="white"
            align="center"
          >
            Hesabın yok mu?{" "}
            <Link
              href="/kayitol"
              underline="hover"
              sx={{
                position: "relative", // Link'i diğer katmanların üzerine taşıyoruz
                zIndex: 9, // Üst katmanda olmasını sağlıyoruz
                color: "silver",
                cursor: "pointer",
                textDecoration: "none",
                "&:hover": {
                  color: "white",
                },
              }}
            >
              Hemen Kaydol
            </Link>
          </Typography>
        </Box>
      </Slide>
    </Modal>
  );
};

export default LoginModal;
