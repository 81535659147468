import React, { useState,useEffect } from "react";
import { Box, Typography, TextField, Button, Paper, IconButton,CircularProgress } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useAuth } from '../auth/AuthContext';
import { useNotification } from "./NotificationContext";
import { useNavigate } from "react-router-dom";
import { fetchReviewsByProductId, addReview } from "../services/productService";
const ProductReviews = (productId) => {
  const [reviews, setReviews] = useState([
    { name: "Ahmet", comment: "Çok lezzetli!", rating: 5 },
    { name: "Elif", comment: "", rating: 4 },
    { name: "Mehmet", comment: "Tavsiye ederim.", rating: 0 },
  ]);
  const navigate = useNavigate();
  const [newComment, setNewComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [newRating, setNewRating] = useState(0);
  const { isLoggedIn } = useAuth();
  const token = localStorage.getItem('token');
  const showNotification = useNotification();
  const handleRating = (value) => {
    setNewRating(value);
  };

  useEffect(() => {
    const loadReviews = async () => {
      setLoading(true);
      const reviewsData = await fetchReviewsByProductId(productId, token);
      setReviews(reviewsData);
      setLoading(false);
    };
  
    if (productId) {
      loadReviews();
    }
  }, [productId]);
  
  const handleSubmit = async () => {
    if (!token) {
      navigate("/kayitol");
      showNotification("Kayıt Olmadan Yorum Yapamazsınız!", "error");
      return;
    }
  
    if (!newRating) {
      showNotification("Lütfen bir puan verin", "warning");
      return;
    }
  
    if (newComment.trim() || newRating) {
      try {
        console.log("Gönderilen veri:", {
          product_id: productId.productId,
          comment: newComment,
          rate: newRating,
        });
  
        await addReview(
          {
            product_id: parseInt(productId.productId),
            comment: newComment.trim(),
            rate: newRating,
          },
          token
        );
  
        showNotification("Yorumunuz başarıyla eklendi", "success");
        setNewComment("");
        setNewRating(0);
        await fetchReviewsByProductId(productId, token); // Yorumları yeniden yükle
        window.location.reload(); // Sayfayı yenile
      } catch (errorMessage) {
        showNotification(errorMessage, "error");
      }
    }
  };
  return (
    <Box
    sx={{
      p: 3,
      pr:{xs:3,md:6},
      maxWidth: "100%",
      mx: "auto",
    }}
  >
    {/* Yorum Gönderme Formu */}
    <Box sx={{ mb: 4 }}>
      <Typography variant="h3" sx={{ mb: 2, fontWeight: "bold", textAlign: "center",color:"#000",fontFamily: "'Sansita', sans-serif", fontSize:{xs:"20px",md:"30px"} }}>
        Yorum Yazın
      </Typography>
      <TextField
        fullWidth
        label="Yorumunuz"
        multiline
        rows={4}
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        variant="outlined"
        sx={{
          mb: 2,

          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#000",
            },
            "&:hover fieldset": {
              borderColor: "#9c7338",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#000",
            },
          },
          "& .MuiInputLabel-root": {
            color: "#fff", // Normal durumdaki label rengi
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#9c7338", // Focus olduğundaki label rengi
            fontFamily: "'Poppins', sans-serif",
          },
          "& .MuiInputBase-input": {
            color: "#000", // Yazılan metnin beyaz olması için
            fontFamily: "'Poppins', sans-serif",
          },
        }}
        
        
      />

      {/* Yıldız Puanlama */}
      <Box
sx={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  mb: 2,
  flexDirection: "column", // Mobilde alt alta olmasını sağlamak için
  "@media (min-width:600px)": {
    flexDirection: "row", // Masaüstü veya büyük ekranlarda yatay düzen
  },
}}
>
<Typography
  variant="body1"
  sx={{
    color: "#000",
    display: "flex",
    alignItems: "center",
    fontFamily: "'Poppins', sans-serif",
    mr: 1,
    my: 2,
  }}
>
  Değerlendirmeniz
</Typography>
<Box sx={{ display: "flex" }}>
  {[1, 2, 3, 4, 5].map((star) => (
    <IconButton 
      key={star} 
      onClick={() => handleRating(star)}
    >
      <StarIcon
        sx={{
          color: star <= newRating ? "#9c7338" : "#fff",
          fontSize: "30px",
        }}
      />
    </IconButton>
  ))}
</Box>
<Button
        variant="contained"
        onClick={handleSubmit}
        sx={{
          width: "70%",
          background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
          backgroundSize: "200% 100%", // Gradient genişliği artırıldı
          backgroundPosition: "right center", // Başlangıç pozisyonu
          color: "#fff",
          fontFamily: "'Poppins', sans-serif",
          fontWeight: "bold",
          my: 4,
          borderRadius: "8px", // Köşeleri yuvarlama
          padding: "10px 20px",
          textDecoration: "none",
          border: "none",
          cursor: "pointer",
          transition: "background-position 0.5s ease, transform 0.3s ease",
          "&:hover": {
            backgroundPosition: "left center", // Hover durumunda sola geçiş
            transform: "scale(1.01)", // Hafif büyüme efekti
            color: "#FFF", // Daha parlak beyaz
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
          },
        }}
      >
        Yorum Gönder
      </Button>
</Box>


    
    </Box>

    {/* Kullanıcı Yorumları */}
    <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold", textAlign: "center",fontFamily: "'Sansita', sans-serif",fontSize:{xs:"18px",md:"24px"} }}>
      Kullanıcı Yorumları
    </Typography>

    <Box sx={{ mb: 4 }}>
      {loading ? (
        <CircularProgress />
      ) : reviews.length > 0 ? (
        reviews.map((review, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{
              p: 2,
              mb: 2,
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "2px solid #9c7338"
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontFamily: "'Sansita', sans-serif", 
                  fontWeight: "bold", 
                  color: "#9c7338" 
                }}
              >
                {review.first_name}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", color: "#000", fontFamily: "'Sansita', sans-serif" }}>
                {review.rate > 0 ? (
                  <>
                    <StarIcon sx={{ color: "#9c7338", fontSize: "20px", mr: 0.5 }} />
                    <Typography variant="body2">{review.rate}</Typography>
                  </>
                ) : (
                  <Typography variant="body2" sx={{ fontFamily: "'Poppins', sans-serif", color: "#000" }}>
                    (Değerlendirme yapılmamış)
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography 
              variant="body2" 
              sx={{ 
                fontFamily: "'Poppins', sans-serif", 
                mt: 1, 
                color: "#000" 
              }}
            >
              {review.comment || "(Yorum yok)"}
            </Typography>
          </Paper>
        ))
      ) : (
        <Typography variant="body2" sx={{fontSize:{xs:"12px",md:"18px"},textAlign:"center"}} color="textSecondary">
          Henüz yorum yapılmamış. İlk yorumu siz yapın!
        </Typography>
      )}
    </Box>
  </Box>
);
};

export default ProductReviews;
