import React from "react";
import { Container, Typography } from "@mui/material";
import Navbar from "../components/navbar";
import Footer from '../components/Footer';
const Mss = () => {
  return (
    <>
    <Navbar />
    <Container>
    <Typography variant="h4" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",mb:2 }}>
              Mesafeli Satış Sözleşmesi
            </Typography>
      <Typography variant="h5" paragraph>
      MADDE  – 1 KONU
      </Typography>
      <Typography variant="body1" paragraph>
      Bu sözleşmenin konusu, Satıcı’ nın Alıcı’ya (Tüketici ’ye) satışını yaptığı, 6502 sayılı Tüketicilerin 
Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Uygulama Esas ve Usulleri Hakkında 
Yönetmelik hükümleri diğer yasal hükümler uyarınca tarafların hak ve yükümlülüklerinin belirlenmesidir.
      </Typography>
      <Typography variant="h5" paragraph>
      MADDE  – 2 SÖZLEŞME’NİN KURULMASI
      </Typography>
      <Typography variant="body1" paragraph>
      2.1  Alıcı sözleşme'yi, anladığını, haklarının ve yükümlülüklerinin bilincinde olduğunu kabul eder.
      </Typography>
      <Typography variant="body1" paragraph>
      2.2  Satıcı ve alıcı, sözleşme ile kararlaştırılan edimler arasında hiçbir oransızlık bulunmadığını ve 
karşılıklı edimlerin işin niteliğine uygun olduğunu, sözleşme konusuna giren işlemler kapsamında 
herhangi bir tecrübesizliklerinin bulunmadığını kabul ederler.
      </Typography>
      <Typography variant="body1" paragraph>
      2.3  Alıcı, sözleşme kapsamında yer alan işlemlerin kendi menfaatine uygun olduğu konusunda tam bir 
kanaate vardığını ve tüm şartlara kendi özgür iradesi ile, hiçbir güçlük veya sıkıntı içinde olmadan, 
düşünerek, isteyerek ve bilerek uyacağını kabul eder.
      </Typography>
      <Typography variant="body1" paragraph>
      2.4  Satıcı ve alıcı, sözleşmenin hükümlerinin haksız şart sayılabilecek bir özellik taşımadığını, 
menfaatler dengesi bakımından bir haksızlık olmadığını kabul eder.
      </Typography>
      <Typography variant="body1" paragraph>
      2.5  İşbu sözleşme, ekleri ve www.ozbeksucuklari.com.tr  üzerinden yapılan işlemler kapsamında tedarik 
edilen bilgiler ve elektronik vasıtalarla onaylanan konular kampanyalı satışlara ilişkin uygulama usul ve 
esasları hakkında yönetmelik’in 6. maddesi’nin gerekliliklerine karşılık gelmektedir.
      </Typography>
      <Typography variant="body1" paragraph>
      2.6  İşbu sözleşme hükümleri tüketici sözleşmelerindeki haksız şartlar hakkında yönetmelik hükümleri 
uyarınca herhangi bir haksız şart içermemektedir. Hükümler dürüstlük ve iyiniyet kuralına aykırılık teşkil 
etmemekte olup tüketicinin korunması mevzuatına uygun olarak hazırlanmıştır.
      </Typography>
      <Typography variant="body1" paragraph>
      2.7  İşbu sözleşme hükümleri 6098 sayılı yeni borçlar kanunu hükümleri de dikkate alınarak 
hazırlanmıştır. Yeni borçlar kanunu’nun 21. maddesinde öngörülen bağlayıcılık ve içerik denetimi alıcı 
tarafından yapılmıştır. işbu sözleşme hükümlerinden hiçbiri işbu sözleşme’nin niteliğine ve işin 
özelliğine yabancı (şaşırtıcı şartlar) nitelik taşımaz. İşbu sözleşme hükümleri açık ve anlaşılır bir şekilde 
yazılmış olup birden çok anlamı ifade etmemektedir.
      </Typography>
      <Typography variant="h5" paragraph>
      MADDE  – 3 SATICI BİLGİLERİ
      </Typography>
      <Typography variant="body1" paragraph>
      Unvan: LOKMAN ÖZBEK ÖZBEK SUCUKLARI
      </Typography>
      <Typography variant="body1" paragraph>
      Adres: Organize Sanayi Bölgesi  1. Cd 4. Sk No:5 Merkez/Afyonkarahisar
      </Typography>
      <Typography variant="body1" paragraph>
      Telefon: 0272 221 10 95 Faxs: 221 12 43 
      </Typography>
      <Typography variant="body1" paragraph>
      E posta: info@ozbeksucuklari.com.tr
      </Typography>
      <Typography variant="h5" paragraph>
      MADDE – 4 İADE VE GARANTİ KOŞULLARI
      </Typography>
      <Typography variant="body1" paragraph>
      Kargoyu teslim alırken koli içini kontrol ediniz. Ürünlerde hasar veya eksik varsa kargo personelinden 
tutanak tutmasını isteyiniz. Teslimi yapılmış bir sipariş için kargo personelinin tüm sorumluluğunu 
yerine getirmiş olduğunu unutmayınız.  Sözleşme konusu ürün, ALICI’dan başka bir kişi/kuruluşa teslim 
edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden SATICI sorumlu tutulamaz.
 Kargo firmasının, ürünü ALICI’ya teslimi aşamasında karşılaşacağı her türlü sorun nedeniyle, siparişi 
verilen ürünün ALICI’ya teslim edilememesinden dolayı SATICI sorumlu tutulamaz.SATICI, sözleşme 
konusu ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri ve 
kullanım kılavuzları ile teslim edilmesinden sorumludur.
 Teslim almış olduğunuz siparişte yanlış eksik veya kusurlu bir ürün olması halinde değişim ve iade 
hakkınızı kullanabilirsiniz. Siparişiniz ile ilgili memnuniyetsizliğiniz olduğunda bize 
info@ozbeksucuklari.com.tr  mail ‘inden sipariş numaranızı belirterek e mail atabilirsiniz veya online 
whatsapp hattından (0549 221 10 95) ulaşabilirsiniz
 Almış olduğunuz ürünü ambalajını açmadan, tahrip etmeden, ürünü kullanmadan ve daha sonra tekrar 
satışına engel olmayacak şekilde teslim tarihini geçmemesine dikkat ederek teslim aldığınız şekli ile iade 
edebilirsiniz. İade kargonuz firmamıza teslim edilince sipariş tutarınız hesabınıza yatırılacaktır. Ürün 
iade işlemlerinde kargo bedelinin iadesi yapılmaz. Sipariş edilen ürün hatası müşteri kullanımından 
oluşmuşsa veya ürün kullanılmışsa ürünün iade ve değişimi yapılmaz
 Ürün iadesi ve değiştirme şartları olarak, 4077 sayılı Tüketicinin Korunması Hakkında Kanun gereği 
uygulamalar esastır.
      </Typography>
      <Typography variant="h5" paragraph>
      MADDE - 5 TESLİMAT BİLGİLERİ
      </Typography>
      <Typography variant="body1" paragraph>
      Siparişleriniz, banka onayı alındıktan sonra gün içerisinde içerisin de kargoya teslim edilir. Teslimat 
adresinin uzaklığına göre de yasal 30 günlük süreyi aşmamak koşulu ile her bir ürün için ALICI’nın 
yerleşim yerinin uzaklığına bağlı olarak kargo şirketi 1-3 gün içerisinde siparişinizi size ulaştıracaktır.
 Tarafımızdan kaynaklanan bir aksilik olması halinde ise size üyelik bilgilerinizden yola çıkılarak haber 
verilecektir. Bu yüzden üyelik bilgilerinizin eksiksiz ve doğru olması önemlidir. Bayram ve tatil 
günlerinde teslimat yapılmamaktadır. Satın aldığınız ürünler bir teyit e-posta'sı ile tarafınıza 
bildirilecektir. Seçtiğiniz ürünlerden herhangi birinin stokta mevcut olmaması durumunda konu ile ilgili 
bir e-posta size yollanacak ve ürünün ilk stoklara gireceği tarih tarafınıza bildirilecektir.
      </Typography>
    </Container>
    <Footer/>
    </>
  );
};

export default Mss;
