import React, { useState } from "react";
import { Box, Grid, } from "@mui/material";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
import ShoppingCart from "../components/ShoppingCart";
import ProductList from "../components/ProductList";
import FilterSidebar from "../components/FilterSidebar";


const Products = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({ category: "", priceRange: [0, 3000] });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "0",
        margin: "0",
      }}
    >
      <Navbar />
      <main>
      <Grid container sx={{ paddingTop: 2 }}> {/* spacing added for spacing between columns */}
  {/* Filtreleme Paneli (Solda) */}
  <Grid item xs={12} sm={3} spacing={2}>
    <Box
      sx={{
        px: { xs: 1, sm: 3 },
        py: 1,
        color: "white",
        display: "flex",
        flexDirection: "column",

      }}
    >




      {/* Filtreleme Sidebar */}
      <FilterSidebar
      filters={filters}
      onFilterChange={handleFilterChange}
      searchQuery={searchQuery}  // Arama sorgusunu geçiriyoruz
      handleSearchChange={handleSearchChange}  // Arama fonksiyonunu geçiriyoruz
    />
    </Box>
  </Grid>

  {/* Ürün Listesi (Sağda) */}
  <Grid item xs={12} sm={9}>
    <ProductList searchQuery={searchQuery} filters={filters} />
  </Grid>
</Grid>

        <ShoppingCart />
        <Footer />
      </main>
    </div>
  );
};

export default Products;
