import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_URL}/orders`;

function decodeJWT(token) {
  const base64Url = token.split('.')[1]; // Token'ın ikinci parçası payload'dur
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Base64 formatını düzelt
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  
  return JSON.parse(jsonPayload); // JSON formatında decode edilmiş veriyi döndür
}

// Sipariş oluşturma fonksiyonu
export const createOrder = async (orderData, token) => {
  if (token) {
  const decodedToken = decodeJWT(token);
  const userId = decodedToken.id;
  orderData.user_id = userId;
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(API_URL, orderData, config);
    return response.data;
  } catch (error) {
    console.error("Sipariş oluşturulurken hata oluştu:", error.response?.data || error.message);
    throw error;
  }
};

export const fetchAddresses = async (token) => {
  const decodedToken = decodeJWT(token); // Token'ı çözümleyip payload verisini al
  const userId = decodedToken.id; // Payload'dan kullanıcı ID'sini al

  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/address/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Token'ı header'da gönderiyoruz
      },
    });
    return response.data; // Adresler verisini döndürüyoruz
  } catch (error) {
    console.error("Adresler alınırken hata oluştu:", error.response?.data || error.message);
    throw error; // Hata oluşursa, frontend kısmında yakalanabilir
  }
};