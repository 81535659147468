import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import axios from 'axios';
import { motion } from "framer-motion";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    issue: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Form verilerini backend'e gönder
    axios.post('http://localhost:5000/api/contactForm', formData)
      .then(response => {
        console.log("Form başarıyla gönderildi:", response.data);
  
        // Formu sıfırlama
        setFormData({
          name: "",
          email: "",
          issue: "",
        });
  
        // Başarı mesajı veya başka işlemler
      })
      .catch(error => {
        console.error("Form gönderilirken bir hata oluştu:", error);
        // Hata mesajı veya başka işlemler
      });
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        p: 3,
        background:
        "transparent",
        boxShadow:"0",
        border:"2px solid #9c7338",
        maxWidth: "1200px",
        margin: "0 auto",
        borderRadius:"8px"
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 3, textAlign: "center", color: "#000",fontFamily: "'Sansita', sans-serif" }}>
        İletişim Formu
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Ad Soyad"
          variant="outlined"
          margin="normal"
          name="name"
          value={formData.name}
          onChange={handleChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#9c7338", // Border color default
              },
              "&:hover fieldset": {
                borderColor: "#fff", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#9c7338", // Border color on focus
              },
              "& input": {
                color: "#000", // Input text color
              },
            },
            "& .MuiInputLabel-root": {
              color: "white", // Label color default
            },
            "& .Mui-focused .MuiInputLabel-root": {
              color: "#000", // Label color on focus
            },
          }}
        />
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          margin="normal"
          name="email"
          value={formData.email}
          onChange={handleChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#9c7338", // Border color default
              },
              "&:hover fieldset": {
                borderColor: "#fff", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#9c7338", // Border color on focus
              },
              "& input": {
                color: "#000", // Input text color
              },
            },
            "& .MuiInputLabel-root": {
              color: "white", // Label color default
            },
            "& .Mui-focused .MuiInputLabel-root": {
              color: "#fff", // Label color on focus
            },
          }}
        />
        <TextField
          fullWidth
          label="Konu"
          variant="outlined"
          margin="normal"
          name="issue"
          value={formData.issue}
          onChange={handleChange}
          multiline
          rows="4"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#9c7338", // Border color default
              },
              "&:hover fieldset": {
                borderColor: "#fff", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#9c7338", // Border color on focus
              },
              "& input": {
                color: "white", // Input text color
              },
            },
            "& .MuiInputLabel-root": {
              color: "white", // Label color default
            },
            "& .Mui-focused .MuiInputLabel-root": {
              color: "#9c7338", // Label color on focus
            },
          }}
        />
        <Button
          variant="contained"
          type="submit"
          component={motion.button}
          whileTap={{ scale: 0.95 }}
          sx={{
            width: "100%",
            background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
            backgroundSize: "200% 100%", // Gradient genişliği artırıldı
            backgroundPosition: "right center", // Başlangıç pozisyonu
            color: "#fff",
            fontWeight: "bold",
    
            borderRadius: "8px", // Köşeleri yuvarlama
            padding: "10px 20px",
            textDecoration: "none",
            border: "none",
            cursor: "pointer",
            transition: "background-position 0.5s ease, transform 0.3s ease",
            "&:hover": {
              backgroundPosition: "left center", // Hover durumunda sola geçiş
              transform: "scale(1.01)", // Hafif büyüme efekti
              color: "#F0F8FF", // Daha parlak beyaz
              boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
            },
          }}
        >
          Gönder
        </Button>
      </form>
    </Box>
  );
};

export default ContactForm;
