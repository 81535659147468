import { useState, useEffect } from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookieAccepted");
    if (!isCookieAccepted) {
      setShowBanner(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("cookieAccepted", "true");
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
<motion.div
  initial={{ y: 50, opacity: 0 }}
  animate={{ y: 0, opacity: 1 }}
  exit={{ y: 50, opacity: 0 }}
  transition={{ duration: 0.5 }}
>
  <Box
    sx={{
      position: "fixed",
      bottom: 0,
      left: { xs: 0, sm: "10%", md: "10%" },
      width: { xs: "95%", sm: "80%", md: "80%" },
      height: "100px",
      background: "rgba(0, 0, 0, 0.8)",
      color: "white",
      padding: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      zIndex: 1000,
    }}
  >
      <Box display="flex" alignItems="center" gap={{xs:0.5,md:5}}>
        <img
          src="/cookie.png"
          alt="Çerezler"
          style={{ width: "120px", height: "100px" }}
        />
        <Typography variant="body2" sx={{fontSize:{xs:"10px",md:"14px"},mr:{xs:4,sm:6,md:10}}}>
          Kişisel verileriniz, hizmetlerimizin daha iyi bir şekilde sunulması için
          mevzuata uygun bir şekilde toplanıp işlenir. Konuyla ilgili detaylı bilgi almak için {" "}
          <Link href="/aydinlatma-metni" color="primary" underline="hover">
            Aydınlatma Metnini
          </Link>{" "}
          inceleyebilirsiniz.
        </Typography>
      </Box>
      <Button onClick={handleClose} color="inherit" sx={{ml:{xs:0,md:4},position: "absolute",top: 8,right: 0,}}>
        <CloseIcon />
      </Button>
      </Box>
    </motion.div>
  );
};

export default CookieBanner;
