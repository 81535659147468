import React,{ useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Login from "@mui/icons-material/Login";
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings";
import AccountCircle  from "@mui/icons-material/AccountCircle";
import "../styles/navbar.css";
import LoginModal from "./LoginModal";
import DrawerComponent from "./DrawerComponent";
import { useNavigate,Link, useLocation } from "react-router-dom";
import { useAuth } from "../auth/AuthContext"; 

const Navbar = ({productName }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, userRole } = useAuth();

const toggleDrawer = (open) => {
  return (event) => {
 
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };
};
const breadcrumbs = location.pathname
.split("/")
.filter((path) => path) // Boş parçaları temizle
.map((path, index, arr) => {
  const routeTo = `/${arr.slice(0, index + 1).join("/")}`;
  const label = path.charAt(0).toUpperCase() + path.slice(1); // İlk harfi büyük yap
  return { routeTo, label };
});

const handleRedirect = (role) => {
  if (role === "admin") {
    navigate("/AdminPanel"); // Admin sayfasına yönlendir
  } else {
    navigate("/hesabim"); // Kullanıcı profil sayfasına yönlendir
  }
};
  return (
    <Box   
    sx={{
      backgroundImage: "url('/banner.webp')",
      backgroundSize: "cover",
      backgroundPosition: { xs: "85% 70%", md: "0% 70%" },
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: { xs: "220px", md: "400px" },
      paddingTop: "55px",
      filter: "drop-shadow(3px 3px 15px rgba(0, 0, 0, 1))",
    }}
  >
    <AppBar
      position="static"
      sx={{
        background: 'rgba(255,255,255,0)',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0)', 

        pt:0.6,
        fontFamily: "'Sansita', sans-serif",
        height: "45px",
        borderRadius: "12px",
        width:"100%",
        mx:"auto"
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: { xs: "space-between", md: "space-between" },
          alignItems: "center",
          minHeight: { sm: "auto" },
        }}
      >
                <Box
          sx={{
            position: "absolute",
            left: {xs:"90px",md:"125px"},
            top:{xs:"-25px",md:"-50px"},
            transform: "translateX(-50%)",
            zIndex: 1,
            filter: "drop-shadow(5px 5px 15px #000)",
            WebkitFilter: "drop-shadow(5px 5px 15px #000)",
            height: {
              xs: "80px",
              sm: "105px",
              md: "120px",},
          }}
        >
          <img src="/ozbek-logo.png" alt="Logo" style={{ height: "100%" }} />
        </Box>
        {/* Sol Menü - Menü Elemanları (Masaüstü) */}
        <Box
  sx={{
    display: { xs: "none", sm:"none", md: "flex", lg: "flex" },
    alignItems: "center",
    gap: {
      xs: "10px",
      sm: "15px",
      md: "10px",
      lg: "40px",
      xl: "50px",
    },
    ml: "250px", // Soldan boşluk
  }}
>
  {/* Menü Elemanları */}
  <div className="menu-item">
    <Typography
      variant="h6"
      component="a"
      href="/"
      sx={{
        color: "white",
        fontFamily: "'Sansita', sans-serif",
        fontSize:"22px",
        textDecoration: "none",
        textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
        paddingBottom:"5px",
        "&:hover": { color: "#B0B0B0" },
        transition: "color 0.3s ease",
      }}
    >
      ANA SAYFA
    </Typography>
  </div>
  <div className="menu-item">
    <Typography
      variant="h6"
      component="a"
      sx={{
        color: "white",
        cursor:"pointer",
        textDecoration: "none",
        fontFamily: "'Sansita', sans-serif",
        fontSize:"22px",
        textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
        paddingBottom:"5px",
        "&:hover": { color: "#B0B0B0" },
        transition: "color 0.3s ease",
      }}
    >
      KURUMSAL
    </Typography>
    <div className="submenu">
    <List>
      <ListItem button  component="a" href="/hakkimizda">
        <ListItemText  primary="Hakkımızda" />
      </ListItem>
      <ListItem button component="a" href="/tesisimiz">
        <ListItemText primary="Tesisimiz" />
      </ListItem>
      <ListItem button component="a" href="/belgelerimiz">
        <ListItemText  primary="Belgelerimiz" />
      </ListItem>
    </List>
  </div>
  </div>
  <div className="menu-item">
    <Typography
      variant="h6"
      component="a"
      href="/urunlerimiz"
      sx={{
        color: "white",
        fontFamily: "'Sansita', sans-serif",
        fontSize:"22px",
        textDecoration: "none",
        paddingBottom:"5px",
        textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
        "&:hover": { color: "#B0B0B0" },
        transition: "color 0.3s ease",
      }}
    >
      ÜRÜNLERİMİZ
    </Typography>
  </div>
  <div className="menu-item">
    <Typography
      variant="h6"
      component="a"
      href="/iletisim"
      sx={{
        color: "white",
        fontFamily: "'Sansita', sans-serif",
        fontSize:"22px",
        textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
        textDecoration: "none",
        paddingBottom:"5px",
        "&:hover": { color: "#B0B0B0" },
        transition: "color 0.3s ease",
      }}
    >
      İLETİŞİM
    </Typography>
  </div>
  <div className="menu-item">
    <Typography
      variant="h6"
      component="a"
      href="/katalog"
      sx={{
        color: "white",
        fontFamily: "'Sansita', sans-serif",
        fontSize:"22px",
        paddingBottom:"5px",
        textDecoration: "none",
        textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
        "&:hover": { color: "#B0B0B0" },
        transition: "color 0.3s ease",
      }}
    >
      KATALOG
    </Typography>
  </div>
 
</Box>
<Box
          sx={{
            display: { xs: "none", md: "flex", lg: "flex" },

            justifyContent: "flex-end",
            alignItems:"flex-end"
          }}
        >
  <div className="menu-item">
    {!isLoggedIn ? (
      <Typography
        variant="h6"
        component="a"
        onClick={handleModalOpen}
        sx={{
          fontFamily: "'Sansita', sans-serif",
          fontSize:"22px",
          cursor: "pointer",
          textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
          color: "white",
          display: "flex",
          alignItems: "center",
          gap: "8px",

          textDecoration: "none",
          "&:hover": { color: "#B0B0B0" },
        }}
      >
        GİRİŞ YAP <Login />
      </Typography>
    ) : userRole === "admin" ? (
      <Typography
        variant="h6"
        component="a"
        onClick={() => handleRedirect("admin")}
        sx={{
          cursor: "pointer",
          color: "white",
          textDecoration: "none",
          "&:hover": { color: "#B0B0B0" },
        }}
      >
        Admin
      </Typography>
    ) : (
      <Typography
        variant="h6"
        component="a"
        onClick={() => handleRedirect("user")}
        sx={{
          cursor: "pointer",
          color: "white",
          textDecoration: "none",
          "&:hover": { color: "#B0B0B0" },
        }}
      >
        Hesabım
      </Typography>
    )}
    <LoginModal open={isModalOpen} handleClose={handleModalClose} />
  </div>
  </Box>


        {/* Hamburger İkonu - Mobil */}
        
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ display: { xs: "flex",sm:"flex" ,md: "none" }, position: "absolute", right: "10px", top: "30%", transform: {xs: "translateY(-50%)", sm: "translateY(-20%)", }, }} onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        <IconButton
        sx={{ display: { xs: "flex", sm: "flex", md: "none" },position: "absolute", right: "60px", top: "30%", transform: {xs: "translateY(-50%)", sm: "translateY(-20%)", },}}
      edge="start"
      color="inherit"
      aria-label="login"
      onClick={isLoggedIn ? null : handleModalOpen}
    >
      {/* Eğer giriş yapılmışsa ve kullanıcı adminse, Admin ikonu göster */}
      {isLoggedIn && userRole === "admin" ? (
        <AdminPanelSettings onClick={() => handleRedirect("admin")}  />
      ) : isLoggedIn && userRole === "user" ? (
        <AccountCircle  onClick={() => handleRedirect("user")} />
      ) : (
        <Login/>
      )}
    </IconButton>

        {/* Drawer - Mobil Açılır Menü */}
        <DrawerComponent isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      </Toolbar>
    </AppBar>
    <div className="custom-div">
  <Typography
    variant="h1"
    sx={{
      fontSize: { xs: "20px", sm: "40px", md: "48px", lg: "56px" },
      fontWeight: "bold",
      fontFamily: "'Sansita', sans-serif",
      marginTop: "10px",
      marginBottom: "0px",
      textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
    }}
  >
    {productName ? productName.toUpperCase() : "ANASAYFA"}
  </Typography>

  <Box
    component={Link}
    to="/"
    sx={{
      color: "#fff",
      textDecoration: "none",
      fontFamily: "'Sansita', sans-serif",
      textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
      fontSize: { xs: "14px", sm: "16px", md: "22px", lg: "28px" },
    }}
  >
    Anasayfa
  </Box>

  {/* Breadcrumbs'ı render et */}
  {breadcrumbs.map((crumb, index) => {
    // Son elemanda productName'i göster
    if (index === breadcrumbs.length - 1 && productName) {
      crumb.label = productName; // Son elemana ürün ismini ekleyin
    }
    return (
      <span key={index}>
        {" > "}
        <Box
          component={Link}
          to={crumb.routeTo}
          sx={{
            color: index === breadcrumbs.length - 1 ? "#9c7338" : "#fff",
            textDecoration: "none",
            textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
            fontSize: { xs: "10px", sm: "12px", md: "18px", lg: "24px" },
          }}
        >
          {crumb.label}
        </Box>
      </span>
    );
  })}
</div>
    </Box>
  );
};

export default Navbar;
