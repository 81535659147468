import React, { useState, useEffect } from "react";
import {Box, } from "@mui/material";
import Navbar from "../components/navbar";
import Slider from '../components/Slider';
import MainProduct from '../components/MainProduct';
import About from '../components/About';
import Footer from '../components/Footer';
import ShoppingCart from "../components/ShoppingCart";
const Home = () => {
    const [divHeight, setDivHeight] = useState("550px");
    const slideData = [
      {
        src: "/slider1.png",
      },
      {
        src: "/slider1.png",
      },
      {
        src: "/slider1.png",
      },
      {
        src: "/slider1.png",
      },
    ];
    useEffect(() => {
      const updateHeight = () => {
        if (window.innerWidth <= 768) {
          setDivHeight("450px");
        } else {
          setDivHeight("550px");
        }
      };
  
      updateHeight();
      window.addEventListener("resize", updateHeight); 
  
      return () => {
        window.removeEventListener("resize", updateHeight);
      };
    }, []);
  return (
    <div
    style={{
    height: "100%",
    width: "100%",
    padding: "0",
    margin: "0"
    }}>
      <Navbar />
      <main>
      <Box sx={{  }}>
          <ShoppingCart /> 
        </Box>
        <div>
        <Slider slideData={slideData} />
        </div>
        <Box 
          sx={{
            marginTop: "40px",
            position: "relative",
            backgroundImage: "url('product.jpg')", 
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: divHeight
          }}
        >
          <MainProduct />
        </Box>
        <div
         style={{
          marginTop: "40px",
        }}>
          <About />
        </div>
        <div>
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default Home;
