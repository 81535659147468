import React, { useState } from "react";
import {
  TextField,
  Grid,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

const BillingAddressForm = ({ onFormDataChange,sameAsDelivery,onSameAsDeliveryChange }) => {
    const [formData, setFormData] = useState({
      fullName: "",
      address: "",
      city: "",
      district: "",
      taxOffice: "",
      taxNumber: "",
    });
  
    const [errors, setErrors] = useState({});

  
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = { ...formData, [name]: value };
      setFormData(updatedFormData);
      onFormDataChange(updatedFormData); // Üst bileşene gönder
    };
  
    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        onSameAsDeliveryChange(isChecked);
      if (e.target.checked) {
        const resetData = {
          fullName: "",
          address: "",
          city: "",
          district: "",
          taxOffice: "",
          taxNumber: "",
        };
        setFormData(resetData);
        onFormDataChange(resetData); // Resetlenmiş veriyi üst bileşene gönder
        setErrors({});
      }
    };

  return (
    <Box sx={{ border: "2px solid #9c7338", p: 3, borderRadius: "8px" }}>
      <Typography
        variant="h5"
        sx={{
          textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",
          fontFamily: "'Sansita', sans-serif",
        }}
        gutterBottom
      >
        Fatura İşlemleri
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={sameAsDelivery}
            onChange={handleCheckboxChange}
            sx={{
              color: "#000",
              "&.Mui-checked": {
                color: "#9c7338",
              },
            }}
          />
        }
        label="Fatura adresim teslimat adresim ile aynı"
        sx={{
          color: sameAsDelivery ? "#9c7338" : "#000",
          fontWeight: "bold",
        }}
      />
      {!sameAsDelivery && (
        <Box mt={3}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Ad Soyad"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                  sx={{
                    "& label.Mui-focused": { color: "#000" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#9c7338" },
                      "&:hover fieldset": { borderColor: "#fff" },
                      "&.Mui-focused fieldset": { borderColor: "#fff" },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Fatura Adresi"
                  multiline
                  rows={4}
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  error={!!errors.address}
                  helperText={errors.address}
                  sx={{
                    "& label.Mui-focused": { color: "#000" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#9c7338" },
                      "&:hover fieldset": { borderColor: "#fff" },
                      "&.Mui-focused fieldset": { borderColor: "#fff" },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Şehir"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  error={!!errors.city}
                  helperText={errors.city}
                  sx={{
                    "& label.Mui-focused": { color: "#000" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#9c7338" },
                      "&:hover fieldset": { borderColor: "#fff" },
                      "&.Mui-focused fieldset": { borderColor: "#fff" },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="İlçe"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  error={!!errors.district}
                  helperText={errors.district}
                  sx={{
                    "& label.Mui-focused": { color: "#000" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#9c7338" },
                      "&:hover fieldset": { borderColor: "#fff" },
                      "&.Mui-focused fieldset": { borderColor: "#fff" },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Vergi Dairesi"
                  name="taxOffice"
                  value={formData.taxOffice}
                  onChange={handleChange}
                  error={!!errors.taxOffice}
                  helperText={errors.taxOffice}
                  sx={{
                    "& label.Mui-focused": { color: "#000" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#9c7338" },
                      "&:hover fieldset": { borderColor: "#fff" },
                      "&.Mui-focused fieldset": { borderColor: "#fff" },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Vergi No"
                  name="taxNumber"
                  value={formData.taxNumber}
                  onChange={handleChange}
                  error={!!errors.taxNumber}
                  helperText={errors.taxNumber}
                  sx={{
                    "& label.Mui-focused": { color: "#000" },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { borderColor: "#9c7338" },
                      "&:hover fieldset": { borderColor: "#fff" },
                      "&.Mui-focused fieldset": { borderColor: "#fff" },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default BillingAddressForm;
