import React, { useState,useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  Paper,
  Modal,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  FormControlLabel,

} from "@mui/material";
import { useNotification } from "../components/NotificationContext";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
import AddressModal from "../components/AddressModal";
import BillingAddressForm from "../components/BillingAddressForm";
import CardModal from "../components/CardModal";
import { cities } from "../data/cities";
import {  useDispatch,useSelector } from "react-redux";
import { createOrder,fetchAddresses   } from "../services/orderService";
import GuestOrderForm from "../components/GuestOrderForm";
const Payment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openCardModal, setOpenCardModal] = useState(false);
  const [sameAsDelivery, setSameAsDelivery] = useState(true);
  const [cards, setCards] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cvv, setCvv] = useState("");
  const [billingFormData, setBillingFormData] = useState({
    fullName: "",
    address: "",
    city: "", 
    district: "",
    taxOffice: "",
    taxNumber: "",
  });
  const [guestOrderData, setGuestOrderData] = useState({
    firstName: "",
    lastName:"",
    email: "",
    phone: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [newAddress, setNewAddress] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const cartItems = useSelector((state) => state.cart.items);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const shippingFee = totalAmount <= 2000 ? 150 : 0;
  const totalPrice = totalAmount + shippingFee;
  const showNotification = useNotification();
  const handleConfirmPayment = async () => {
    if (!selectedAddress || !selectedCard) {
      showNotification(`Lütfen Bir Adres Ve Kart Seçin!`, "info");
      return;
    }
    setLoading(true);
    const cardObject = JSON.parse(selectedCard);
    const billingAddress = !sameAsDelivery ? billingFormData : null;
  
    const orderDetails = cartItems.map((item) => ({
      product_id: item.id,
      name:item.name,
      quantity: item.quantity,
      price: item.price,
      total: item.price * item.quantity,
    }));

    const orderData = {
      order_date: new Date().toISOString(),
      status: 1,
      total_price: totalPrice,
      address_id: selectedAddress.id || null,
      orderDetails,
      city: selectedAddress.city,
      district: selectedAddress.district,
      address: selectedAddress.fullAddress,
      cardNumber: cardObject.cardNumber,
      cardHolder: `${cardObject.firstName} ${cardObject.lastName}`,
      expirationDate: cardObject.expiryDate,
      cvv: cvv,
      ...(billingAddress && { billingAddress }),
      ...(isLoggedIn ? null : guestOrderData && { guestOrderData })

    };
    

  
    const token = localStorage.getItem("token");
  
    try {
       const orderId = await createOrder(orderData, token);

       showNotification("Ödeme Başarı İle Tamamlandı!", "success");
       localStorage.setItem("notificationMessage", "Ödeme Başarı İle Tamamlandı!");
       localStorage.setItem("notificationSeverity", "success");
       dispatch({ type: "cart/clearCart" });
       setLoading(false);
       navigate(`/sepetim/odeme/siparis/${orderId}`);
    } catch (error) {
      const errorMessage =
    error.response?.data?.errorMessage ||
    error.response?.data?.message ||
    error.message ||
    "Sipariş oluşturulurken bir hata oluştu!";


    showNotification(`Ödeme başarısız! ${errorMessage}`, "error");
    setLoading(false);
    }
  };
  
  const handleAddAddress = () => {
    if (city && district && newAddress) {
      const newAddressObj = {
        city: city,
        district: district,
        fullAddress: newAddress,
      };
  
      // Yeni adresi addresses dizisine ekle
      setAddresses([...addresses, newAddressObj]);
  
      // Formu sıfırla
      setCity("");
      setDistrict("");
      setNewAddress(""); // Geçici adresi sıfırla
  
      setOpen(false); // Modalı kapat
    } else {
      // Kullanıcıya bilgi ver: Şehir, ilçe ve adres boş bırakılamaz
      showNotification(`Şehir İlçe Ve Adres Bilgilerini Giriniz!`, "info");
    }
  };
  

  const handleCardSelect = (card) => {
    setSelectedCard(card);
    setOpenModal(true); // CVV modalını aç
  };

  useEffect(() => {
    const token = localStorage.getItem('token'); // Token'ı localStorage'dan al

    if (token) {
      const fetchAddressesData = async () => {
        try {
          const data = await fetchAddresses(token);  // Adresleri al

  
          // Adres verilerini eşlemek
          const mappedAddresses = data.map(address => ({
            city: address.city,
            district: address.state || address.district, // state ya da district kullanabilirsiniz
            fullAddress: address.address || address.newAddress, // address ya da newAddress kullanabilirsiniz
          }));
  
          setAddresses(mappedAddresses); // Eşlenmiş adresleri state'e ekle
        } catch (error) {
          showNotification(`Adres Verisi Bulunamadı!`, "info");
        }
      };
  
      fetchAddressesData();
    }
  }, []);
  useEffect(() => {
    // localStorage'dan kullanıcı bilgilerini kontrol et
    const userData = localStorage.getItem("isLoggedIn"); // Örneğin "user" verisini kullanabilirsiniz.
    if (userData) {
      setIsLoggedIn(true); // Kullanıcı giriş yaptıysa true
    } else {
      setIsLoggedIn(false); // Kullanıcı girişi yoksa false
    }
  }, []);
  
  const handleOpen = () => {
    setOpenCardModal(true);
  };

  const handleClose = () => {
    setOpenCardModal(false);
  };

  const handleCardAdd = (cardData) => {
    setCards([...cards, cardData]);
  };
  const handleCvvSubmit = () => {
    setOpenModal(false); // Modalı kapat

  };
  const handleFormDataChange = (updatedData) => {
    setBillingFormData(updatedData);
  };
  const handleGuestDataChange = (updatedData) => {
    setGuestOrderData(updatedData);
  };
  return (
    <Box>
      <Navbar />
      <Box
        sx={{
          maxWidth: "1200px",
          margin: "auto",
          p: { xs: 2, sm: 4 },
          my: 4,
          color: "white",
          boxShadow: "0",

        }}
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif", }}
          gutterBottom
        >
          Ödeme Bilgileri
        </Typography>
        <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    overflow: "hidden",
    mb:2
  }}
>
  <Divider
    sx={{
      borderWidth: "2px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
</Box>
<GuestOrderForm  onFormDataChange={handleGuestDataChange} isLoggedIn={isLoggedIn} />
<Box mb={4} sx={{border:"2px solid #9c7338", p:3,borderRadius:"8px"}}>
  <Typography variant="h5" gutterBottom sx={{textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",}}>
    Adresler
  </Typography>
  {addresses.length > 0 ? (
    <RadioGroup
      value={selectedAddress}
      onChange={(e) => setSelectedAddress(e.target.value)}
    >
      <List>
        {addresses.map((address, index) => (
          <ListItem
            key={index}
            sx={{
              color:"#000",
              background: selectedAddress === address ? "#9c7338" : "transparent",
              mb: 1,
              borderRadius: 1,
              transition: "background-color 0.3s ease",
            }}
            onClick={() => setSelectedAddress(address)}
          >
            <FormControlLabel
              value={address}
              control={<Radio sx={{ color: "#9c7338" }} />}
              label={<ListItemText primary={`${address.city}, ${address.district}, ${address.fullAddress.slice(0,20)}...`} />}
              sx={{ width: "100%" }}
            />
          </ListItem>
        ))}
      </List>
    </RadioGroup>
  ) : (
    <Typography sx={{color:"#000",fontFamily: "'Poppins', sans-serif",mb:2}}>Kayıtlı adresiniz bulunmamaktadır.</Typography>
  )}

  <Button
    variant="outlined"
    onClick={() => setOpen(true)}
    component={motion.button}
    whileTap={{ scale: 0.95 }}
    sx={{
      width: "100%",
      background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
      backgroundSize: "200% 100%", // Gradient genişliği artırıldı
      backgroundPosition: "right center", // Başlangıç pozisyonu
      color: "#fff",
      fontWeight: "bold",

      borderRadius: "8px", // Köşeleri yuvarlama
      padding: "10px 20px",
      textDecoration: "none",
      border: "none",
      cursor: "pointer",
      transition: "background-position 0.5s ease, transform 0.3s ease",
      "&:hover": {
        backgroundPosition: "left center", // Hover durumunda sola geçiş
        transform: "scale(1.01)", // Hafif büyüme efekti
        color: "#F0F8FF", // Daha parlak beyaz
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
      },
    }}
  >
    Adres Ekle
  </Button>
</Box>

        <Box mb={4} sx={{border:"2px solid #9c7338", p:3,borderRadius:"8px"}}>
        <Typography variant="h5" gutterBottom sx={{textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",}}>
        Kartlar
      </Typography>
      {cards.length > 0 ? (
        <RadioGroup
          value={selectedCard}
          onChange={(e) => handleCardSelect(e.target.value)}
        >
          <List>
            {cards.map((card, index) => (
              <ListItem
                key={index}
                sx={{
                  color: "#000",
                  background: selectedCard === `${JSON.stringify(card)}` ? "#9c7338" : "transparent",
                  mb: 1,
                  borderRadius: 1,
                  transition: "background-color 0.3s ease",
                }}
              >
                <FormControlLabel
                  value={JSON.stringify(card)}
                  control={<Radio sx={{ color: "#9c7338" }} />}
                  label={<ListItemText primary={`${card.firstName} ${card.lastName} ${card.cardNumber}`} />}
                  sx={{ width: "100%" }}
                  onChange={() => setSelectedCard(card)}
                />
              </ListItem>
            ))}
          </List>
        </RadioGroup>
      ) : (
        <Typography sx={{color:"#000",fontFamily: "'Poppins', sans-serif"}}>Lütfen Kart Giriniz.</Typography>
      )}
          <Button
            variant="outlined"
            onClick={handleOpen}
            component={motion.button}
            whileTap={{ scale: 0.95 }}
            sx={{
              width: "100%",
              background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
              backgroundSize: "200% 100%", // Gradient genişliği artırıldı
              backgroundPosition: "right center", // Başlangıç pozisyonu
              color: "#fff",
              fontWeight: "bold",
              mt:2,
              borderRadius: "8px", // Köşeleri yuvarlama
              padding: "10px 20px",
              textDecoration: "none",
              border: "none",
              cursor: "pointer",
              transition: "background-position 0.5s ease, transform 0.3s ease",
              "&:hover": {
                backgroundPosition: "left center", // Hover durumunda sola geçiş
                transform: "scale(1.01)", // Hafif büyüme efekti
                color: "#F0F8FF", // Daha parlak beyaz
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
              },
            }}
          >
            Kart Bilgilerini Gir
          </Button>
        </Box>
        <CardModal open={openCardModal} handleClose={handleClose} handleCardAdd={handleCardAdd} />
        <BillingAddressForm sameAsDelivery={sameAsDelivery} onSameAsDeliveryChange={setSameAsDelivery} onFormDataChange={handleFormDataChange} />
        <Paper
          elevation={3}
          sx={{
            mt: 4,
            p: 3,
            background: "transparent",
            boxShadow:"0",
            color: "white",
            border:"2px solid #9c7338",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h5" gutterBottom sx={{textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",}}>
            Sipariş Özeti
          </Typography>
          <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    overflow: "hidden",
    mb:2
  }}
>
  <Divider
    sx={{
      borderWidth: "2px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
</Box>
          <Box display="flex" justifyContent="space-between" sx={{color:"#000"}}>
            <Typography sx={{fontFamily: "'Poppins', sans-serif"}}>Ürünler</Typography>
            <Typography sx={{fontFamily: "'Poppins', sans-serif"}}>{totalAmount} TL</Typography>
          </Box> 
          {cartItems.length > 0 ? (
        cartItems.map((item, index) => (
          <Box key={index} display="flex" justifyContent="space-between" sx={{color:"#000",p:0,m:0}}>
            <Typography sx={{ fontFamily: "'Poppins', sans-serif",fontSize:"14px",color:"grey", }}>
              {item.name} - {item.quantity} adet
            </Typography>
            <Typography sx={{ fontFamily: "'Poppins', sans-serif",color:"grey",fontSize:"14px" }}>
            {item.quantity*item.price}TL
            </Typography>
          </Box>
        ))
      ) : (
        <Typography sx={{ fontFamily: "'Poppins', sans-serif" }}>
          Sepetinizde ürün bulunmamaktadır.
        </Typography>
      )}
          <Box display="flex" justifyContent="space-between" sx={{color:"#000"}} mb={2}>
  <Typography sx={{fontFamily: "'Poppins', sans-serif"}}>Kargo</Typography>
  {totalAmount >= 2000 ? (
    <Typography sx={{ textDecoration: "line-through", color: "gray",fontFamily: "'Poppins', sans-serif" }}>
      150 TL
    </Typography>
  ) : (
    <Typography sx={{fontFamily: "'Poppins', sans-serif"}}>150 TL</Typography>
  )}
</Box>

<Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    overflow: "hidden",
    mb:2
  }}
>
  <Divider
    sx={{
      borderWidth: "2px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
</Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6" sx={{ color: "#9c7338",fontFamily: "'Poppins', sans-serif" }}>
              Toplam (KDV dahil)
            </Typography>
            <Typography variant="h5" sx={{ color: "#9c7338", fontWeight: "bold",fontFamily: "'Poppins', sans-serif" }}>
            {totalPrice } TL
            </Typography>
          </Box>
          
        </Paper>

        <Box mt={3} textAlign="center">
          <Button
            variant="contained"
            component={motion.button}
            whileTap={{ scale: 0.95 }}
            sx={{
              width: "100%",
              background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
              backgroundSize: "200% 100%", // Gradient genişliği artırıldı
              backgroundPosition: "right center", // Başlangıç pozisyonu
              color: "#fff",
              fontWeight: "bold",
      
              borderRadius: "8px", // Köşeleri yuvarlama
              padding: "10px 20px",
              textDecoration: "none",
              border: "none",
              cursor: "pointer",
              transition: "background-position 0.5s ease, transform 0.3s ease",
              "&:hover": {
                backgroundPosition: "left center", // Hover durumunda sola geçiş
                transform: "scale(1.01)", // Hafif büyüme efekti
                color: "#F0F8FF", // Daha parlak beyaz
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
              },
            }}
            onClick={handleConfirmPayment}
            disabled={loading}
          >
             {loading ? "İşlem Devam Ediyor..." : "Ödeme Yap"} {/* Yükleniyor metni */}
          </Button>
          <Button
            variant="outlined"
            component={motion.button}
            whileTap={{ scale: 0.95 }}
            sx={{
              width: "100%",
              background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
              backgroundSize: "200% 100%", // Gradient genişliği artırıldı
              backgroundPosition: "right center", // Başlangıç pozisyonu
              color: "#fff",
              fontWeight: "bold",
              mt:2,
              borderRadius: "8px", // Köşeleri yuvarlama
              padding: "10px 20px",
              textDecoration: "none",
              border: "none",
              cursor: "pointer",
              transition: "background-position 0.5s ease, transform 0.3s ease",
              "&:hover": {
                backgroundPosition: "left center", // Hover durumunda sola geçiş
                transform: "scale(1.01)", // Hafif büyüme efekti
                color: "#F0F8FF", // Daha parlak beyaz
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
              },
            }}
            onClick={() => navigate("/sepetim")}
            disabled={loading}
          >
            Sepete Geri Dön
          </Button>
        </Box>
      </Box>

      <AddressModal
        open={open}
        onClose={() => setOpen(false)}
        cities={cities}
        city={city}
        setCity={setCity}
        district={district}
        setDistrict={setDistrict}
        newAddress={newAddress}
        setNewAddress={setNewAddress}
        onAddAddress={handleAddAddress}
      />
   {/* CVV Modal */}
   <Modal open={openModal} onClose={() => setOpenModal(false)}>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 300,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    }}
  >
    <Typography variant="h6" gutterBottom>
      CVV Bilgisi Girin
    </Typography>
    <TextField
      fullWidth
      type="password"
      label="CVV"
      required
      value={cvv}
      onChange={(e) => setCvv(e.target.value)}
      inputProps={{ maxLength: 3 }}
      sx={{ mb: 2 }}
      error={cvv === ""}
      helperText={cvv === "" ? "Bu alan boş bırakılamaz" : ""}
    />
    <Button
      variant="contained"
      color="primary"
      onClick={handleCvvSubmit}
      fullWidth
      disabled={cvv.length < 3} // CVV en az 3 karakter olmalı
    >
      Onayla
    </Button>
  </Box>
</Modal>
      <Footer />
    </Box>
  );
};

export default Payment;
