import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Box, Button, FormControl, InputLabel, Select, MenuItem,IconButton } from '@mui/material';
import useAuth from './useAuth.js';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const ShowForm = () => {
  useAuth();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('unread'); // Default filter for unread messages
  const navigate = useNavigate();
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/contactForm`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMessages(response.data.data);
      } catch (error) {
        console.error('Error fetching contact messages:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, []);

  const filteredMessages = messages.filter((message) => {
    if (filter === 'unread') return !message.okundu;
    if (filter === 'read') return message.okundu;
    return true; // 'all' filter
  });

  const markAsRead = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.patch(`http://localhost:5000/api/contactForm/${id}`, { okundu: true }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Güncellenen mesajı bul ve okundu olarak işaretle
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === id ? { ...message, okundu: true } : message
        )
      );
    } catch (error) {
      console.error('Error updating message status:', error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <Box sx={{ padding: '20px', fontFamily: 'Arial, sans-serif', backgroundColor:"#fff",mt:5 }}>
              <IconButton onClick={handleBackClick} style={{ marginRight: '10px' }}>
          <ArrowBackIcon />
        </IconButton>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Mesajlar
      </Typography>
      
      <FormControl fullWidth margin="normal">
        <InputLabel>Filtrele</InputLabel>
        <Select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          label="Filtrele"
        >
          <MenuItem value="unread">Okunmamış</MenuItem>
          <MenuItem value="read">Okunmuş</MenuItem>
          <MenuItem value="all">Tümü</MenuItem>
        </Select>
      </FormControl>

      {filteredMessages.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>ID</strong></TableCell>
                <TableCell><strong>Ad Soyad</strong></TableCell>
                <TableCell><strong>E-posta</strong></TableCell>
                <TableCell><strong>Konu</strong></TableCell>
                <TableCell><strong>Durum</strong></TableCell>
                <TableCell><strong>İşlem</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredMessages.map((message) => (
                <TableRow key={message.id} style={{ backgroundColor: message.okundu ? '#e0f7fa' : '#ffebee' }}>
                  <TableCell>{message.id}</TableCell>
                  <TableCell>{message.name}</TableCell>
                  <TableCell>{message.email}</TableCell>
                  <TableCell>{message.issue}</TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      style={{
                        color: message.okundu ? 'green' : 'red',
                        fontWeight: 'bold',
                      }}
                    >
                      {message.okundu ? 'Okundu' : 'Okunmamış'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {!message.okundu && (
                      <Button
                        variant="contained"
                        sx={{backgroundColor:"green"}}
                        onClick={() => markAsRead(message.id)}
                      >
                        Okundu
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">Mesaj Bulunamadı.</Typography>
      )}
    </Box>
  );
};

export default ShowForm;
