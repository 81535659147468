import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';

const WhatsAppButton = () => {
  const [visible, setVisible] = useState(true);
  const controls = useAnimation();

  
  // 8 saniyede bir titreşim efekti
  useEffect(() => {
    const interval = setInterval(() => {
      controls.start({
        scale: [1, 1.1, 0.9, 1.05, 1],
        transition: { duration: 0.6 },
      });
    }, 8000);

    return () => clearInterval(interval);
  }, [controls]);

  // Kapatma butonu için görünürlüğü kontrol et
  const handleClose = () => {
    setVisible(false);
  };
    // WhatsApp'a yönlendirme fonksiyonu
    const handleClick = () => {
      const phoneNumber = "+905492211095"; // WhatsApp numarasını buraya yazın
      const message = ""; // Mesaj içeriği
      const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank'); // Yeni sekmede WhatsApp açılacak
    };
  if (!visible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 100,
      }}
    >
      {/* Kapatma Butonu */}
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: -5,
          right: -5,
          backgroundColor: '#25D366',
          color: '#fff',
          width: 20,
          zIndex: 101,
          height: 20,
          borderRadius: '50%',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          '&:hover': { backgroundColor: '#075E54' },
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      {/* WhatsApp Butonu */}
      <motion.div
        initial={{ scale: 1 }}
        animate={controls}
        whileHover={{
          scale: 1.2, // Hover büyütme efekti
          rotate: 360,
        }}
        onHoverEnd={() => controls.start({ scale: 1 })} // Hover sonrası geri dönüş
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 10,
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#25D366',
          color: '#fff',
          borderRadius: '50%',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
          cursor: 'pointer',
          height: 60,
          width: 60,
        }}
        onClick={handleClick}
      >
        <WhatsAppIcon fontSize="large" />
      </motion.div>
    </Box>
  );
};

export default WhatsAppButton;
