import React from "react";
import {
  Box,
  Typography,
  Divider,
  Slider,

  TextField,
  InputAdornment
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
const FilterSidebar = ({ filters, onFilterChange, searchQuery, handleSearchChange }) => {
  const handleCategoryChange = (category) => {
    onFilterChange({ ...filters, category });
  };

  const handlePriceRangeChange = (event, newValue) => {
    onFilterChange({ ...filters, priceRange: newValue });
  };

  return (
    <>
      <Typography variant="body2" sx={{fontFamily: "'Sansita', sans-serif",  cursor: "pointer",  fontSize: {xs: "14px", sm: "22px" }, color: filters.category === "" ? "#9c7338" : "black",  // Seçili kategori rengini değiştirme
        "&:hover": { color: "#9c7338" },textAlign:"center",my:0,py:0, }} onClick={() => handleCategoryChange("")} gutterBottom>
        ÜRÜNLERİMİZ
      </Typography>
      <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    overflow: "hidden",
  }}
>
  <Divider
    sx={{
      borderWidth: "2px",
      borderStyle: "solid",
      width: "60%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
      mb:2
    }}
  />
</Box>
<Box
  sx={{
    flex: 1,
    display: "flex",
    flexDirection: { xs: "column", sm: "column" },
    textAlign: "center",  // Ortalamak için
    mb: { xs: 2, sm: 2 },
  }}
>  
  {/* Kategoriler Listesi */}
  <Box  sx={{ display: {xs:"block",md:"flex"}, flexDirection: "column", alignItems: "center", gap: 2 }}>

    <Typography
      sx={{ 
        fontFamily: "'Sansita', sans-serif",
        cursor: "pointer", 
        fontSize: {xs: "14px", sm: "22px" },
        color: filters.category === "sucuk" ? "#9c7338" : "black",  // Seçili kategori rengini değiştirme
        "&:hover": { color: "#9c7338" },
      }}
      onClick={() => handleCategoryChange("sucuk")}
    >
      SUCUK
    </Typography>
    <Typography
      sx={{ 
        fontFamily: "'Sansita', sans-serif",
        cursor: "pointer", 
        fontSize: {xs: "14px", sm: "22px" },
        color: filters.category === "pastırma" ? "#9c7338" : "black",  // Seçili kategori rengini değiştirme
        "&:hover": { color: "#9c7338" },
      }}
      onClick={() => handleCategoryChange("pastirma")}
    >
      PASTIRMA
    </Typography>
    <Typography
      sx={{ 
        fontFamily: "'Sansita', sans-serif",
        cursor: "pointer", 
        fontSize: {xs: "14px", sm: "22px" },
        color: filters.category === "salam" ? "#9c7338" : "black",  // Seçili kategori rengini değiştirme
        "&:hover": { color: "#9c7338" },
      }}
      onClick={() => handleCategoryChange("salam")}
    >
      SALAM
    </Typography>
    <Typography
      sx={{ 
        fontFamily: "'Sansita', sans-serif",
        cursor: "pointer", 
        fontSize: {xs: "14px", sm: "22px" },
        color: filters.category === "kavurma" ? "#9c7338" : "black",
        "&:hover": { color: "#9c7338" },
      }}
      onClick={() => handleCategoryChange("kavurma")}
    >
      KAVURMA
    </Typography>
  </Box>
</Box>
<TextField
  fullWidth
  sx={{
    borderRadius: "20px", 
    backgroundColor: "#bcbcbc",
    mb:2,
    "& .MuiInputBase-root": {
      padding: "-4px 4px",  
      fontSize: "14px", 
      height: "40px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",  
      },
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none", 
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    
  }}
  placeholder="Ürün ara..."
  value={searchQuery}
  onChange={handleSearchChange}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon sx={{ fontSize: "18px",color:"#fff" }} />
      </InputAdornment>
    ),
  }}
/>
<Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    overflow: "hidden",
    mb:2
  }}
>
  <Divider
    sx={{
      borderWidth: "2px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
</Box>
      {/* Kategori ve Fiyat Aralığı Yan Yana ve Alt Alta */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column" },
          justifyContent: "space-between",
          gap: 2, 
        }}
      >
        {/* Fiyat Aralığı */}
        <Box
          sx={{
            flex: 1,

          }}
        >
           <Typography variant="body1" sx={{fontFamily: "'Poppins', sans-serif", mb: 0.5,ml:2, color: "black", fontSize: { xs: "12px", sm: "18px" } }}>
        Fiyat
      </Typography>
      <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    overflow: "hidden",
    mb:1
  }}
>
  <Divider
    sx={{
      borderWidth: "1px",
      borderStyle: "solid",
      width: "90%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
</Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          alignItems: "center",
          flexDirection: { xs: "column", sm: "column", xl: "row" },
        }}
      >
        {/* Fiyat Aralığı Çubuğu */}
        <Box sx={{ width: "80%", maxWidth: "300px" }}>
        <Slider
  value={filters.priceRange}
  onChange={handlePriceRangeChange}
  valueLabelDisplay="auto"
  valueLabelFormat={(value) => `₺${value}`}
  min={0}
  max={3000} // Fiyat aralığının üst sınırını buradan ayarlayabilirsiniz
  step={10}
  sx={{
    color: "#9c7338",
    "& .MuiSlider-thumb": {
      backgroundColor: "#9c7338",  // Yuvarlak köşe rengi beyaz
      width: 16,
      height: 16,
    },
    "& .MuiSlider-track": {
      background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)", // Orta çizgi için gradyan renk
    },
    "& .MuiSlider-rail": {
      backgroundColor: "#9c7338", // Ray çizgisi

    },
  }}
/>

        </Box>
      </Box>

        </Box>
      </Box>
    </>
  );
};

export default FilterSidebar;
