import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { motion } from "framer-motion";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FactoryIcon from "@mui/icons-material/Factory";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyLocationIcon from '@mui/icons-material/MyLocation';

const ContactDetails = () => {
  return (
    <Box
      sx={{
        borderRadius: "10px",
        textAlign: "center",
        mb: 4,
      }}
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              textAlign: "left",
              background:
                "transparent",
                boxShadow:"0",
                border:"2px solid #9c7338",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", display: "flex",fontFamily: "'Sansita', sans-serif", alignItems: "center",color:"#000", gap: 1 }}>
              <AccessTimeIcon /> Çalışma Saatleri
            </Typography>
            <Typography sx={{my:1,fontFamily: "'Poppins', sans-serif"}}>Hafta içi: 08:45 - 18:00</Typography>
            <Typography sx={{fontFamily: "'Poppins', sans-serif"}}>Cumartesi: 8:45 - 18:00</Typography>
            <Typography sx={{my:1,fontFamily: "'Poppins', sans-serif"}}>Pazar: Kapalı</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              textAlign: "left",
              background:
              "transparent",
              boxShadow:"0",
              border:"2px solid #9c7338",
            }}
          >
            <Typography variant="h6" sx={{ ontWeight: "bold", display: "flex",fontFamily: "'Sansita', sans-serif", alignItems: "center",color:"#000", gap: 1  }}>
              <FactoryIcon /> İletişim
            </Typography>
            <Typography sx={{ display: "flex",my:1, alignItems: "center", gap: 1,fontFamily: "'Poppins', sans-serif" }}>
              <PhoneIcon /> Telefon: 0 (272) 221 1095 | 0 (850) 420 6 925
            </Typography>
            <Typography sx={{ display: "flex", alignItems: "center", gap: 1,fontFamily: "'Poppins', sans-serif" }}>
              <EmailIcon /> Email: info@ozbeksucuklari.com.tr
            </Typography>
            <Typography sx={{ display: "flex",my:1, alignItems: "center", gap: 1,fontFamily: "'Poppins', sans-serif" }}>
              <LocationOnIcon /> Adres: 1. Cadde, 4. Sokak, No:5, Afyon Organize Sanayi Bölgesi, Merkez/Afyonkarahisar
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={2}
            sx={{
              p: 2,
              background:
                "transparent",
                boxShadow:"0",
                border:"2px solid #9c7338",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold",textAlign:"center",justifyContent:"center", color: "#000",display: "flex",my:1, alignItems: "center", gap: 1,fontFamily: "'Sansita', sans-serif", }}
            >
              <MyLocationIcon /> Adres Haritası
            </Typography>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1849.098458989919!2d30.56523372192463!3d38.79120688087743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cf17c4b8819fbb%3A0xa1eb298150ed1e4a!2zw5Z6YmVrIFN1Y3VrbGFyxLEgw5xyZXRpbSBUZXNpc2k!5e0!3m2!1str!2str!4v1738015182960!5m2!1str!2str" 
              width="100%"
              height="400"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              title="ÖZBEK SUCUKLARI"
            ></iframe>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactDetails;
