import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { getOrdersByUserId } from "../services/accountService";  // accountService import

const getStatusDetails = (status) => {
  switch (status) {
    case 3:
      return {
        color: "#0f430f", // Yeşil
        text: "Teslim Edildi"
      };
    case 2:
      return {
        color: "#1C427A", // Turuncu
        text: "Hazırlanıyor"
      };
    default:
      return {
        color: "#BDBDBD", // Gri
        text: "Onay Bekleniyor"
      };
  }
};

const Orders = ({ id }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Kullanıcı id'sine göre siparişleri çek
    const fetchOrders = async () => {
      try {
        const data = await getOrdersByUserId(id, token);
        setOrders(data);
        console.log(data);
        setLoading(false);
      } catch (err) {
        setError("Sipariş Bulunamadı.");
        setLoading(false);
      }
    };

    fetchOrders();
  }, [id, token]); // token da bağımlılıklar arasında

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (loading) {
    return <Typography>Yükleniyor...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography variant="h5" gutterBottom>
        Siparişlerim
      </Typography>
      {orders.length === 0 ? (
        <Typography variant="h6" color="textSecondary">
          Sipariş bulunamadı
        </Typography>
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
          {orders.map((order) => {
            const { color, text } = getStatusDetails(order.status); // Burada status kontrolü yapılır
            return (
              <Card
                key={order.id}
                variant="outlined"
                sx={{
                  backgroundColor: color, // Rengi doğru şekilde alıyoruz
                  color: "white",
                }}
              >
                <CardContent>
                  <Typography variant="subtitle1">
                    Sipariş Numarası: {order.id}
                  </Typography>
                  <Typography>Tarih: {formatDate(order.order_date)}</Typography>
                  <Typography>Toplam Fiyat: {order.total_price}</Typography>
                  <Typography>Durum: {text}</Typography>
                </CardContent>
              </Card>
            );
          })}
        </Box>
      )}
    </motion.div>
  );
};

export default Orders;
