
import axios from 'axios';

export const getUserInfo = async (token, userId) => {
    try {
      // userId'yi URL'ye dahil ediyoruz
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Token'ı Authorization header'ında gönderiyoruz
        },
      });
      return { ...response.data, userId };  // Backend'den gelen veriyi döndürüyoruz
    } catch (error) {
      console.error("Kullanıcı bilgileri alınırken hata oluştu:", error);
      throw error; // Hata durumunda hatayı fırlatıyoruz
    }
  };

  export const updateUserInfo = async (email, phone,userId) => {
    const token = localStorage.getItem("token");
  
    if (!token) {
      throw new Error("Kullanıcı oturumu açmamış.");
    }
  
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/user/${userId}`,
        { email, phone,userId },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Token'ı ekliyoruz
          },
        }
      );
  
      if (response.status === 200) {
        return response.data; // Güncellenmiş verileri dönebiliriz
      } else {
        throw new Error("Bilgiler güncellenirken bir hata oluştu.");
      }
    } catch (error) {
      console.error("Error updating user info", error);
      throw error; // Hata fırlatıyoruz, UI'da yakalayabiliriz
    }
  };  


  export const getAddresses = async (token, id) => {
    try {
      // userId'yi URL'ye dahil ediyoruz
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/address/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Token'ı Authorization header'ında gönderiyoruz
        },
      });
      return { ...response.data};  // Backend'den gelen veriyi döndürüyoruz
    } catch (error) {
      console.error("Kullanıcı bilgileri alınırken hata oluştu:", error);
      throw error; // Hata durumunda hatayı fırlatıyoruz
    }
  };

  export const deleteAddress = async (token, addressId) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/address/${addressId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  
    if (!response.ok) {
      throw new Error('Adres silinemedi');
    }
  
    return response.json();
  };
  
  export const getOrdersByUserId = async (userId, token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`, // Token'ı Authorization başlığında gönderiyoruz
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching orders:', error);
      throw error;
    }
  };
  export const getAllOrdersForAdmin = async (token) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/admin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Siparişleri döndürüyoruz
    } catch (error) {
      console.error("Siparişleri çekerken hata oluştu:", error);
      throw error; // Hata durumunda hatayı yükseltiyoruz
    }
  };
  
  export const updateOrderStatus = async (orderId, newStatus, token) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/orders/${orderId}`,
        { status: newStatus }, // Body içinde gönderilecek veri
        {
          headers: {
            Authorization: `Bearer ${token}`, // Token başlığı
          },
        }
      );
  
      return response.data; // Güncellenen sipariş bilgisi döner
    } catch (error) {
      console.error('Durum güncelleme hatası:', error);
      throw error; // Hata durumunda çağıran taraf hatayı yakalayabilir
    }
  };
  
  