import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Button } from '@mui/material';
import { motion } from 'framer-motion'; // Framer Motion'ı içeri aktarın
import useAuth from './useAuth.js';

const AdminPanel = ({ onShowNotification }) => {
  useAuth(); // Erişim kontrolü burada yapılır.
  const navigate = useNavigate();
  const [activeBoxIndex, setActiveBoxIndex] = useState(null);

  // Sayfa adları ve yönlendirme yolları
  const boxData = [
    { name: 'Ürünler', routes: { add: '/ProductAdd', edit: '/ProductEdit' } },
    { name: 'PDF', routes: { add: '/AdminPDF' }, single: true },
    { name: 'İletişim formu', routes: { add: '/ShowForm' }, single: true },
    { name: 'Siparişler', routes: { add: '/Orders' }, single: true },
    { name: 'Ürün Detay', routes: { add: '/ProductDetailEdit' }, single: true },
  ];

  // Aktif kutu seçimi işlemi
  const handleBoxClick = (index) => {
    setActiveBoxIndex(activeBoxIndex === index ? null : index);
  };

  const handleAddClick = (index) => {
    if (boxData[index].routes.add) {
      navigate(boxData[index].routes.add);
    } else {
      console.error('Add route is missing for this box.');
    }
  };

  const handleEditClick = (index) => {
    if (boxData[index].routes.edit) {
      navigate(boxData[index].routes.edit);
    } else {
      console.error('Edit route is missing for this box.');
    }
  };

  // Yeni butonlar için yönlendirme
  const handleHomePageClick = () => {
    navigate('/');
  };

  const handleAccountPageClick = () => {
    navigate('/hesabim');
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          bgcolor: 'rgba(0, 0, 0, 0.5)',
          padding: 2,
        }}
      >
        <Typography variant="h2" component="h1" sx={{ mb: 4, color: '#fff' }}>
          Admin Panel
        </Typography>

        {/* Yeni Butonlar */}
        <Box sx={{ mb: 3 }}>
          <Button
            variant="contained"
            onClick={handleHomePageClick}
            sx={{
              bgcolor: 'rgba(76, 175, 80, 0.8)',
              border: '1px solid #4CAF50',
              margin: '0 5px',
              animation: 'fadeInMove 0.5s ease-out',
            }}
          >
            Ana Sayfaya Git
          </Button>
          <Button
            variant="contained"
            onClick={handleAccountPageClick}
            sx={{
              bgcolor: 'rgba(33, 150, 243, 0.8)',
              border: '1px solid #2196F3',
              margin: '0 5px',
              animation: 'fadeInMove 0.5s ease-out',
            }}
          >
            Hesabım
          </Button>
        </Box>

        {/* Sayfa Kutuları */}
        <Grid container spacing={2} justifyContent="center">
          {boxData.map((box, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <motion.div
                onClick={() => handleBoxClick(index)}
                whileHover={{ scale: 1.05 }} // Hover animasyonu
                transition={{ duration: 0.3 }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: 100,
                    bgcolor: 'rgba(0, 0, 0, 0.3)',
                    borderRadius: 2,
                    boxShadow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #ccc',
                    cursor: 'pointer',
                  }}
                >
                  <Typography variant="h6" sx={{ color: '#fff', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)' }}>
                    {box.name}
                  </Typography>
                </Box>
              </motion.div>
              {activeBoxIndex === index && (
                <Box
                  sx={{
                    marginTop: 1,
                    display: 'flex',
                    justifyContent: box.single ? 'center' : 'space-around',
                  }}
                >
                  {box.single ? (
                    <Button
                      variant="contained"
                      onClick={() => handleAddClick(index)} // Hem "Ekle" hem de "Görüntüle" işlemi burada yapılacak
                      sx={{
                        bgcolor: 'rgba(25, 118, 210, 0.8)',
                        border: '1px solid #1976D2',
                        flex: 1,
                        margin: '0 5px',
                        animation: 'fadeInMove 0.5s ease-out',
                      }}
                    >
                      {box.name === 'PDF' ? 'Düzenle' :box.name === 'Ürün Detay' ? 'Düzenle' :box.name === 'Siparişler' ? 'Görüntüle' : box.name === 'İletişim formu' ? 'Görüntüle' : 'Ekle'}
                    </Button>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        onClick={() => handleAddClick(index)}
                        sx={{
                          bgcolor: 'rgba(25, 118, 210, 0.8)',
                          border: '1px solid #1976D2',
                          flex: 1,
                          margin: '0 5px',
                          animation: 'fadeInMove 0.5s ease-out',
                        }}
                      >
                        Ekle
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleEditClick(index)}
                        sx={{
                          flex: 1,
                          bgcolor: 'rgba(156, 39, 176, 0.8)',
                          border: '1px solid #9C27B0',
                          margin: '0 5px',
                          animation: 'fadeInMove 0.5s ease-out',
                        }}
                      >
                        Düzenle
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </Grid>
          ))}
        </Grid>

        {/* Animasyon Tanımları */}
        <style>
          {`
            @keyframes fadeInMove {
              0% {
                opacity: 0;
                transform: translateY(20px);
              }
              100% {
                opacity: 1;
                transform: translateY(0);
              }
            }

            @keyframes slideFadeIn {
              0% {
                transform: translateY(50px);
              }
              100% {
                transform: translateY(0);
              }
            }
          `}
        </style>
      </Box>
    </>
  );
};

export default AdminPanel;
