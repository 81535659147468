import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Checkbox, FormControlLabel, Button, Grid, Typography, Paper,IconButton,InputLabel,Select,MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth.js';
import { useNotification } from "../../components/NotificationContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const ProductAdd = () => {
  useAuth();
  const [productData, setProductData] = useState({
    description: '',
    name: '',
    price: '',
    in_stock: false,
    sort: "",
  });

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState('');
  const [error, setError] = useState('');
  const [productDetailData, setProductDetailData] = useState({
    product_id: '',
    enerji_kcal: '',
    protein: '',
    yag: '',
    doymus_yag: '',
    karbonhidrat: '',
    seker: '',
    tuz: '',
    koli_agirligi: '',
    koli_ici_adet: '',
    raf_omru: '',
  });
  const showNotification = useNotification();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDetailChange = (e) => {
    const { name, value } = e.target;
    setProductDetailData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setProductData((prevData) => ({
      ...prevData,
      in_stock: !prevData.in_stock,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(file); 
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setError(''); 
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!image) {
      setError('Lütfen bir fotoğraf yükleyin.');
      return;
    }
  
    setLoading(true);
    const token = localStorage.getItem('token');
  
    const formData = new FormData();
    // Ürün verileri
    formData.append('name', productData.name);
    formData.append('description', productData.description);
    formData.append('price', productData.price);
    formData.append('in_stock', productData.in_stock);
    formData.append('category', productData.category);
    formData.append('sort', productData.sort);
  
    if (image) {
      formData.append('photo_url', image);
    }
  
    // Ürün detay verileri
    Object.keys(productDetailData).forEach((key) => {
      formData.append(key, productDetailData[key]);
    });
  
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/product`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      showNotification('Ürün Kayıtı Başarılı!', 'success');
      localStorage.setItem('notificationMessage', 'Ürün Kayıtı Başarılı!');
      localStorage.setItem('notificationSeverity', 'success');
      setLoading(false);
  
      setTimeout(() => {
        navigate('/AdminPanel');
      });
    } catch (error) {
      showNotification('Ürün Kayıtı Başarısız!', 'error');
      setLoading(false);
    }
  };
  
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{mt:5}} >
      <Grid item xs={12} sm={10} md={10}>
        <Paper elevation={5} style={{ padding: '30px', borderRadius: '15px', backgroundColor: '#ffffff' }}>
        <IconButton onClick={handleBackClick} style={{ marginRight: '10px' }}>
        <ArrowBackIcon />
      </IconButton>
          <Typography variant="h4" gutterBottom align="center" style={{ fontWeight: 600, color: '#3f51b5' }}>
            Yeni ürün ekle
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Ürün ismi"
              name="name"
              value={productData.name}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
<TextField
  fullWidth
  label="Açıklama"
  name="description"
  value={productData.description}
  onChange={handleChange}
  multiline
  minRows={4}
  maxRows={6}
  variant="outlined"
  style={{
    marginBottom: '15px',
    borderRadius: '4px',
    fontSize: '16px',
  }}
/>
            <TextField
              fullWidth
              label="Ücret"
              name="price"
              value={productData.price}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />

        <InputLabel id="category-label">Kategori</InputLabel>
        <Select
        fullWidth
          labelId="category-label"
          name="category"
          value={productData.category}
          onChange={handleChange}
          label="Kategori"
        >
          <MenuItem value="sucuk">Sucuk</MenuItem>
          <MenuItem value="pastirma">Pastırma</MenuItem>
          <MenuItem value="salam">Salam</MenuItem>
          <MenuItem value="kavurma">Kavurma</MenuItem>
        </Select>
        <TextField
              fullWidth
              label="Ürün Sırası"
              name="sort"
              value={productData.sort}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={productData.in_stock}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label="Stokta var mı"
              style={{ marginBottom: '20px' }}
            />
            <div style={{ marginBottom: '20px' }}>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="upload-image"
              />
              <label htmlFor="upload-image">
                <Button variant="contained" color="secondary" component="span">
                  Fotoğraf Yükle
                </Button>
              </label>
              <div style={{ marginTop: '10px', backgroundColor: 'grey', padding: '10px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
  <Typography variant="h5" style={{ fontWeight: 'bold', color: '#fff', textAlign: "center" }}>
    Seçtiğiniz Fotoğraf
  </Typography>
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
    {imagePreview ? (
      <img
        src={imagePreview}
        alt="Preview"
        style={{
          maxWidth: '100%',
          maxHeight: '300px',
          objectFit: 'contain',
          borderRadius: '8px',
          border: '2px solid gold',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      />
    ) : (
<Typography
  variant="body2"
  style={{
    color: '#fff',
    textAlign: 'center',
    fontStyle: 'italic',
    height: '300px',
    width: '200px',
    border: '2px solid gold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  Fotoğraf seçilmedi.
</Typography>
    )}
  </div>
</div>

            </div>
            {error && <Typography color="error" variant="body2">{error}</Typography>} {/* Hata mesajı */}
            <TextField
              fullWidth
              label="Enerji (kcal)"
              name="enerji_kcal"
              onChange={handleDetailChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <TextField
              fullWidth
              label="Protein (g)"
              name="protein"
              onChange={handleDetailChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <TextField
              fullWidth
              label="Yağ (g)"
              name="yag"
              onChange={handleDetailChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <TextField
              fullWidth
              label="Doymuş Yağ (g)"
              name="doymus_yag"
              onChange={handleDetailChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <TextField
              fullWidth
              label="Karbonhidrat (g)"
              name="karbonhidrat"
              onChange={handleDetailChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <TextField
              fullWidth
              label="Şeker (g)"
              name="seker"
              onChange={handleDetailChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <TextField
              fullWidth
              label="Tuz (g)"
              name="tuz"
              onChange={handleDetailChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <TextField
              fullWidth
              label="Koli Ağırlığı (kg)"
              name="koli_agirligi"
              onChange={handleDetailChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <TextField
              fullWidth
              label="Koli İçindeki Adet"
              name="koli_ici_adet"
              onChange={handleDetailChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <TextField
              fullWidth
              label="Raf Ömrü (gün)"
              name="raf_omru"
              onChange={handleDetailChange}
              margin="normal"
              variant="outlined"
              style={{ marginBottom: '15px' }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              style={{
                marginTop: '20px',
                padding: '12px',
                fontWeight: 600,
                transition: 'background-color 0.3s',
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = '#303f9f')}
              onMouseOut={(e) => (e.target.style.backgroundColor = '#3f51b5')}
            >
              {loading ? "Kayıt Yapılıyor..." : "Ürünü Ekle"}
            </Button>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProductAdd;
