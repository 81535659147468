import { motion } from "framer-motion";
import { Box } from "@mui/material";

const LogoAnimation = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        padding: 2, // Küçük ekranlarda kenarlardan taşmayı önler
      }}
    >
      <motion.img
        src="/ozbek-logo.png"
        alt="Özbek Logo"
        initial={{ clipPath: "inset(100% 0% 0% 0%)", opacity: 0 }}
        animate={{ clipPath: "inset(0% 0% 0% 0%)", opacity: 1 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
        style={{
          width: "100%", // Responsive genişlik
          maxWidth: "400px", // Maksimum genişlik belirterek büyük ekranlarda boyutu sınırlama
          height: "auto", // Yüksekliği orantılı ayarla
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

export default LogoAnimation;
