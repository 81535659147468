import React, { useState } from "react";
import { Box, Grid, Paper, Modal, IconButton, Typography } from "@mui/material";
import { motion } from "framer-motion";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
import ShoppingCart from "../components/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";

const Documents = () => {
  const documents = [
    { id: 1, image: "/HELAL.jpg" },
    { id: 2, image: "/ISO9001.jpg" },
    { id: 3, image: "/ISO10002.jpg" },
    { id: 4, image: "/ISO22000.jpg" },
  ];

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        padding: "0",
        margin: "0",
      }}
    >
      <Navbar />

      <Box
      sx={{width:{xs:"90%",md:"95%"},mx:"auto"}}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Typography variant="h2" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif", }}>
              BELGELERİMİZ
            </Typography>
          </motion.div>
        </Box>
      <main>
        <Box sx={{ p: 3 }}>
          <Grid container spacing={3}>
            {documents.map((doc) => (
              <Grid item xs={12} sm={6} md={4} key={doc.id}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      p: 2,
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                      boxShadow:"0",
                      cursor: "pointer", // Makes it clear it's clickable
                    }}
                    onClick={() => handleOpen(doc.image)} // Open modal on click
                  >
                    <img
                      src={doc.image}
                      alt={`document-${doc.id}`}
                      style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                    />
                  </Paper>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Modal for image enlargement */}
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              padding: 0.5,
              maxWidth: "100%",
              maxHeight: "100%",
              overflow: "hidden",
              borderRadius: 2,
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "#1C427A",
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={selectedImage}
              alt="Selected"
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "90vh",
                objectFit: "contain",
              }}
            />
          </Box>
        </Modal>

        <ShoppingCart />
        <Footer />
      </main>
    </div>
  );
};

export default Documents;
