import React, { useState,useEffect } from "react";
import { Box, Tabs, Tab, Typography, Container,Button  } from "@mui/material";
import { motion } from "framer-motion";
import UserInfo from "../components/UserInfo";
import Addresses from "../components/Addresses";
import Footer from "../components/Footer"; 
import Navbar from "../components/navbar"; 
import ShoppingCart from "../components/ShoppingCart"; 
import Orders from "../components/Orders";
import { Person, Home, LocalShipping } from "@mui/icons-material";
import { useAuth } from '../auth/AuthContext';
import { useNotification } from "../components/NotificationContext";
import { getUserInfo } from '../services/accountService';
function AccountPage() {
  const [tabIndex, setTabIndex] = useState(0);
  const { logout } = useAuth();
  const showNotification = useNotification();
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const handleLogout = () => {
    try {
      logout();
      showNotification("Çıkış başarılı!", "success");
  

      localStorage.setItem("notificationMessage", "Çıkış başarılı!");
      localStorage.setItem("notificationSeverity", "success");
  
      window.location.href = "/";
    } catch (error) {
      console.error("Çıkış hatası:", error);
      showNotification("Hata oluştu, lütfen tekrar deneyin.", "error");
    }
  };
  const [userInfo, setUserInfo] = useState({ full_name: "", email: "", phone: "" });
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null); // Kullanıcı ID'sini state'de saklıyoruz
  const token = localStorage.getItem('token'); // Token'ı localStorage'dan alıyoruz

  // JWT decode işlemini gerçekleştiren fonksiyon
  function decodeJWT(token) {
    const base64Url = token.split('.')[1]; // Token'ın ikinci parçası payload'dur
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Base64 formatını düzelt
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload); // JSON formatında decode edilmiş veriyi döndür
  }

  // `token` değiştiğinde `userId`'yi decode et ve state'e set et
  useEffect(() => {
    if (token) {
      const decodedUserId = decodeJWT(token); // Token'dan kullanıcı ID'sini decode ediyoruz
      setUserId(decodedUserId.id); // Kullanıcı ID'sini state'e kaydediyoruz
    }
  }, [token]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (userId && token) {
        setLoading(true);
        try {
          const data = await getUserInfo(token, userId); // accountService'den veri alıyoruz
          setUserInfo(data); // Veriyi state'e set ediyoruz
        } catch (error) {
          console.error("Kullanıcı bilgileri alınırken hata oluştu:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    if (userId) { // `userId` kontrolü yaparak sadece mevcut olduğunda veri çekme işlemi yapıyoruz
      fetchUserInfo();
    }
  }, [userId, token]);

  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Alışveriş Sepeti */}
      <ShoppingCart />

      <Box
      sx={{width:{xs:"90%",md:"75%"},mx:"auto"}}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Typography variant="h2" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif", }}>
              HESABIM
            </Typography>
          </motion.div>
        </Box>
      {/* Kullanıcı Hesabı Ana İçerik */}
      <Container sx={{mb:4}}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >

<Tabs
      value={tabIndex}
      onChange={handleTabChange}
      centered
      sx={{
        borderRadius: 300, // Sekmeler için köşe yumuşatma
        border: '2px solid #9c7338', // Border kalınlığını belirliyoruz

        "& .MuiTabs-indicator": {
          height: "2px", // Gösterge kalınlığı
          backgroundColor: "#9c7338", // Gösterge rengi
        },
      }}
      textColor="inherit" // Varsayılan metin rengini iptal eder
    >
      <Tab
        icon={<Person />}
        aria-label="Bilgiler"
        sx={{

          "&.Mui-selected": {
            color: "#9c7338", // Seçili metin rengi
          },
        }}
      />
      <Tab
        icon={<Home />}
        aria-label="Adresler"
        sx={{

          "&.Mui-selected": {
            color: "#9c7338", // Seçili metin rengi
          },
        }}
      />
      <Tab
        icon={<LocalShipping />}
        aria-label="Siparişlerim"
        sx={{

          "&.Mui-selected": {
            color: "#9c7338", // Seçili metin rengi
          },
        }}
      />
    </Tabs>

          <Box sx={{ mt: 3 }}>
            {tabIndex === 0 && <UserInfo userInfo={userInfo} loading={loading} />}
            {tabIndex === 1 && <Addresses id={userInfo.userId}/>}
            {tabIndex === 2 && <Orders id={userInfo.userId} />}
          </Box>
        </motion.div>
      </Container>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Button
          variant="contained"
          onClick={handleLogout}
          sx={{

            background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
            backgroundSize: "200% 100%", // Gradient genişliği artırıldı
            backgroundPosition: "right center", // Başlangıç pozisyonu
            color: "#fff",
            fontWeight: "bold",

            borderRadius: "8px", // Köşeleri yuvarlama
            padding: "10px 20px",
            textDecoration: "none",
            border: "none",
            cursor: "pointer",
            transition: "background-position 0.5s ease, transform 0.3s ease",
            "&:hover": {
              backgroundPosition: "left center", // Hover durumunda sola geçiş
              transform: "scale(1.01)", // Hafif büyüme efekti
              color: "#F0F8FF", // Daha parlak beyaz
              boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
            },
          }}
        >
          Çıkış Yap
        </Button>
      </Box>
      {/* Footer */}
      <Footer />
    </>
  );
}

export default AccountPage;
