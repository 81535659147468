import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch } from "react-redux"; // useDispatch'ı ekliyoruz
import { clearCart } from "../redux/cartSlice"; // clearCart action'ını ekliyoruz

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch(); // dispatch fonksiyonunu alıyoruz

  // localStorage'dan değerleri okuma
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn") === "true");
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole") || "");

  // localStorage'dan son giriş zamanını okuma
  const storedLoginTime = localStorage.getItem("loginTime");
  const currentTime = new Date().getTime();
  const oneDay = 23 * 60 * 60 * 1000; // 1 gün (24 saat)

  // Eğer 1 günden fazla zaman geçmişse, verileri sıfırlıyoruz
  useEffect(() => {
    if (storedLoginTime && currentTime - storedLoginTime > oneDay) {
      logout(); // 1 günden fazla zaman geçtiyse çıkış yap
    }
  }, [storedLoginTime, currentTime, oneDay]);

  useEffect(() => {
    // Eğer kullanıcı giriş yaptıysa ve rolü varsa, localStorage'a kaydet
    if (isLoggedIn) {
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("userRole", userRole);
      localStorage.setItem("loginTime", currentTime); // Giriş zamanını kaydet
    } else {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("userRole");
      localStorage.removeItem("loginTime");
    }
  }, [isLoggedIn, userRole, currentTime]);

  const login = (role) => {
    setIsLoggedIn(true);
    setUserRole(role); // Rolü güncelle
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUserRole(""); // Rolü sıfırla
    localStorage.removeItem("isLoggedIn"); // localStorage'dan çıkışı sil
    localStorage.removeItem("userRole"); // localStorage'dan rolü sil
    localStorage.removeItem("loginTime"); // localStorage'dan giriş zamanını sil
    localStorage.removeItem("token"); // localStorage'dan token'ı sil
    localStorage.setItem("cookieAccepted", "false");
    // Redux store'daki ürünleri temizle
    dispatch(clearCart()); // Ürünleri sıfırlıyoruz
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, userRole, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
