import React, { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Card,Snackbar, CardMedia, CardContent, Typography, Button, IconButton,Alert ,Modal } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import StarIcon from "@mui/icons-material/Star";
import ProductFeatures from "../components/ProductFeatures";
import ProductReviews from "../components/ProductReviews";
import Navbar from "../components/navbar";
import { useDispatch } from "react-redux";
import { addToCart } from "../redux/cartSlice";
import Footer from '../components/Footer';
import ShoppingCart from "../components/ShoppingCart";
import { motion } from "framer-motion";
import { fetchProductById } from "../services/productService";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
const ProductDetails = () => {
  const { productName } = useParams();
  const [product, setProduct] = useState(null);
  const [productRating, setProductRating] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0);
  const dispatch = useDispatch();
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
  
    return (
      <div
      style={{
        display: "flex",
        gap: "5px",
      }}
    >
      <button
        onClick={() => zoomIn()}
        style={{
          backgroundColor: "#9c7338",
          color: "#fff",
          fontSize: "16px",
          fontWeight: "bold",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          transition: "background-color 0.3s ease, transform 0.2s ease",
        }}
      >
        Yakınlaştır
      </button>
      <button
        onClick={() => zoomOut()}
        style={{
          backgroundColor: "#9c7338",
          color: "#fff",
          fontSize: "16px",
          fontWeight: "bold",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          transition: "background-color 0.3s ease, transform 0.2s ease",
        }}
      >
        Uzaklaştır
      </button>
      <button
        onClick={() => resetTransform()}
        style={{
          backgroundColor: "#9c7338",
          color: "#fff",
          fontSize: "16px",
          fontWeight: "bold",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          transition: "background-color 0.3s ease, transform 0.2s ease",
        }}
      >
        Resetle
      </button>
    </div>
    );
  };


  useEffect(() => {
    const loadProduct = async () => {
      try {
        const productData = await fetchProductById(productName);
        if (productData) {
          setProduct(productData);
          setProductRating(productData.averageRating);
          setTotalRatings(productData.totalRatings);
        }
      } catch (error) {
        console.error("Error fetching product detail:", error);
      }
    };
  
    if (productName) {
      loadProduct();
    }
  }, [productName]);
  // Adet sayısı ve sekme yönetimi için state
  const [quantity, setQuantity] = useState(1);
  const [activeTab, setActiveTab] = useState("features");
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  
  // Ürün bulunamazsa bir mesaj göster
  if (!product) {
    return (
      <Typography variant="h4" sx={{ textAlign: "center", mt: 4 }}>
        Yükleniyor...
      </Typography>
    );
  }

  
  // Adet artırma/azaltma işlemleri
  const handleIncrease = () => setQuantity((prev) => prev + 1);
  const handleDecrease = () => setQuantity((prev) => (prev > 1 ? prev - 1 : 1));

  const handleAddToCart = () => {
    const cartItem = {
      id: product.id,
      name: product.name,
      price: product.price,
      quantity: quantity,
      image: `${process.env.REACT_APP_BASE_URL}${product.photo_url}`,
    };

    dispatch(addToCart(cartItem)); // Redux'a ürünü gönder
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false); 
  };



  return (
<>
{product && <Navbar productName={product.name} />}
    <Box sx={{  p: 1,mt:4 }}>
      
      {/* Ürün Kartı */}
      <Card
  sx={{
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    overflow: "hidden",
    backgroundColor: "transparent", // Arka plan rengini kaldırır
    boxShadow: "none",             // Paper shadow'u kaldırır
    py: 3,
    mx:{xs:1,md:4}
  }}
>
<CardMedia
  component="img"
  image={`${process.env.REACT_APP_BASE_URL}${product.photo_url}`}
  alt={product.name}
  onClick={openModal} // Modal açılır
  sx={{
    width: { xs: "90%", md: "30%" },
    marginX:{ xs:"auto"},
    px:{ xs:1},
    height: { xs:"350px",md:"500px"},
    objectFit: "contain",
    border: "2px solid #9c7338",
    borderRadius: "8px",
    cursor: "pointer",
    py:3
  }}
/>
  <CardContent sx={{ p: 3,width: { xs: "90%", md: "60%" }, }}>
    {/* Ürün Başlığı ve Puan */}
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
      <Typography
        variant="h2"
        sx={{
          mt:0,
          fontWeight: "bold",
          fontFamily: "'Sansita', sans-serif",
          fontSize:{xs:"16px",md:"50px"}
        }}
      >
        {product.name}
      </Typography>
      <Box
  sx={{
    display: "flex",
    alignItems: "center",
    color: "#9c7338",
    fontFamily: "'Sansita', sans-serif",
  }}
>
  <StarIcon sx={{ mr: 0.5,fontSize:{xs:"16px",md:"30px"} }} />
  <Typography variant="h6" sx={{fontSize:{xs:"16px",md:"30px"} }}>{productRating}</Typography>
  <Typography variant="body2" sx={{ ml: 1, color: 'grey' }}>
    ({totalRatings})
  </Typography>
</Box>
    </Box>

    {/* Açıklama */}
    <Typography 
  variant="body1" 
  sx={{ 
    my: 2, 
    fontFamily: "'Sansita', sans-serif", 
    overflow: "hidden",  // Taşmayı engelle
    wordWrap: "break-word", // Kelimeleri sarmak
    whiteSpace: "normal" // Satır sonlarına geçmesini sağlar
  }}
>
  {product.description}
</Typography>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2,
        borderRadius: "10px",
        p: 2,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Ürün Adeti Seçme */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "white",
          borderRadius: "10px",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",

        }}
      >
        <IconButton onClick={handleDecrease} sx={{ color: "#9c7338" }}>
          <RemoveIcon />
        </IconButton>
        <Typography variant="h6" sx={{ mx: 5, color: "#9c7338","@media (max-width:600px)": { mx: 2 }, }}>
          {quantity}
        </Typography>
        <IconButton onClick={handleIncrease} sx={{ color: "#9c7338" }}>
          <AddIcon />
        </IconButton>
      </Box>

      {/* Fiyat */}
      <Typography  sx={{ fontWeight: "bold",fontFamily: "'Sansita', sans-serif",my:0,py:0,fontSize:{xs:"20px",md:"34px"} }}>
        {`${product.price} TL`}
      </Typography>
    </Box>

    <Button
      variant="contained"
      onClick={handleAddToCart}
      disabled={!product.in_stock}
      sx={{
        width: "100%",
        background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
        backgroundSize: "200% 100%", // Gradient genişliği artırıldı
        backgroundPosition: "right center", // Başlangıç pozisyonu
        color: "#fff",
        fontWeight: "bold",
        my: 4,
        borderRadius: "8px", // Köşeleri yuvarlama
        padding: "10px 20px",
        textDecoration: "none",
        border: "none",
        cursor: "pointer",
        transition: "background-position 0.5s ease, transform 0.3s ease",
        "&:hover": {
          backgroundPosition: "left center", // Hover durumunda sola geçiş
          transform: "scale(1.01)", // Hafif büyüme efekti
          color: "#F0F8FF", // Daha parlak beyaz
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
        },
      }}
    >
      {product.in_stock ? "Sepete Ekle" : "Stokta Kalmadı"}
    </Button>
  </CardContent>
</Card>
<Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        {quantity} Adet {product.name} Sepete eklendi!
        </Alert>
      </Snackbar>

      <Box sx={{ display: "flex", width: "100%",flexDirection: { xs: "column", md: "row" } }}>
  {/* Sol Taraf - Butonlar */}
  <Box sx={{ width: { xs: "calc(100% + 3px)", md: "20%" }, pr:0,mr:0 }}>
    <Box sx={{ display: "flex", flexDirection: { xs: "row", md: "column" }, mb: {sx:0,md:3} }}>
    <Button
  variant={activeTab === "features" ? "contained" : "outlined"}
  sx={{
    width: {xs:"100%" ,md:"calc(100% + 2px)"},
    background: activeTab === "features" ? "#9c7338" : "#D2D2D5", // Düz renk arka plan
    color: activeTab === "features" ? "#fff" : "#9c7338", // Renk değişikliği
    border: "2px solid #9c7338", // Düz renk border
    borderRadius:"0",
    borderRight: activeTab === "features" ? "2px solid #9c7338" : "#D2D2D5",
    borderBottom: {
      xs: "none",
      md: activeTab === "features" ? "2px solid #9c7338" : "none",
    },
    borderTop:{md:"2px solid #9c7338"},
    borderLeft:{md:"2px solid #9c7338"},
    boxShadow: "none", // Varsayılan shadow'ı kaldırdık
    fontFamily: "'Poppins', sans-serif",
    "@media (max-width:600px)": { fontSize: "9px" },
    "&:hover": {
      background: "#9c7338", // Hover durumunda da düz renk
      color: "#fff",
      "@media (max-width:600px)": { fontSize: "9px" }
    },
  }}
  onClick={() => setActiveTab("features")}
>
  Ürün Özellikleri
</Button>

<Button
  variant={activeTab === "reviews" ? "contained" : "outlined"}
  sx={{
    width: {xs:"100%" ,md:"calc(100% + 2px)"},
    borderRadius:"0",
    background: activeTab === "reviews" ? "#9c7338" : "#D2D2D5", // Düz renk arka plan
    color: activeTab === "reviews" ? "#fff" : "#9c7338", // Renk değişikliği
    border: "2px solid #9c7338", // Düz renk border
    borderRight: {
      xs: "2px solid #9c7338",
      md: activeTab === "features" ? "none" : "none",
    },
    borderTop: {
      xs: "2px solid #9c7338",
      md: activeTab === "features" ? "2px solid #9c7338" : "none",
    },
    borderBottom:{xs:"none",md:"2px solid #9c7338"},
    boxShadow: "none", // Varsayılan shadow'ı kaldırdık
    fontFamily: "'Poppins', sans-serif",
    "@media (max-width:600px)": { fontSize: "9px" },
    "&:hover": {
      background: "#9c7338", // Hover durumunda da düz renk
      color: "#fff",
      "@media (max-width:600px)": { fontSize: "9px" },
    },
  }}
  onClick={() => setActiveTab("reviews")}
>
  Yorum Ve Değerlendirme
</Button>

    </Box>
  </Box>

  {/* Sağ Taraf - İçerik */}
  <Box sx={{ display: "block",ml:0,justifyContent: "flex-start", alignItems: "center", width: { xs: "100%", md: "80%" },border: "2px solid #9c7338",borderTop:{xs:"none",md:"2px solid #9c7338"} }}>
    <motion.div
      key={activeTab}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {activeTab === "features" && <ProductFeatures details={product} />}
      {activeTab === "reviews" && <ProductReviews productId={product.id} />}
    </motion.div>
  </Box>
</Box>




</Box>
<Modal open={isModalOpen} onClose={closeModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: 2,
          width: "100%",
          maxWidth: {xs:"95%",sm:"70%",md:"40%"},
          maxHeight: "90%",
          outline: "none",
        }}
      >
        {/* Kapatma Tuşu */}
        <Box
          sx={{
            position: "absolute",
            top: {xs:-20,md:20},
            right: {xs:16,md:8},
            zIndex: 2,
            cursor: "pointer",
            color: "#fff",
            fontWeight: "bold",
            fontSize: "24px",
          }}
          onClick={closeModal}
        >
          ×
        </Box>

        {/* Fotoğrafı Zoom ve Pan Yapacak Bileşen */}
        <TransformWrapper
          initialScale={1}   // Başlangıç zoom seviyesi
          initialPositionX={0}
          initialPositionY={0}
          minScale={0.5}     // Minimum zoom seviyesi
          maxScale={5}       // Maksimum zoom seviyesi
          wheel={{
            step: 0.1,       // Scroll adımı
          }}
        >
          {({ zoomIn, zoomOut, resetTransform,...rest }) => (
            <div>
            <Controls />

              {/* Zoom ve Pan İçin Görsel */}
              <TransformComponent>
                <CardMedia
                  component="img"
                  src={`${process.env.REACT_APP_BASE_URL}${product.photo_url}`}
                  alt={product.name}
                  sx={{
                    width: "100%",
                    height: {
                      xs: "500px",
                      sm: "auto",
                      md: "600px",
                      lg: "650px",
                    },
                    display: "block",
                    objectFit: "contain",
                    cursor: "grab", // Sürüklemek için
                    borderRadius: "8px",
                    mt:3,
                  }}
                />
              </TransformComponent>
            </div>
          )}
        </TransformWrapper>
      </Box>
    </Modal>
    <ShoppingCart /> 
    <Footer />
</>
    );
  };


export default ProductDetails;
