import { Container, Typography, Button, Box, Grid,Divider } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate,useParams  } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
const ConfirmPayment = () => {

    const { orderId } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ textAlign: "center", my: 5 }}>
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Grid container spacing={1} alignItems="center">
            {/* Sol taraf: Resim */}
            <Grid item xs={12} sm={2} sx={{ display: "flex", justifyContent: "center" }}>
              <motion.div
                initial={{ scale: 0.5, rotate: -10 }}
                animate={{ scale: 1, rotate: 0 }}
                transition={{ type: "spring", stiffness: 120 }}
              >
                <Box
                  component="img"
                  src="/success.png"
                  alt="Sipariş Başarılı"
                  sx={{ width: "80%", maxWidth: 300,objectFit:"cover" }}
                />
              </motion.div>
            </Grid>

            {/* Sağ taraf: Yazı */}
            <Grid item xs={12} sm={10}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6 }}
              >
                <Typography variant="h3" fontWeight="bold" sx={{fontFamily: "'Sansita', sans-serif",color:"#fff", textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",}}>
                  Siparişiniz Alındı!
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, fontFamily: "'Poppins', sans-serif", }}>
  Siparişiniz başarıyla oluşturuldu. Takip bilgileri hesabım siparişlerim sayfasından görebilirsiniz.
</Typography>
<Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    overflow: "hidden",
    my:2
  }}
>
  <Divider
    sx={{
      borderWidth: "2px",
      borderStyle: "solid",
      width: "80%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
</Box>
<Typography variant="body1" sx={{ mt: 2, fontFamily: "'Poppins', sans-serif", }}>
                Sipariş Numarası: {orderId}
                </Typography>
              </motion.div>
              <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            <Button
              variant="contained"
              onClick={() => navigate("/")}
              component={motion.button}
              whileTap={{ scale: 0.95 }}
              sx={{
                width: "80%",
                background: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338)",
                backgroundSize: "200% 100%", // Gradient genişliği artırıldı
                backgroundPosition: "right center", // Başlangıç pozisyonu
                color: "#fff",
                fontWeight: "bold",
                mt:4,
                borderRadius: "8px", // Köşeleri yuvarlama
                padding: "10px 20px",
                textDecoration: "none",
                border: "none",
                cursor: "pointer",
                transition: "background-position 0.5s ease, transform 0.3s ease",
                "&:hover": {
                  backgroundPosition: "left center", // Hover durumunda sola geçiş
                  transform: "scale(1.01)", // Hafif büyüme efekti
                  color: "#F0F8FF", // Daha parlak beyaz
                  boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)", // Hafif bir gölge
                },
              }}
            >
              Ana Sayfaya Dön
            </Button>
          </motion.div>
            </Grid>

          </Grid>
      
        </motion.div>

      </Container>
      <Footer />
    </>
  );
};

export default ConfirmPayment;
