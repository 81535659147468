import React, { useState } from 'react';
import { Drawer, Box, List, ListItem, ListItemText, Collapse,Divider,Typography } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Link } from 'react-router-dom'; 

const DrawerComponent = ({ isOpen, toggleDrawer }) => {
  const [openCorporate, setOpenCorporate] = useState(false);
  const handleCorporateClick = (event) => {
    event.stopPropagation();
    setOpenCorporate(!openCorporate);
  };
  




  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer(false)}
      sx={{
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: "rgba(255, 255, 255, 0.2)", // %50 siyah saydam
            backdropFilter: "blur(5px)", // Opsiyonel: Blur efekti ekler
          },
      }}
    >
      <Box sx={{ width: 250 }} role="presentation">
        <List>
          <ListItem button onClick={toggleDrawer(false)}>
            <ListItemText>
              <Link to="/" style={{ textDecoration: 'none', color: '#fff',display:"block",fontFamily: "'Sansita', sans-serif",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)", }}>Ana Sayfa</Link>
            </ListItemText>
          </ListItem>
  <Divider
    sx={{
      borderWidth: "1px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
          {/* Kurumsal Menü */}
          <ListItem button onClick={handleCorporateClick}>
          <ListItemText 
    primary={
      <Typography 
        sx={{ 
          color: '#fff', 
          fontFamily: "'Sansita', sans-serif", 
          textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)", // Gölge ekleme
        }}
      >
        Kurumsal
      </Typography>
    }
  />
            {openCorporate ? <ExpandLess sx={{ color: '#fff' }} /> : <ExpandMore sx={{ color: '#fff' }} />}
          </ListItem>
          <Collapse in={openCorporate} timeout="auto" unmountOnExit>
            <List>
            <Divider
    sx={{
      borderWidth: "1px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText>
                  <Link to="/hakkimizda" style={{ textDecoration: 'none', color: '#fff',fontFamily: "'Sansita', sans-serif",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)" }}>Hakkımızda</Link>
                </ListItemText>
              </ListItem>
              <Divider
    sx={{
      borderWidth: "1px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText>
                  <Link to="/tesisimiz" style={{ textDecoration: 'none', color: '#fff',fontFamily: "'Sansita', sans-serif",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)" }}>Tesisimiz</Link>
                </ListItemText>
              </ListItem>
              <Divider
    sx={{
      borderWidth: "1px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
              <ListItem button onClick={toggleDrawer(false)}>
                <ListItemText>
                  <Link to="/belgelerimiz" style={{ textDecoration: 'none', color: '#fff',fontFamily: "'Sansita', sans-serif",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)" }}>Belgelerimiz</Link>
                </ListItemText>
              </ListItem>
              
            </List>
          </Collapse>
          <Divider
    sx={{
      borderWidth: "1px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
       

          <ListItem button onClick={toggleDrawer(false)}>
            <ListItemText>
              <Link to="/urunlerimiz" style={{ textDecoration: 'none', color: '#fff',display:"block",fontFamily: "'Sansita', sans-serif",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)" }}>Ürünlerimiz</Link>
            </ListItemText>
          </ListItem>
          <Divider
    sx={{
      borderWidth: "1px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
          <ListItem button onClick={toggleDrawer(false)}>
            <ListItemText>
              <Link to="/iletisim" style={{ textDecoration: 'none', color: '#fff',display:"block",fontFamily: "'Sansita', sans-serif",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)" }}>İletişim</Link>
            </ListItemText>
          </ListItem>
          <Divider
    sx={{
      borderWidth: "1px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
          <ListItem button onClick={toggleDrawer(false)}>
            <ListItemText>
              <Link to="/katalog" style={{ textDecoration: 'none', color: '#fff',display:"block",fontFamily: "'Sansita', sans-serif",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)" }}>Katalog</Link>
            </ListItemText>
          </ListItem>
          <Divider
    sx={{
      borderWidth: "1px",
      borderStyle: "solid",
      width: "100%",
      borderImage: "linear-gradient(135deg, #ada87e, #9c7338, #ebc48c, #9c7338) 1",
    }}
  />
        </List>
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
