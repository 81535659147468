import React from "react";
import { Container, Typography, List, ListItem } from "@mui/material";
import Navbar from "../components/navbar";
import Footer from '../components/Footer';
const AydinlatmaMetni = () => {
  return (
      <>
    <Navbar />
    <Container>
    <Typography variant="h4" sx={{color:"#fff", fontWeight: "bold",textShadow: "-2px -2px 4px rgba(0, 0, 0, 1)",fontFamily: "'Sansita', sans-serif",mb:2 }}>
              ÇEREZ AYDINLATMA METNİ
            </Typography>
      <Typography variant="body1" paragraph>
        Bu web sitesi, kullanıcı deneyimini iyileştirmek ve temel işlevleri
        sağlamak için çerezler kullanmaktadır. Kullanıcıların gizliliğine saygı
        gösteriyoruz ve yalnızca gerekli olan çerezleri kullanıyoruz. Bu
        çerezler, siteyi kullanabilmeniz için gereklidir ve sizin izninizle
        kullanılmaktadır.
      </Typography>
      <Typography variant="body1" paragraph>
        Kullanıcılar, bu siteyi kullanmaya devam ettikçe, çerezlerin kullanılmasına
        onay vermiş olurlar. Web sitemiz yalnızca şu çerezleri kullanır:
      </Typography>
      <List>
        <ListItem>
          <Typography variant="body1">
            <strong>Gerekli Çerezler:</strong> Web sitesinin temel işlevlerini
            çalıştıran çerezlerdir. Bu çerezler, kullanıcıların oturum açması,
            güvenli bir şekilde gezinti yapması gibi temel işlemleri sağlar.
          </Typography>
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        Web sitemizdeki çerezler hakkında daha fazla bilgi almak için bizimle
        iletişime geçebilirsiniz.
      </Typography>
    </Container>
    <Footer/>
    </>
  );
};

export default AydinlatmaMetni;
