import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // localStorage kullanmak için
import cartReducer from "./cartSlice"; // cart slice'ınızı import edin

const persistConfig = {
  key: "root", // persist edilen verinin anahtarı
  storage, // storage türü (localStorage kullanıyoruz)
};

const persistedReducer = persistReducer(persistConfig, cartReducer);

const store = configureStore({
  reducer: {
    cart: persistedReducer, // Reducer'ı persistReducer ile sarıyoruz
  },
});

const persistor = persistStore(store); // Persistor oluşturuluyor

export { store, persistor };
